import React from 'react';
import ServerApi from '../../services/server-api-service';
import CookiesManager from '../../services/cookies-manager';
import FormCheckService from '../../services/form-check-service';
import Select, { components, GroupProps } from 'react-select';
import ThanksModal from '../thanks-modal';


import './user-form-style.css';
const telephones = require('./tel.json');
const FLAGBAN = "US";

class UserForm extends React.Component {
    serverApi = new ServerApi();
    cookiesManager = null;
    formCheckService = new FormCheckService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            isVisibleThanksModal: false,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userComment: ''
            },
            currentNubmer: null,
            currentElement: null,
        }

    }

    componentDidMount() {
        this.cookiesManager = new CookiesManager();
    }

    async getTelephoneList() {
        const res = await fetch('http://ngirealty.com/resource/data/tel.json', {
            method: 'GET',
        })
            .then((response) => {
                console.log(response);
                return response.json();
            })
            .then((data) => {
                this.setState({
                    telephonesList: data
                })
            })

    }

    componentWillMount() {
        //this.getTelephoneList()
    }



    componentDidUpdate(prevProps) {
        // if (prevProps.selectedCountry !== this.props.selectedCountry) {
        //   this.saveElement(this.props.selectedCountry);
        // }
    }

    handleInput = (event) => {
        if (this.props.handleInput) this.props.handleInput(event);
        // this.setState({
        //     contactForm: {
        //         ...this.state.contactForm,
        //         [event.target.name]: event.target.value
        //     }
        // })
    }


    handleSubmit = (event) => {
        event.preventDefault();
        //if(this.props.handleSubmit) this.props.handleSubmit(this.state.currentElement.dial_code);
        if (this.props.handleSubmit) this.props.handleSubmit();
        else if (!this.formCheckService.checkContactForm(this.props.contactForm)) {
            let form = Object.assign({}, this.props.contactForm);
            //if(this.state.currentElement.code!==FLAGBAN)
            //form.userPhone=this.state.currentElement.dial_code+this.props.contactForm.userPhone;
            if (this.state.debug) {
                console.log(form)
            }
            this.serverApi.sendForm(form)
                .then((data) => {
                    this.cookiesManager.set('exit_pop', '1', 365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    if (this.props.clearForm) this.props.clearForm();
                    if (this.props.openThanksModal) this.props.openThanksModal();
                    else if (this.props.thankModal) this.openDefaultModal();
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: join team modal ERROR:${error}`);
                })
        }
    }

    handleChange = (event) => {
        if (this.state.debug) console.log(event);
        this.setState({
            currentNubmer: event.dial_code,
            currentElement: event
        })
        if (this.props.saveCurrentSelectedCountry) this.props.saveCurrentSelectedCountry(event);

    }

    openDefaultModal = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                isVisibleThanksModal: !prevState.isVisibleThanksModal
            }
        })
    }

    CustomOption(props) {
        let { children, isDisabled, innerProps } = props;
        if (!isDisabled) {
            return (<div className="CustomOption" {...innerProps}><img className="Image" src={`https://www.countryflags.io/${props.code}/flat/64.png`} />	{props.name}&nbsp;{props.dial_code} </div>)
        } else return (null)
    }

    saveNumber(number) {
        this.setState({
            currentNubmer: number
        })
    }

    saveElement(elem) {
        this.setState({
            currentElement: elem
        })
    }

    renderPhoneInput() {
        let { width, className, style, dictionary, country, selectedCountry = null, CustomInputStyle = { padding: '0 0 0 5px' },customLandingForm = false } = this.props;
        let { currentElement, telephonesList } = this.state;
        let elem;

        // if(currentElement===null&&Array.isArray(telephonesList)){
        //     if(selectedCountry!==null&&selectedCountry!==undefined) this.saveElement(selectedCountry);
        //     else if(country!==undefined&&country!==null){ 
        //         this.saveElement(telephonesList.find(el=> el.code === country));
        //     }
        // }
        let inputClassName= 'Input'
        if(customLandingForm) inputClassName = ''
      
        //if(currentElement!== null&&currentElement.code===FLAGBAN){
        if (1 == 1) {
            return (<input
                onChange={this.handleInput}
                name='userPhone'
                className={inputClassName}
                placeholder={dictionary.phone}
                type="text"
                value={this.props.contactForm.userPhone}></input>)
        }
        else
            if (currentElement !== null && telephonesList !== undefined) {
                return (
                    <div className="Input CustomInput" style={CustomInputStyle} tabindex="-1">
                        <Select
                            tabIndex={-1}
                            value={currentElement}
                            //components={{Option:this.CustomOption,ValueOption:this.ValueOption}}
                            formatOptionLabel={this.CustomOption}
                            isSearchable={false}
                            options={telephonesList}
                            onChange={this.handleChange}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    border: "none",
                                    backgroundColor: 'inherit',
                                    maxHeight: '28px',
                                    minHeight: '0',
                                    maxWidth: '70px',
                                    justifyContent: 'left',
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    width: '300px'
                                }),
                                indicatorSeparator: (provided, state) => ({
                                    ...provided,
                                    display: 'none'
                                }),
                                valueContainer: (provided, state) => ({
                                    ...provided,
                                    marginBottom: '6px',
                                    padding: '2px 0 2px 1px',
                                    maxWidth: '28px'
                                }),
                                indicatorsContainer: (provided, state) => ({
                                    ...provided,
                                    '.css-1gtu0rj-indicatorContainer': {
                                        padding: '8px 0 8px 0'
                                    },
                                    '.css-tlfecz-indicatorContainer': {
                                        padding: '8px 0 8px 0'
                                    }

                                }),
                                singleValue: (provided, state) => ({
                                    ...provided,
                                    width: '20px'
                                }),
                            }}
                            name="location"
                            className="Select"
                        />
                        <div className="CountryNumber">
                            {currentElement.dial_code}
                        </div>
                        <input
                            className="Input"
                            onChange={this.handleInput}
                            name='userPhone'
                            placeholder={dictionary.phone}
                            type="text"
                            value={this.props.contactForm.userPhone}></input>
                    </div>
                )
            } else return null;

    }

    renderFormBody() {
        let { width, className, disableMessage = false, urgencyMessage = null, style, dictionary, watcher,
            secureInfo = false, buttonTitle = dictionary.submit, topPartRow = false,
            inputBans = { comment: false, message: false }, message = dictionary.thank_you,
            newButtons = false, customLandingForm = false,formTitle ='' } = this.props;

        const iconLock = require('../../elements/icons/s_secured.png');

        if (customLandingForm) {
            return (
                <>
                    <div className={"contactForm"}>
                        <div className={"contactFormHeader"}>{formTitle}</div>
                        <input onChange={this.handleInput} name='userFullName' placeholder={`Your name`} type="text" value={this.props.contactForm.userFullName}></input>
                        {this.renderPhoneInput()}
                        <input onChange={this.handleInput} name='userEmail' placeholder={`Your email`} type="text" value={this.props.contactForm.userEmail}></input>
                        <textarea onChange={this.handleInput} name='userComment' placeholder={dictionary.message} type="text" value={this.props.contactForm.userComment}></textarea>
                        <button onClick={this.handleSubmit} className={"requestButton"}>{'Request a consultation'}</button>
                        {/* <div className={"contactFormHeader"}></div>
                        <input type={"text"} placeholder={"Your name"}></input>
                        <input type={"text"} placeholder={"Your phone number"}></input>
                        <input type={"text"} placeholder={"Your email"}></input>
                        <textarea></textarea>
                        <div className={"requestButton"}>Request a consultation</div> */}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <form className={`JoinTeamForm ${className}`}
                        onFocus={() => { if (watcher) watcher.stopWatcher() }}
                        onBlur={() => { if (watcher) watcher.resumeWatcher() }}
                        style={style}>
                        <div>
                            {/* <div className={topPartRow ? ('TopPartForm') : null}>
                                
                            </div> */}
                            <input onChange={this.handleInput} name='userFullName' className="Input" placeholder={dictionary.full_name} type="text" value={this.props.contactForm.userFullName}></input>
                            {/* <input onChange={this.handleInput} name='userPhone' className="Input" placeholder={dictionary.phone} type="text" value={this.props.contactForm.userPhone}></input> */}
                            {this.renderPhoneInput()}
                            <input onChange={this.handleInput} name='userEmail' className="Input" placeholder={dictionary.email} type="text" value={this.props.contactForm.userEmail}></input>
                            {(!inputBans.comment && urgencyMessage == null) && (!disableMessage) ? <textarea onChange={this.handleInput} name='userComment' className="Input" placeholder={dictionary.message} type="text" value={this.props.contactForm.userComment}></textarea> : (null)}
                            {secureInfo ? (
                                <div className="SecureDiv ListingPageSecureBlock">
                                    <img className="SecureIcon" src={iconLock} alt="" />
                                    <span className="Text">
                                        {dictionary.information_secure}
                                    </span>
                                </div>
                            ) : (null)}
                            <button onClick={this.handleSubmit} id={newButtons ? `ButtonLight` : null} className={`Button`}>{urgencyMessage ? (dictionary.request_about) : (buttonTitle)}</button>
                        </div>
                    </form>
                </>
            )
        }

    }

    render() {
        let {dictionary, message = dictionary.thank_you} = this.props;
        return (
            <>
                {this.renderFormBody()}
                <ThanksModal
                    isVisible={this.state.isVisibleThanksModal}
                    title={dictionary.thank_you}
                    message={message}
                    closeModal={this.openDefaultModal}
                />
            </>
        )
    };
}

export default UserForm;