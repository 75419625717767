const MILLISECONDS = 1000;
const EVENTS = [
    'mousedown', 'mousemove', 'keydown',
    'scroll', 'touchstart'
];
let secondSinceLastActivity = 0;
let flagClose = false;

class ActivityWatcher{
    constructor(eventNameForOpen){
        this.eventNameForOpen = eventNameForOpen
        this.addEvents()
        this.addInterval();
    }
   
    addEvents(){
        EVENTS.forEach((event)=>{
            document.addEventListener(event,this.active)
        })
    }

    addInterval(){
        this.interval = setInterval(()=>{
            secondSinceLastActivity++;
            if(secondSinceLastActivity>15&&!flagClose){
                //handle for open modal
                document.dispatchEvent(new Event(this.eventNameForOpen))
                flagClose=true; 
            }  
        },1*MILLISECONDS)
    }
    
    active(){
        secondSinceLastActivity = 0;
    }

    
    resumeWatcher(){
        this.addEvents()
        this.addInterval();
    }

    stopWatcher(){
        clearInterval(this.interval);
        EVENTS.forEach((event)=>{
            document.removeEventListener(event,this.active)
        })
    }
}

export default ActivityWatcher;