import React from 'react';


import ConfigurationService from '../../services/configuration-service';
import StringProcessingService from '../../services/string-processing-service';

import './language-switch-style.css';

const listOfLanguages = require('../../configuration/languages/list.json');

class LanguageSwitch extends React.Component {
    configurationService = new ConfigurationService();
    stringProcessingService = new StringProcessingService();
    render() {

        // let {forMobile} = this.props;

        //console.log('for mobile: '+forMobile);

        // let svg_icon = () => {
        //     if (forMobile) {
        //         return (
        //             <svg xmlns="http://www.w3.org/2000/svg" width="14.591" height="14.591" viewBox="0 0 28.591 28.591">
        //                 <g id="Сгруппировать_488" data-name="Сгруппировать 488" transform="translate(1 1)">
        //                     <ellipse id="Эллипс_1" data-name="Эллипс 1" cx="13.296" cy="13.296" rx="13.296" ry="13.296" transform="translate(0 0)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        //                     <line id="Линия_6" data-name="Линия 6" x2="26.591" transform="translate(0 13.296)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        //                     <ellipse id="Эллипс_2" data-name="Эллипс 2" cx="5.446" cy="13.296" rx="5.446" ry="13.296" transform="translate(7.85 0)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        //                 </g>
        //             </svg>
        //         )
        //     }
        //     else {
        //         return (
        //             <svg xmlns="http://www.w3.org/2000/svg" width="12.719" height="12.719" viewBox="0 0 12.719 12.719">
        //                 <g id="Сгруппировать_104" data-name="Сгруппировать 104" transform="translate(-1284.452 -871.849)">
        //                     <circle id="Эллипс_1" data-name="Эллипс 1" cx="5.86" cy="5.86" r="5.86" transform="translate(1284.952 872.349)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
        //                     <line id="Линия_6" data-name="Линия 6" x2="11.719" transform="translate(1284.952 878.208)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
        //                     <ellipse id="Эллипс_2" data-name="Эллипс 2" cx="2.4" cy="5.86" rx="2.4" ry="5.86" transform="translate(1288.411 872.349)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1" />
        //                 </g>
        //             </svg>
        //         )
        //     }
        // }


        // console.log('for mobile: '+ swg_icon);
        let language = this.configurationService.getCurrentLanguagePrefix()
        if (document.location.host) {

        }
        let { allowedLanguages, articlesTranslations } = this.props;
        let renderLanguageList = []
        if (allowedLanguages != null) {
            for (let i = 0; i < listOfLanguages.length; i++) {
                if (allowedLanguages.includes(listOfLanguages[i].name)) renderLanguageList.push(listOfLanguages[i])
            }
        } else {
            for (let i = 0; i < listOfLanguages.length; i++) {
                if ((listOfLanguages[i].name!='tr')) renderLanguageList.push(listOfLanguages[i])
            }
        };
        return (
            <div className="LanguageSwitch">
                {/*{svg_icon()}&nbsp;*/}
                {/* <a href={document.location.href} className={language==='en'?('Current'):(null)}>EN</a>
                <div>|</div>
                <a href={language==='en'?null:(null)} className={language==='es'?('Current'):(null)}>ES</a>
                <div>|</div>
                <a className={language==='pt'?('Current'):(null)}>PT</a> */}
                {
                    renderLanguageList.map((lang, index) => {
                        
                        let regWithLang = new RegExp("\/\/[a-z]{2}\\.", "g");
                        let regWithoutLang = new RegExp("\/\/", "g");
                        let hrefUrl = '';
                        if (regWithLang.test(document.location.href)) {
                            if (lang.name === 'en') {
                                hrefUrl = document.location.href.replace(regWithLang, `\\\\`)
                            } else {
                                hrefUrl = document.location.href.replace(regWithLang, `\\\\${lang.name}.`)
                            }
                            if (articlesTranslations != null && articlesTranslations[lang.name] != null) {
                                if (lang.name === 'en') {
                                hrefUrl = document.location.origin.replace(regWithLang, `\\\\`)
                            } else {
                                hrefUrl = document.location.origin.replace(regWithLang, `\\\\${lang.name}.`)
                            }
                                hrefUrl += `/articles/${articlesTranslations[lang.name].id}/${this.stringProcessingService.StringFormatUrl(articlesTranslations[lang.name].title)}`;
                            }
                        } else {
                            if (lang.name === 'en') {
                                hrefUrl = document.location.href.replace(regWithoutLang, `\\\\`)
                            } else {
                                hrefUrl = document.location.href.replace(regWithoutLang, `\\\\${lang.name}.`)
                            }
                            if (articlesTranslations != null && articlesTranslations[lang.name] != null) {
                                if (lang.name === 'en') {
                                hrefUrl = document.location.origin.replace(regWithoutLang, `\\\\`)
                            } else {
                                hrefUrl = document.location.origin.replace(regWithoutLang, `\\\\${lang.name}.`)
                            }
                                hrefUrl += `/articles/${articlesTranslations[lang.name].id}/${this.stringProcessingService.StringFormatUrl(articlesTranslations[lang.name].title)}`;
                            }
                        }

                        


                        if (allowedLanguages !== null && allowedLanguages !== undefined) {
                            if (allowedLanguages.length > 1) {
                                if (allowedLanguages.includes(lang.name)) {
                                    return (
                                        <>
                                            <a href={hrefUrl} className={language === lang.name ? ('Current') : (null)}>{lang.name.toUpperCase()}</a>
                                            {index < renderLanguageList.length - 1 ? (<div>|</div>) : (null)}
                                        </>
                                    )
                                } else return null;
                            } else return null;


                        } else {
                            return (
                                <>
                                    <a href={hrefUrl} className={language === lang.name ? ('Current') : (null)}>{lang.name.toUpperCase()}</a>
                                    {index < renderLanguageList.length - 1 ? (<div>|</div>) : (null)}
                                </>
                            )
                        }

                    })
                }
            </div>
        )
    }
}
export default LanguageSwitch;