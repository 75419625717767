import React, { useState, useEffect } from "react";

import Modal from '../modal';
import HOne from "../h-one";

import ServerApi from "../../services/server-api-service";

import './listing-feedback-style.css';

const serverApi = new ServerApi();

export default function ListingFeedback(props) {

    const [feedback, setFeedback] = useState({
        like: null,
        client_comment: null
    })

    const [isVisibleModalFeedback, setVisibileModalFeedback] = useState(false)
    const [isLoading, setLoading] = useState(false)

    let { selectionId, data,overlay = false,type = 'listing'} = props;

    useEffect(() => {
        getFeedback()
    }, [])

    useEffect(() => {
        if (isLoading) sendFeedback()
    }, [feedback.like])


    function handleInput(event) {
        setFeedback({...feedback,client_comment:event.target.value})
    }

    function getFeedback() {

        serverApi.getSelectionFeedback(selectionId, data.id,type)
            .then((data) => {
                setFeedback(data);
                setLoading(true);
            })
            .catch((error) => {
                console.log('Error: fetch GetSelectionFeedback: ', error)
            })
    }

    function sendFeedback(event) {
        if(event) {
            event.preventDefault();
            setVisibileModalFeedback(false)
        }
        serverApi.sendSelectionFeedback(selectionId, data.id, feedback,type)
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log('Error: send feedback: ', error)
            })
    }

    let like_class= 'fa-thumbs-up';
    let dislike_class='fa-thumbs-down';
    if(feedback.like == null || !feedback.like) like_class= 'fa-thumbs-o-up';
    if(feedback.like == null || feedback.like) dislike_class='fa-thumbs-o-down';

    return (
        <>
        <div className={`FeedbackBlock ${overlay ? 'FeedBackOverlay' : ''}`}>
            <i class={`fa ${like_class} FeedbackItem`} style={feedback.like != null && feedback.like ? { color: '#15c315' } : { color: 'grey' }} aria-hidden="true"
                onClick={
                    async () => {
                        let like = true;
                        if(feedback.like) like = null;
                        setFeedback({ ...feedback, like: like })
                    }
                }

            ></i>

            <i class={`fa ${dislike_class} FeedbackItem`} style={feedback.like != null && !feedback.like ? { color: 'red' } : { color: 'grey' }} aria-hidden="true"
                onClick={
                    async () => {
                        let like = false;
                        if(feedback.like == false) like = null;
                        setFeedback({ ...feedback, like: like })
                     
                    }
                }

            ></i>
            {/* {feedback.client_comment ? (<i class="fa fa-commenting FeedbackItem" aria-hidden="true"
                onClick={
                    () => {
                        setVisibileModalFeedback(true)
                    }
                }></i>) : (<i class="fa fa-comment-o FeedbackItem"
                    style={{ color: "grey" }}
                    aria-hidden="true"
                    onClick={
                        () => {
                            setVisibileModalFeedback(true)
                        }
                    }
                ></i>)} */}

           
        </div>
        <Modal
                ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                modalClass={'MessageModal'}
                closeColor={'#000'}
                speed={0.5}
                ModalStyle={{justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                isVisible={isVisibleModalFeedback}
                closeModal={()=>{setVisibileModalFeedback(false)}}
            >
                <div className="FeedbackModal">
                    <HOne title={'PLEASE LEAVE FEEDBACK ABOUT LISTING'} hTag={'h3'} line={"large"} />
                     <form className={`Form`}>
                        <div>
                            <textarea onChange={handleInput} name='client_comment' className="Input" placeholder={'Comments...'} type="text" value={feedback.client_comment}></textarea>
                            <button onClick={sendFeedback} className={`Button`}>Send</button>
                        </div>
                    </form>
                </div>

            </Modal>
        </>
    )
}