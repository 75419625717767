import React,{useState} from 'react';
import HOne from '../h-one';
import StringProcessingService from '../../services/string-processing-service';
import Modal from '../modal';
import Parser from 'html-react-parser';
import KeyDealFeatureModal from '../key-deal-feature-modal/key-deal-feature-modal';

import './key-deal-features-style.css'

export default function KeyDealFeatures(props) {
    const stringProcessingService = new StringProcessingService();
    let { dictionary,analyseInfo,width,data,selection =false } = props;
    // const smartDealImg = require('../../elements/images/smart_deal.png');
    // const fullDiamonIcon = require('../../elements/icons/s_diamond_c.png');
    // const emptyDiamonIcon = require('../../elements/icons/s_diamond_a.png');
    const checkIcon = require('../../elements/images/check.png');
    // const info = require('../../elements/images/i.svg');
    // const infoDark = require('../../elements/images/i_dark.svg');
    // let diamonds = [];

    const [isVisibleModalInfo,setIsVisibleModalInfo] = useState(false); 


    let listingPrice, averageSoldPrice, origintalPrice, recentDicount = null;

    if (analyseInfo !== undefined) {

        // for (let i = 0; i < 4; i++) {
        //     if (this.state.keyDealFeatures.length > i) {
        //         diamonds.push(<img className="DiamondIcon" key={i} src={fullDiamonIcon} alt=""></img>)
        //     } else {
        //         diamonds.push(<img className="DiamondIcon" key={i} src={emptyDiamonIcon} alt=""></img>)
        //     }


        // }

        // if (analyseInfo.less_than_average_br_listed) {
        //     diamonds.unshift(<img className="DiamondIcon" src={fullDiamonIcon} alt=""></img>)
        // } else {
        //     diamonds.push(<img className="DiamondIcon" src={emptyDiamonIcon} alt=""></img>)
        // }
        // if (analyseInfo.less_than_average_sold) {
        //     diamonds.unshift(<img className="DiamondIcon" src={fullDiamonIcon} alt=""></img>)
        // } else {
        //     diamonds.push(<img className="DiamondIcon" src={emptyDiamonIcon} alt=""></img>)
        // }

        // if (analyseInfo.less_than_purchase_price) {
        //     diamonds.unshift(<img className="DiamondIcon" src={fullDiamonIcon} alt=""></img>)
        // } else {
        //     diamonds.push(<img className="DiamondIcon" src={emptyDiamonIcon} alt=""></img>)
        // }

        // if (analyseInfo.price_reduced) {
        //     diamonds.unshift(<img className="DiamondIcon" src={fullDiamonIcon} alt=""></img>)
        // } else {
        //     diamonds.push(<img className="DiamondIcon" src={emptyDiamonIcon} alt=""></img>)
        // }

        let averageListedDiscount = parseInt(analyseInfo.average_br_listed_discount * 100);
        let averageSoldDiscount = parseInt(analyseInfo.average_sold_discount * 100);
        let purchasePriceDiscount = parseInt(analyseInfo.purchase_price_discount * 100);
        let listingDiscount = parseInt(analyseInfo.listing_discount * 100);


        if(data.ClosePrice != null && data.ClosePrice > 0) return null;

        if (analyseInfo.less_than_average_br_listed) {
            let activeListingDeal = stringProcessingService.convertToFormat(parseInt(analyseInfo.active_listings_average_sqft).toString())
            let price_sqft = stringProcessingService.convertToFormat(parseInt(analyseInfo.selected_unit.price_sqft).toString())
            if (props.width > 700) {
                listingPrice = (
                    <tr>
                        {/*<td><img className="DiamondIcon" src={fullDiamonIcon} alt="" /></td>*/}
                        <td><img className="checkIcon" src={checkIcon} alt="" /></td>
                        <td>{dictionary.analyse_priced_below_comps} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_comparable)}</KeyDealFeatureModal></td>
                        <td className="Tar">${activeListingDeal}/{dictionary.area_metric_abr}  </td>
                        <td className="Separator">&nbsp;</td>
                        <td>${price_sqft}/{dictionary.area_metric_abr}</td>
                        <td className="Separator">&nbsp;</td>
                        <td className="FeatureDiscount">{averageListedDiscount}%</td>
                    </tr>
                )
            }
            else {
                listingPrice = (
                    <>
                        <tr className={"MobilTableRow"}>
                            <td className={"Icon"}><img className="checkIcon" src={checkIcon} alt="" /></td>
                            <td className={"Feat"}>{dictionary.analyse_priced_below_comps} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_comparable)}</KeyDealFeatureModal></td>
                            <td className={"Pri"}>${activeListingDeal}/{dictionary.area_metric_abr} </td>
                        </tr>
                        <tr className={"MobilTableRow"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.the_deal}</td>
                            <td className={"Pri"}>${price_sqft}/{dictionary.area_metric_abr}</td>
                        </tr>
                        <tr className={"MobilTableRow MobilTableRowLast"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.discount}</td>
                            <td className="FeatureDiscount Pri">{averageListedDiscount}%</td>
                        </tr>
                    </>
                )
            }
        }

        if (analyseInfo.less_than_average_sold) {
            let soldListingDeal = stringProcessingService.convertToFormat(parseInt(analyseInfo.sold_listings_average_sqft).toString())
            let price_sqft = stringProcessingService.convertToFormat(parseInt(analyseInfo.selected_unit.price_sqft).toString())

            if (width > 700) {
                averageSoldPrice = (
                    <tr>
                        {/*<td><img className="DiamondIcon" src={fullDiamonIcon} alt="" /></td>*/}
                        <td><img className="checkIcon" src={checkIcon} alt="" /></td>
                        <td>{dictionary.analyse_priced_below_market} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_average)}</KeyDealFeatureModal></td>
                        <td className="Tar">${soldListingDeal}/{dictionary.area_metric_abr}</td>
                        <td className="Separator">&nbsp;</td>
                        <td>${price_sqft}/{dictionary.area_metric_abr}</td>
                        <td className="Separator">&nbsp;</td>
                        <td className="FeatureDiscount">{averageSoldDiscount}%</td>
                    </tr>
                )
            }
            else {
                averageSoldPrice = (
                    <>
                        <tr className={"MobilTableRow"}>
                            <td className={"Icon"}><img className="checkIcon" src={checkIcon} alt="" /></td>
                            <td className={"Feat"}>{dictionary.analyse_priced_below_market} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_average)}</KeyDealFeatureModal></td>
                            <td className={"Pri"}>${soldListingDeal}/{dictionary.area_metric_abr}</td>
                        </tr>
                        <tr className={"MobilTableRow"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.the_deal}</td>
                            <td className={"Pri"}>${price_sqft}/{dictionary.area_metric_abr}</td>
                        </tr>
                        <tr className={"MobilTableRow MobilTableRowLast"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.discount}</td>
                            <td className="FeatureDiscount Pri">{averageSoldDiscount}%</td>
                        </tr>
                    </>
                )
            }
        }

        if (analyseInfo.less_than_purchase_price) {
            let ParsedpurchasePrice = stringProcessingService.convertToFormat(analyseInfo.purchase_price.toString());
            let ParsedListingPrice = stringProcessingService.convertToFormat(parseInt(analyseInfo.selected_unit.list_price).toString())

            if (width > 700) {
                origintalPrice = (
                    <tr>
                        {/*<td><img className="DiamondIcon" src={fullDiamonIcon} alt="" /></td>*/}
                        <td><img className="checkIcon" src={checkIcon} alt="" /></td>
                        <td>{dictionary.analyse_price_below_original} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_original)}</KeyDealFeatureModal></td>
                        <td className="Tar">${ParsedpurchasePrice}</td>
                        <td className="Separator">&nbsp;</td>
                        <td>${ParsedListingPrice}</td>
                        <td className="Separator">&nbsp;</td>
                        <td className="FeatureDiscount">{purchasePriceDiscount}%</td>
                    </tr>
                )
            }

            else {
                origintalPrice = (
                    <>
                        <tr className={"MobilTableRow"}>
                            <td className={"Icon"}><img className="checkIcon" src={checkIcon} alt="" /></td>
                            <td className={"Feat"}>{dictionary.analyse_price_below_original} <KeyDealFeatureModal>{Parser(dictionary.deal_legend_priced_below_original)}</KeyDealFeatureModal></td>
                            <td className={"Pri"}>${ParsedpurchasePrice}</td>
                        </tr>
                        <tr className={"MobilTableRow"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.the_deal}</td>
                            <td className={"Pri"}>${ParsedListingPrice}</td>
                        </tr>
                        <tr className={"MobilTableRow MobilTableRowLast"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.discount}</td>
                            <td className="FeatureDiscount Pri">{purchasePriceDiscount}%</td>
                        </tr>
                    </>
                )
            }
        }


        if (analyseInfo.price_reduced) {
            let ParsedListingPrice = stringProcessingService.convertToFormat(parseInt(analyseInfo.selected_unit.list_price).toString())
            let ParsedPreviousListPrice ="";

            if(data.PrevListPrice){
                ParsedPreviousListPrice = stringProcessingService.convertToFormat(data.PrevListPrice.toString())
            }else if(data.PreviousListPrice){
                ParsedPreviousListPrice = stringProcessingService.convertToFormat(data.PreviousListPrice.toString())
            }

            if (width > 700) {
                recentDicount = (
                    <tr>
                        {/*<td><img className="DiamondIcon" src={fullDiamonIcon} alt="" /></td>*/}
                        <td><img className="checkIcon" src={checkIcon} alt="" /></td>
                        <td>{dictionary.analyse_discounted} ({analyseInfo.price_reduction_date}) <KeyDealFeatureModal>{Parser(dictionary.deal_legend_recently_discounted)}</KeyDealFeatureModal></td>
                        <td className="Tar">${ParsedPreviousListPrice}</td>
                        <td className="Separator">&nbsp;</td>
                        <td>${ParsedListingPrice}</td>
                        <td className="Separator">&nbsp;</td>
                        <td className="FeatureDiscount">{listingDiscount}%</td>
                    </tr>
                )
            }
            else {
                recentDicount = (
                    <>
                        <tr className={"MobilTableRow"}>
                            <td className={"Icon"}><img className="checkIcon" src={checkIcon} alt="" /></td>
                            <td className={"Feat"}>{dictionary.analyse_discounted} ({analyseInfo.price_reduction_date}) <KeyDealFeatureModal>{Parser(dictionary.deal_legend_recently_discounted)}</KeyDealFeatureModal></td>
                            <td className={"Pri"}>${ParsedPreviousListPrice}</td>
                        </tr>
                        <tr className={"MobilTableRow"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.the_deal}</td>
                            <td className={"Pri"}>${ParsedListingPrice}</td>
                        </tr>
                        <tr className={"MobilTableRow MobilTableRowLast"}>
                            <td></td>
                            <td className={"MobileHdr"}>{dictionary.discount}</td>
                            <td className="FeatureDiscount Pri Percent">{listingDiscount}%</td>
                        </tr>
                    </>
                )
            }

        }
    }

    // let featuresItems = this.state.keyDealFeatures.map((item, index) => {
    //     return (
    //         <tr key={index}>
    //             <td className="FeaturedItem">
    //                 <img className="DiamondIcon" src={fullDiamonIcon} alt="">
    //                 </img>
    //                 <span className="FeatureName">
    //                     {item.feature}
    //                 </span>
    //                 <span className="FeaturePrice">
    //                     {item.featurePrice}
    //                 </span>
    //             </td>
    //             <td className="CellSeparator">
    //             </td>
    //             <td className="FeaturedItem">
    //                 <span className="FeaturePrice">
    //                     {item.deal}
    //                 </span>
    //             </td>
    //             <td className="CellSeparator">
    //             </td>
    //             <td className="FeaturedItem">
    //                 <span className="FeatureDiscount">
    //                     {item.discount}
    //                 </span>
    //             </td>
    //         </tr>
    //     )
    // })

    let renderFormTop = () => {
        if (width > 700)
            return (
                <thead>
                    <tr>
                        <td colSpan="3" className={"Feature"}>{dictionary.feature}</td>
                        <td className={"Separator"}>&nbsp;</td>
                        <td className={"TheDeal"}>{dictionary.the_deal}</td>
                        <td className={"Separator"}>&nbsp;</td>
                        <td>{dictionary.discount}</td>
                    </tr>
                </thead>
            )
        else {
            return null;
        }
    }

    let result = null;
    let minRank = 2;
    // if(selection) minRank =2
    if (analyseInfo !== undefined &&analyseInfo.rank >= minRank) {
        result = (
            <div className="KeyDealBlock InfoBlock">
                <div className="TitleDiv">
                    <div className={"SectionHdr"}>
                        <HOne title={dictionary.key_deal_features} align={"left"} addclass={"xsmall"} />
                    </div>
                    {/*<span className="Title"></span>*/}
                    <div className="TitleRightBlock">
                        {/* <img className="Icon" src={smartDealImg} alt=""/> */}
                        {/*{this.state.analyseInfo.rank > 3 ? <img className="Icon SmartDealIconListing" src={smartDealImg} alt="" /> : null}*/}
                        {/*<React.Fragment>{diamonds}</React.Fragment>*/}
                    </div>
                </div>
                {/* <img className={"infoIcon"} src={info} alt=""
                                onMouseOver={e => {
                                    e.target.src = infoDark;
                                }}

                                onMouseOut={e => {
                                    e.target.src = info;
                                }}

                                onClick={() => {
                                    setIsVisibleModalInfo(true)
                                }}
                            />
                */}
                <div>
                    <table className="KeyDealTable" border="0" cellPadding="0" cellSpacing="0">
                        {renderFormTop()}
                        <tbody>
                            {/* {featuresItems} */}
                            {listingPrice}
                            {averageSoldPrice}
                            {origintalPrice}
                            {recentDicount}
                        </tbody>
                    </table>
                    {/* {featuresItems} */}
                </div>
                <Modal
                        isVisible={isVisibleModalInfo}
                        closeModal={()=>{setIsVisibleModalInfo(false)}}
                    >
                        <div className="InfoModal">
                            {Parser(dictionary.deal_legend)}

                        </div>
                        <div onClick={()=>{setIsVisibleModalInfo(false)}}>
                            <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                                <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                                    <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                    <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                </g>
                            </svg>

                        </div>
                </Modal>
            </div>
        )
    } else {
        result = null;
    }

    return (
        result
    )

}