import React from 'react';
import Parser from 'html-react-parser';
import FormCheckService from '../../services/form-check-service';
import ServerApi from '../../services/server-api-service';
import CookiesManager from '../../services/cookies-manager';
import './wizard-page-style.css';
import { withRouter } from 'react-router-dom';
import HOne from "../h-one";
import OffersGrid from '../offers-grid';
import Modal from '../modal';
import UserForm from '../user-form';
import Helmet from 'react-helmet';
import SeoService from '../../services/seo-service';

const initialState = {
    currentStep: 1,
    flagMaxStep: 1,
    isVisibleContactFormModal: false,//true
    formSended: false,
    selectedItems: {
        preferPriceId: 0,
        preferBedroomsId: 0,
        // preferBathroomsId: 0,
        preferTimelineId: 0,
        preferUsageId: 0,
    },
    info: {
        preferPrice: '0', //0
        preferBedrooms: '0',//0
        // preferBathrooms: 0,
        preferTimeline: "",//''
        preferUsage: '',
        contactInfo: {
            project: 'Wizard',
            sample_deals: '',
            userComment: "",
            userEmail: "",
            userFullName: "",
            userPhone: ""
        }
    }
}
class WizardPage extends React.Component {
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    cookiesManager = null;
    seoService = new SeoService();
    constructor(props) {
        super(props);
        // this.state = {
        //     currentStep: 1,
        //     flagMaxStep: 1,
        //     selectedItems: {
        //         preferPriceId: 0,
        //         preferBedroomsId: 0,
        //         // preferBathroomsId: 0,
        //         preferTimelineId: 0,
        //     },
        //     info: {
        //         preferPrice: 0,
        //         preferBedrooms: 0,
        //         // preferBathrooms: 0,
        //         preferTimeline: "",
        //         contactInfo: {
        //             userComment: "",
        //             userEmail: "",
        //             userFullName: "",
        //             userPhone: ""
        //         }
        //     }

        // }
        this.state = initialState;
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        this.cookiesManager = new CookiesManager();
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'WizardPage' })
        this.setState(initialState);
        this.getNeighborhoodInfo();
    }

    getNeighborhoodInfo() {
        if (this.props.location.state !== undefined) {
            this.setState({
                info: {
                    ...this.state.info,
                    area: this.props.location.state.area
                }
            })
        }
        if (this.props.match.params.id && this.props.location.state === undefined) {
            this.serverApi.getNeighborhoodPageInfo(this.props.match.params.id)
                .then((data) => {
                    if (this.state.debug) console.log(data);
                    this.setState({
                        neighborhoodData: data,
                        info: {
                            ...this.state.info,
                            area: data.area.name
                        }
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getNeighborhoodPageInfo ... -> Component: NeighborhoodPage  ERROR:${error}`);
                })
        }
    }

    handleInput(event) {
        this.setState({
            info: {
                ...this.state.info,
                contactInfo: {
                    ...this.state.info.contactInfo,
                    [event.target.name]: event.target.value
                }
            }
        })
    }

    searchOffers() {
        if (!this.state.sampleListings) {
            this.serverApi.getSampleListings(this.state.info)
                .then((data) => {
                    if (this.state.debug) {
                        console.log('searchOffers', data);
                    }
                    let deals = '';

                    for (let i = 0; i < data.length; i++) {
                        if (i === 0) deals += `${data[i].id}`
                        else deals += `, ${data[i].id}`
                    }
                    // data.map((item,index)=>{
                    //     if(index===0) deals+=`${item.id}`
                    //     else deals += `, ${item.id}`

                    // })
                    this.setState({
                        sampleListings: data,
                        info: {
                            ...this.state.info,
                            contactInfo: {
                                ...this.state.info.contactInfo,
                                sample_deals: deals
                            }
                        }
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch searchOffers ... -> Component: Wizard-Page ERROR:${error}`);
                })
        }
    }

    openThanksModal = () => {
        this.setState({
            isVisibleContactFormModal: false,
            formSended: true
        })
    }

    handleSubmit(number = '') {
        if (!this.formCheckService.checkContactForm(this.state.info.contactInfo)) {
            let form = Object.assign({}, this.state.info);
            form.contactInfo.userPhone = number + this.state.info.contactInfo.userPhone;
            this.serverApi.sendForm(form)
                .then((data) => {
                    this.cookiesManager.set('exit_pop', '1', 365)
                    if (this.state.debug) {
                        console.log(data);
                    }
                    this.setState({
                        isVisibleContactFormModal: false,
                        formSended: true
                    })
                    // if (this.validationStep()) {
                    //     let next = this.state.currentStep + 1;
                    //     this.setState({
                    //         ...this.state,
                    //         currentStep: next
                    //     })
                    // }
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
                })
        }
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            info: {
                ...this.state.info,
                contactInfo: {
                    project: 'Wizard',
                    sample_deals: '',
                    userComment: "",
                    userEmail: "",
                    userFullName: "",
                    userPhone: ""
                }
            }
        })
    }

    handleSelectFirst(data) {

        const arr = Object.entries(data);


        let maxStep = this.state.flagMaxStep;
        if (maxStep < this.state.currentStep + 1) {
            maxStep = this.state.currentStep + 1;
        }
        if (this.state.currentStep === 5) {
            this.searchOffers();
        }
        this.setState({
            ...this.state,
            currentStep: this.state.currentStep + 1,
            flagMaxStep: maxStep,
            selectedItems: {
                ...this.state.selectedItems,
                [arr[0][0]]: arr[0][1]
            },
            info: {
                ...this.state.info,
                [arr[1][0]]: arr[1][1]
            }
        })


        // this.setState({
        //     ...this.state,
        //     selectedItems:{
        //         ...this.state.selectedItems,
        //         preferPrice:event.id
        //     },
        //     info:{
        //         preferPrice:event.value
        //     }
        // })
    }

    handleNext(e) {
        let next = this.state.currentStep + 1;

        if (e.target.name === 'next') {
            this.setState({
                ...this.state,
                currentStep: next,
                flagMaxStep: 2
            })
        }
        if (this.validationStep()) {
            this.setState({
                ...this.state,
                currentStep: next
            })
        }
    }

    handlePrev() {
        let prev = this.state.currentStep - 1
        this.setState({
            ...this.state,
            currentStep: prev
        })
    }

    handleBackStep(index) {
        this.setState({
            ...this.state,
            currentStep: index
        })
    }


    closeModal = () => {
        this.setState({
            isVisibleContactFormModal: false
        })
    }

    renderBackNextButtons() {
        let { dictionary } = this.props;
        let result = [];
        let { currentStep, flagMaxStep } = this.state;

        if (currentStep === 1) {
            result.push(<button className="Button" id="ButtonNext" name='next' onClick={this.handleNext}>{dictionary.start}</button>)
        }
        if (currentStep > 1 && currentStep !== 6) {
            result.unshift(<button className="Button" id="ButtonPrev" onClick={this.handlePrev}>{dictionary.back}</button>);
        }
        if (currentStep < flagMaxStep && currentStep !== 1 && currentStep !== 6) {
            result.push(<button className="Button" id="ButtonNext" onClick={this.handleNext}>{dictionary.next}</button>)
        }
        return (<React.Fragment>{result}</React.Fragment>)
    }



    stepsRenderItem(index) {
        let currentStep = this.state.currentStep;
        if (index === 4) {
            if (currentStep === index) {
                return (
                    <>
                        <div className="Item Current">{index}</div>
                    </>
                )
            } else if (index < currentStep) {
                return (
                    <>
                        <div onClick={this.handleBackStep.bind(this, index)} className="Item PrevItem">&#10003;</div>
                    </>
                )
            } else if (index < this.state.flagMaxStep + 1) {
                return (
                    <>
                        <div onClick={this.handleBackStep.bind(this, index)} className="Item PrevItem">&#10003;</div>

                    </>
                )
            }
            else {
                return (
                    <>
                        <div className="Item">{index}</div>
                    </>
                )
            }
        } else {
            if (currentStep === index) {
                return (
                    <>
                        <div className="Item Current">{index}</div>
                        <div className="Separator"></div>
                    </>
                )
            } else if (index < currentStep) {
                return (
                    <>
                        <div onClick={this.handleBackStep.bind(this, index)} className="Item PrevItem">&#10003;</div>
                        <div className="Separator PrevSeparator"></div>
                    </>
                )
            } else if (index < this.state.flagMaxStep + 1) {
                return (
                    <>
                        <div onClick={this.handleBackStep.bind(this, index)} className="Item PrevItem">&#10003;</div>
                        <div className="Separator PrevSeparator"></div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className="Item">{index}</div>
                        <div className="Separator"></div>
                    </>
                )
            }
        }
    }

    validationStep() {

        // contactInfo: {
        //     userComment:"",
        //     userEmail:"",
        //     userFullName:"",
        //     userPhone:""
        // }
        let currentStep = this.state.currentStep;
        let data = this.state.info;
        let positions = this.state.selectedItems;
        //let contactInfo = this.state.info.contactInfo;
        let { dictionary } = this.props;


        if (currentStep === 2 && data.preferPrice === 0 && positions.preferPriceId === 0) {

            return false;
        }
        // if (currentStep === 2 && data.preferBedrooms === 0 && positions.preferBedroomsId === 0) {
        //     return false;
        // }
        if (currentStep === 4 && data.preferBathrooms === 0 && positions.preferBathroomsId === 0) {
            return false;
        }
        if (currentStep === 5 && data.preferTimeline === "" && positions.preferTimelineId === 0) {
            return false;
        }
        if (currentStep === 6) {
            if (this.formCheckService.checkEmptyField(data.contactInfo.userFullName, dictionary.full_name) ||
                this.formCheckService.checkEmptyField(data.contactInfo.userPhone, dictionary.phone) ||
                this.formCheckService.checkEmail(data.contactInfo.userEmail, dictionary.email)) {
                return false;

            }
        }
        return true;
    }

    stepsRender = () => {
        let totalSteps = 4, procent, result = null;
        let { width, country } = this.props;
        let { currentStep } = this.state;
        procent = (currentStep - 2) * 100 / totalSteps;
        if (currentStep === 1) procent = 0;
        if (currentStep >= 2) procent += 10;
        let attribute = 'height';
        if (width < 1000) {
            attribute = 'width'
        }
        // if (this.state.currentStep !== 5) {
        //     result = (
        //         <div className="WizardSelectSteps">
        //             {this.stepsRenderItem(1)}
        //             {this.stepsRenderItem(2)}
        //             {this.stepsRenderItem(3)}
        //             {this.stepsRenderItem(4)}
        //             {/* {this.stepsRenderItem(5)} */}
        //         </div>)
        // }

        let style = {
            [attribute]: `${procent}%`
        }
        if (currentStep >= 2 && currentStep < 6) {
            result = (
                <div className="StatusBar">
                    <div className="StatusProgress" style={style}>

                    </div>
                </div>
            )
        }

        return result;
    };

    renderDesc() {
        let { dictionary, country } = this.props;
        let parsedDesc = '';
        if (this.props.location.state !== undefined && this.props.location.state !== '') {
            parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.props.location.state.area}</b>`));
        } else if (this.state.neighborhoodData) {
            parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.state.neighborhoodData.area.name}</b>`));
        } else {
            parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', dictionary.wizard_default_location));
        }
        if (this.state.currentStep !== 6) {
            return (
                <div className={"WizardBodyText"}>
                    <HOne addclass={"upper"} title={dictionary.wizard_main_title} color={'black'} poweredBy={false} dictionary={dictionary} />
                    <br />
                    {parsedDesc}
                    <br />

                    <br />
                    <div className={"PoweredBy PoweredByBottom"}>
                        {Parser(dictionary.slogan_powered_by)}
                    </div>
                </div>
            )
        }
    }

    renderNeighborhoodImg() {
        let { dictionary } = this.props;
        let parsedDesc = '';
        if (this.props.location.state !== undefined && this.props.location.state !== '') {
            parsedDesc = dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.props.location.state.area}</b>`);
        } else if (this.state.neighborhoodData) {
            parsedDesc = dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.state.neighborhoodData.area.name}</b>`);
        } else {
            parsedDesc = dictionary.wizard_desc.replaceAll('[wizard_area_location]', dictionary.wizard_default_location);
        }

        if ((this.props.location.state && this.props.location.state.neighborhoodImg) || (this.props.match.params.id && this.props.match.params.name && this.state.neighborhoodData)) {
            if (window.orientation === undefined || (window.orientation !== undefined && window.orientation === 0)) {
                let imgName = '', nameNeighborhood = '';



                if (this.props.location.state) {
                    let { area, neighborhoodImg } = this.props.location.state;
                    imgName = neighborhoodImg;
                    nameNeighborhood = area;
                }
                else {

                    let { image_cover, name } = this.state.neighborhoodData.area;
                    imgName = image_cover;
                    nameNeighborhood = name;
                }

                return (
                    //see neighborhood-page-style.css
                    <>
                        <Helmet>
                            <title>{nameNeighborhood} | {dictionary.wizard_main_title} | Smart Luxe</title>
                            <meta name="description" content={parsedDesc} />
                        </Helmet>

                        <div className="PageLogo">
                            <img id="PageLogoImg" alt="Logo" src={`https://ngirealty.com/img/smart_luxe/areas/cover/${imgName}`}></img>
                            <div id="PageLogoTitle">
                                <HOne  line={"lagre"} addclass={"lagresize"} title={nameNeighborhood} />
                            </div>
                        </div>
                    </>
                )
            }
        } else return (

            <Helmet>
                <title> {dictionary.wizard_main_title} | Smart Luxe</title>
                <meta name="description" content={parsedDesc} />
            </Helmet>

        )
    }

    renderPoweredBy() {
        let { dictionary } = this.props;
        return (
            <div className={"PoweredBy"}>
                {Parser(dictionary.slogan_powered_by)}
            </div>
        )
    }

    render() {
        let body = null;
        const iconLock = require('../../elements/icons/s_secured.png');
        const loadingImg = require('../../elements/images/processing.png');

        let { dictionary, width, country } = this.props;
        //parse description
        // let parsedDesc = '';
        // if (this.props.location.state !== undefined && this.props.location.state !== '') {
        //     parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.props.location.state.area}</b>`));
        // } else {
        //     parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', dictionary.wizard_default_location));
        // }

        switch (this.state.currentStep) {
            case 1: {
                let parsedDesc = '';
                if (this.props.location.state !== undefined && this.props.location.state !== '') {
                    parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.props.location.state.area}</b>`));
                } else if (this.state.neighborhoodData) {
                    parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', `<b>${this.state.neighborhoodData.area.name}</b>`));
                }
                else {
                    parsedDesc = Parser(dictionary.wizard_desc.replaceAll('[wizard_area_location]', dictionary.wizard_default_location));
                }

                body = (
                    <div className="WizardBody">
                        {/* <HOne title={dictionary.wizard_price_range} color={'black'} poweredBy={false} /> */}
                        {/* <div className={"WizardBodyText"}>
                        {dictionary.wizard_price_range_desc}
                    </div> */}
                        <HOne tag={"h1"} addclass={"upper"} title={dictionary.wizard_main_title} color={'black'} poweredBy={false} dictionary={dictionary} />
                        <div className={"WizardBodyText"}>
                            <div className={"BuySmartText1"}>{parsedDesc}</div>
                            <div className={"BuySmartText2"}>{dictionary.wizard_select}</div>
                            <br />
                            {/*<div className={"PoweredBy PoweredByBottom"}>*/}
                            {/*    {Parser(dictionary.slogan_powered_by)}*/}
                            {/*</div>*/}
                        </div>
                        {this.renderBackNextButtons()}

                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>
                        {/* <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
                    </div>
                )
                break;
            }
            case 2: {
                body = (
                    <div className="WizardBody">
                        <HOne addclass={"upper"} title={dictionary.wizard_price_range} color={'black'} poweredBy={false} />
                        <div className={"WizardBodyText"}>
                            {dictionary.wizard_price_range_desc}
                        </div>
                        <div className="WizardSelectDiv">
                            <div className={this.state.selectedItems.preferPriceId === 1 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferPriceId: 1, preferPrice: 1 })}>
                                <div >
                                    {dictionary.below} <span>$500K</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferPriceId === 2 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferPriceId: 2, preferPrice: 2 })}>
                                <div>
                                    <span>$500K</span> {dictionary.to} <span>$1.5M  </span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferPriceId === 3 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferPriceId: 3, preferPrice: 3 })}>
                                <div>
                                    {dictionary.above}  <span>$1.5M</span>
                                </div>
                            </div>
                        </div>
                        {this.renderBackNextButtons()}
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>
                        {/* <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
                    </div>
                )
                break;
            }
            case 3: {
                body = (
                    <div className="WizardBody">
                        {/* <div className={"WizardBodyText"}>
                            {parsedDesc}
                        </div> */}
                        <HOne addclass={"upper"} title={dictionary.bedrooms.toUpperCase()} />
                        <div className={"WizardBodyText"}>
                            {dictionary.wizard_bedrooms_desc}
                        </div>
                        <div className="WizardSelectDiv WizardSelectDivTwo">
                            <div className={this.state.selectedItems.preferBedroomsId === 1 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBedroomsId: 1, preferBedrooms: "1" })}>
                                <div>
                                    <span>1</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferBedroomsId === 2 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBedroomsId: 2, preferBedrooms: "2" })}>
                                <div>
                                    <span>2</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferBedroomsId === 3 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBedroomsId: 3, preferBedrooms: "3" })}>
                                <div>
                                    <span>3</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferBedroomsId === 4 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBedroomsId: 4, preferBedrooms: "4" })}>
                                <div>
                                    <span>4+</span>
                                </div>
                            </div>
                        </div>

                        {this.renderBackNextButtons()}
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>
                        {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>
                        <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
                    </div>
                )
                break;
            }
            // case 2: {
            //     body = (
            //         <div>
            //             <HOne title={dictionary.bathrooms.toUpperCase()} />
            //             <br></br>
            //             {dictionary.wizard_bathrooms_desc}
            //             <div className="WizardSelectDiv WizardSelectDivTwo">
            //                 <div className={this.state.selectedItems.preferBathroomsId === 1 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBathroomsId: 1, preferBathrooms: "1" })}>
            //                     <div>
            //                         <span>1</span>
            //                     </div>
            //                 </div>
            //                 <div className={this.state.selectedItems.preferBathroomsId === 2 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBathroomsId: 2, preferBathrooms: "2" })}>
            //                     <div>
            //                         <span>2</span>
            //                     </div>
            //                 </div>
            //                 <div className={this.state.selectedItems.preferBathroomsId === 3 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBathroomsId: 3, preferBathrooms: "3" })}>
            //                     <div>
            //                         <span>3</span>
            //                     </div>
            //                 </div>
            //                 <div className={this.state.selectedItems.preferBathroomsId === 4 ? "WizardSelectCurrentItem WizardSelectItem WizardSelectItemTwo" : "WizardSelectItem WizardSelectItemTwo"} onClick={this.handleSelectFirst.bind(this, { preferBathroomsId: 4, preferBathrooms: "4" })}>
            //                     <div>
            //                         <span>4+</span>
            //                     </div>
            //                 </div>
            //             </div>
            //             {this.renderBackNextButtons()}
            //             {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>
            //             <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
            //         </div>
            //     )
            //     break;
            // }
            case 4: {
                body = (
                    <div className="WizardBody">
                        {/* <div className={"WizardBodyText"}>
                            {parsedDesc}
                        </div> */}
                        <HOne addclass={"upper"} title={dictionary.wizard_timeline} />
                        <div className={"WizardBodyText"}>
                            {dictionary.wizard_timeline_desc}
                        </div>
                        <div className="WizardSelectDiv">

                            <div className={this.state.selectedItems.preferTimelineId === 1 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferTimelineId: 1, preferTimeline: "NOW" })}>
                                <div>
                                    <span>{dictionary.now}</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferTimelineId === 2 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferTimelineId: 2, preferTimeline: "SOON" })}>
                                <div>
                                    <span>{dictionary.soon}</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferTimelineId === 3 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferTimelineId: 3, preferTimeline: "LATER" })}>
                                <div>
                                    <span>{dictionary.later}</span>
                                </div>
                            </div>
                        </div>
                        {this.renderBackNextButtons()}
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>
                        {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>
                        <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
                    </div>
                )
                break;
            }

            case 5: {
                body = (
                    <div className="WizardBody">
                        {/* <div className={"WizardBodyText"}>
                            {parsedDesc}
                        </div> */}
                        <HOne addclass={"upper"} title={dictionary.wizard_usage} />
                        <div className={"WizardBodyText"}>
                            {dictionary.wizard_usage_desc}
                        </div>
                        <div className="WizardSelectDiv">

                            <div className={this.state.selectedItems.preferUsageId === 1 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferUsageId: 1, preferUsage: "p" })}>
                                <div>
                                    <span>{dictionary.usage_primary}</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferUsageId === 2 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferUsageId: 2, preferUsage: "s" })}>
                                <div>
                                    <span>{dictionary.usage_secondary}</span>
                                </div>
                            </div>
                            <div className={this.state.selectedItems.preferUsageId === 3 ? "WizardSelectCurrentItem WizardSelectItem" : "WizardSelectItem"} onClick={this.handleSelectFirst.bind(this, { preferUsageId: 3, preferUsage: "i" })}>
                                <div>
                                    <span>{dictionary.usage_investment}</span>
                                </div>
                            </div>
                        </div>
                        {this.renderBackNextButtons()}
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>
                        {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>
                        <button className="Button" id="ButtonNext" onClick={this.handleNext}>NEXT</button> */}
                    </div>
                )
                break;
            }
            case 6: {
                //loading screen
                setTimeout(() => {
                    this.setState((prevState, props) => {
                        return { currentStep: 7 };
                    })
                    setTimeout(() => {
                        this.setState({
                            isVisibleContactFormModal: true
                        })
                    }, 100)
                }, 2000)
                body = (
                    <div className="WizardThankBlock">
                        <HOne title={dictionary.looking_for_deals} />
                        <div className={"WizardLoadingBlock"}>
                            <img className='WizardLoading' src={loadingImg} alt='loading' />
                            <div className="WizardLoadingTitle">
                                {dictionary.processing}
                            </div>
                        </div>
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>


                    </div>
                )
                break;
            }
            // case 6: {

            //     body = (
            //         <div className="WizardBody">
            //             {/* <div className={"WizardBodyText"}>
            //                 {parsedDesc}
            //             </div> */}
            //             <HOne addclass={"upper"} title={dictionary.wizard_contact_information} />
            //             {/* <div className={"WizardBodyText"}>
            //                 {dictionary.wizard_contact_desc}
            //             </div> */}
            //             <div className="WizardContactDiv">
            //                 <div>
            //                     {/* <div className="TopBlock">
            //                         <input type="text" name="userFullName" className="Input" placeholder={dictionary.full_name} value={this.state.info.contactInfo.userFullName} onChange={this.handleInput} />
            //                         <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.info.contactInfo.userPhone} onChange={this.handleInput} />
            //                     </div> */}
            //                     <input type="text" name="userFullName" className="Input" placeholder={dictionary.full_name} value={this.state.info.contactInfo.userFullName} onChange={this.handleInput} />
            //                     <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.info.contactInfo.userPhone} onChange={this.handleInput} />

            //                     <input type="text" name="userEmail" className="Input" placeholder={dictionary.email} value={this.state.info.contactInfo.userEmail} onChange={this.handleInput} />
            //                     {/* <textarea type="text" name="userComment" className="Input InputComments" placeholder={dictionary.comments} value={this.state.info.contactInfo.userComment} onChange={this.handleInput} /> */}
            //                 </div>
            //                 <div className="SecureDiv">
            //                     <img className="SecureIcon" src={iconLock} alt="" />
            //                     <span className="Text">
            //                         {dictionary.information_secure}
            //                     </span>
            //                 </div>

            //             </div>
            //             <br />
            //             {this.renderBackNextButtons()}
            //             {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>BACK</button>*/}
            //             <button className="Button" id="ButtonNext" onClick={this.handleSubmit}>{dictionary.submit}</button>
            //             <div className={"PoweredByBlock"}>
            //             {this.renderPoweredBy()}
            //             </div>
            //             <br /><br />
            //             <OffersGrid
            //                 title={dictionary.sample_deals}
            //                 data={this.state.sampleListings}
            //                 width={this.props.width}
            //                 dictionary={dictionary}
            //                 disable={true}
            //                 verticalList={true}
            //                 wizardOffers={true}
            //                 blured={true}
            //             />
            //         </div>
            //     )
            //     break;
            // }

            case 7: {
                let parsedThankDesc = Parser(dictionary.pinpointed);
                let parsedNext = Parser(dictionary.thank_you_next);
                body = (
                    <div className="WizardThankBlock">
                        {this.state.formSended ? (<HOne addclass={"upper"} title={dictionary.thank_you} />) : (null)}

                        {this.state.sampleListings.length > 0 ? (
                            <div className="WizardContactThank">
                                {parsedThankDesc}
                            </div>
                        ) : (null)}
                        {/* <button className="Button" id="ButtonPrev" onClick={this.handlePrev}>{dictionary.back}</button> */}
                        <div className={"PoweredByBlock"}>
                            {this.renderPoweredBy()}
                        </div>

                        <OffersGrid
                            title={dictionary.sample_deals}
                            data={this.state.sampleListings}
                            width={width}
                            dictionary={dictionary}
                            disable={true}
                            limit={width <= 800 ? 2 : this.state.sampleListings.length}
                            verticalList={true}
                            wizardOffers={true}
                            blured={this.state.isVisibleContactFormModal}
                        />
                        {this.state.formSended ? (
                            <div className="WizardLastBlock">
                                <HOne title={dictionary.what_is_next} />
                                <div className="WizardLastBlockText">
                                    {parsedNext}
                                </div>
                            </div>
                        ) : (null)}

                        <Modal
                            isVisible={this.state.isVisibleContactFormModal}
                            closeModal={this.closeModal}
                            opportunityToClose={false}
                        >
                            {/* HTML contact form for modal -> write inside tag */}

                            <div className="Body">

                                <HOne addclass={"upper"} title={dictionary.wizard_contact_information} />
                                <div className={"WizardBodyText"}>
                                    {dictionary.wizard_contact_desc}
                                </div>
                                <div className="WizardContactDiv">
                                    {/* <div>
                                        <div className="TopBlock">
                                     <input type="text" name="userFullName" className="Input" placeholder={dictionary.full_name} value={this.state.info.contactInfo.userFullName} onChange={this.handleInput} />
                                     <input type="text" name="userPhone" className="Input" placeholder={dictionary.phone} value={this.state.info.contactInfo.userPhone} onChange={this.handleInput} />
                                 </div>


                                        <input type="text" name="userEmail" className="Input" placeholder={dictionary.email} value={this.state.info.contactInfo.userEmail} onChange={this.handleInput} />
                                    </div> */}
                                    <UserForm
                                        clearForm={this.clearForm}
                                        topPartRow={true}
                                        contactForm={this.state.info.contactInfo}
                                        formForSend={this.state.info}
                                        handleInput={this.handleInput}
                                        width={width}
                                        saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                                        dictionary={dictionary}
                                        country={country}
                                        openThanksModal={this.openThanksModal}
                                        buttonTitle={dictionary.get_deals}
                                        secureInfo={true}
                                        handleSubmit={this.handleSubmit}
                                        inputBans={{
                                            comment: false,
                                            message: false,
                                        }}
                                        className={'Form'}
                                        style={{ textAlign: 'center', justifyContent: 'center' }}
                                    />
                                    {/* <div className="SecureDiv">
                                        <span className="Text">
                                            <img className="SecureIcon" src={iconLock} alt="" />
                                            {dictionary.information_secure}
                                        </span>
                                    </div> */}

                                </div>
                                {/* <button className="Button" id="ButtonNext" onClick={this.handleSubmit}>{dictionary.get_deals}</button> */}
                                <div className={"PoweredByBlock"}>
                                    {this.renderPoweredBy()}
                                </div>
                            </div>

                        </Modal>

                    </div>
                )
                break;
            }
            default: { }
        }

        return (
            <div className="PageBodyGrey WizardPage">
                {/* <div className="Block" >
                    {this.renderDesc()}

                </div> */}

                <Helmet>
                    {this.seoService.insertHrefLangTags(
                        {
                            path: `/find-my-deal`,

                        })
                    }
                </Helmet>
                {this.renderNeighborhoodImg()}
                <div className="Block WizardBlock" >
                    <div className="WizardFrame">
                        {body}

                        {this.stepsRender()}

                    </div>

                </div>

            </div>
        )
    }
}

export default withRouter(WizardPage);