import React from 'react';

import NeighborhoodItem from '../neighborhood-item';

import './neighborhoods-grid-style.css';

class NeighborhoodsGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            debug: false,
        }
    }

    renderBody() {
        if (this.props.data !== undefined) {
            let { data,  } = this.props; //alignTitle  = "center"
            return (
                <div className="GridMain" >
                    {/* <HOne title={this.props.title} align={alignTitle} />
                    <br /><br /> */}
                    <div className="Grid">
                        {/* items */}
                        {data.map((item, index) => {
                            return (<NeighborhoodItem data={item} key={index} />)
                        })}
                    </div>
                </div>
            )
        }
    }
    render() {
        return (this.renderBody());
    }

}

export default NeighborhoodsGrid;