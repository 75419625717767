import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

import {
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";


function Info(props) {
    if (props.handleOnMarkerHover) return (props.handleOnMarkerHover(props.data))
    else return null;
}

function handleOnClick(props) {
    let {data, selectionId, agentId, history,soldOutMarker=false } = props;
    let pathname = document.location.pathname;

    let pageName = '';

    for (let i = 0; i < pathname.length; i++) {
        if (pathname[i] === '/' && i !== 0) break;
        pageName += pathname[i];
    }
    console.log(pageName)
    if(!soldOutMarker || data.units_total!==data.units_sold){
        switch (pageName) {
            case '/listing': {
                history.push({
                    pathname: `/listing/${data.id}`,
                })
                break;
            }
            case '/selection': {
                let path = '';
                if(data.offerId){
                    path = `/selection/${selectionId}/offer/${data.offerId}`
                    history.push({
                        pathname: path,
                    })
                }else{
                    if (agentId !== undefined && agentId !== null) path = `/selection/${selectionId}/${agentId}/listing/${data.id}`;
                    else path = `/selection/${selectionId}/listing/${data.id}`
                    history.push({
                        pathname: path,
                    })
                }
                break;
            }
            case '/projects': {
                history.push({
                    pathname: `/projects/${data.id}/${data.name}`,
                })
                break;
            }
           
            default: {
                history.push({
                    pathname: `/listing/${data.id}`,
                })
                break;
            }
        }
    }
}

function CustomMarker(props) {
    let { position,currentSelectionListing, data,key, selectionId, agentId, similarOffers,secondaryMarker = false,soldOutMarker=false,hoveredMarkerId,currentPage} = props;
    const [isVisibleInfo, setVisibleInfo] = useState(false);
    const [isHover, setIsHover] = useState(false);
    // function handleOnClick() {
    //     props.history.push({
    //         pathname: pathName,
    //         state: {
    //             // similarOffers: similarOffers
    //         }
    //     })
    // }
    let markerName = '';
    if(secondaryMarker || currentPage==='SelectionPage'|| currentPage==='SearchPage' ){
        markerName = 'smartluxe-map-pointer-small.png';
    }else if(soldOutMarker){
        markerName = 'smartluxe-map-pointer-small-sold-out.png';
    }else{
        markerName = 'smlx_marker_a.png'
    }

    
    // if( ((isHover||hoveredMarkerId===data.id) && (currentPage==='SelectionPage'||currentPage==='SelectionListingPage'))
    // ||(currentSelectionListing!= null && currentSelectionListing.ListingId===data.id)
    // ){
    //     icon = {
    //         url: `https://ngirealty.com/resource/data/${markerName}`,
    //         scaledSize: {width:70,height:70}
    //     }
    // }

    if(currentSelectionListing != null && ((currentSelectionListing.ListingId!=null &&(currentSelectionListing.ListingId!==data.id)) ||
    (currentSelectionListing.id != null && currentSelectionListing.id !== data.id))){
        markerName = 'smartluxe-map-pointer-small.png'
    }


    if(isHover || hoveredMarkerId === data.id){
        markerName = 'smlx_marker_a.png'
    }

    let icon = {
        url: `https://ngirealty.com/resource/data/${markerName}`,
    }
    
    return (
        <Marker
            key={key}
            tracksViewChanges={false}
            icon={icon}
            onMouseOver={(event) => {
                if (document.location.pathname.includes('/projects')||
                    document.location.pathname.includes('/search')) {
                    setVisibleInfo(true)
                }else {
                    
                }
                setIsHover(true)
                document.dispatchEvent(new CustomEvent('markerHover', { detail: { id: data.id } }))
            }}
            onMouseOut={() => {
                if (document.location.pathname.includes('/projects')||
                    document.location.pathname.includes('/search')) setVisibleInfo(false)
                setIsHover(false)
                document.dispatchEvent(new CustomEvent('markerHover', { detail: { id: null } }))
                
            }}
            onClick={() => { handleOnClick(props) }}
            position={position}>
            {(isVisibleInfo || hoveredMarkerId === data.id) && (currentPage ==='ProjectInnerPage'||currentPage==='ConstructionLobbyPage' ||currentPage==='ConstructionLobbyPage'||currentPage==='SearchPage') ? ( //isVisibleInfo
                <InfoWindow
                    key={key}
                    position={position}
                    onCloseClick={() => { setVisibleInfo(false) }}
                >
                    {Info(props)}
                </InfoWindow>
            ) : null}
        </Marker>
    )
}

export default withRouter(CustomMarker);