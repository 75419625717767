import React from 'react';
import Slider from 'react-slick';


import NeighborhoodItem from '../neighborhood-item';

import './neighborhoods-style.css';
import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';
import HOne from "../h-one";
import { Link } from 'react-router-dom';


class Neighborhoods extends React.Component {
    state = {
    }

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            rows: 1,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1170,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 570,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ],
        };

        let {data,dictionary} = this.props;
        let SliderItems = null;
        if (data !== undefined && data.length > 0) {
            SliderItems = data.map((item, index) => {
                return (
                    // <Link key={index} to={`/neighborhood/${item.id}`}>
                    //     <div className="NeighboorSliderItem">
                    //         <div className="Overlay"></div>
                    //         <img src={`https://ngirealty.com/img/smart_luxe/areas/thumbs/${item.image_cover}`} alt=""></img>
                    //         <div>{item.name}</div>
                    //     </div>
                    // </Link>
                    <NeighborhoodItem data={item} slider={true} key={index}/>
                )
            })
        }
        return (
            <div className="HeighborhoodBlock">
                <Link to={'/neighborhoods'}><HOne 
                color={'black'} 
                title={dictionary.neighborhoods} /></Link>
                <br /><br /><br />
                <Slider {...settings}>
                    {SliderItems}
                </Slider>
            </div>
        )
    }
}

export default Neighborhoods;