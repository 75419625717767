import GoogleMapStaticCustom from '../google-map-static-custom';
import HOne from '../h-one';

function renderInformation(props) {
    let {data, width, selection = false } = props;

    
    let googleMapSize = {
        width: '1280',
        height: '1280',
        scale: 2
    }

    if (width > 1000) {
        googleMapSize.width = '650';
        googleMapSize.height = '400';
        googleMapSize.scale = 1;
    }


    if (selection && width < 1000) {
        return (
            <div className="RightBlock">
                <GoogleMapStaticCustom
                    lat={data.Latitude}
                    lng={data.Longitude}
                    googleMapSize={googleMapSize}
                />
            </div>
        )
    } else if (!selection) {
        return (
            <div className="RightBlock">
                <GoogleMapStaticCustom
                    lat={data.Latitude}
                    lng={data.Longitude}
                    googleMapSize={googleMapSize}
                />
            </div>
        )
    } else return null;
}

export default function Information(props) {
    let { dictionary, data,selection} = props;

    // let googleMapSize = {
    //     width: '1280',
    //     height: '1280',
    //     scale: 2
    // }

    // if (width > 1000) {
    //     googleMapSize.width = '650';
    //     googleMapSize.height = '400';
    //     googleMapSize.scale = 1;
    // }

    // let googleWidth = '50%';
    // if (width <= 1000) googleWidth = "100%";
    // let ParsedListingPrice = this.stringProcessingService.convertToFormat(data.ListPrice.toString());
    return (
        <div className="DealBlockProperty InfoBlock">
            <div className="LeftBlock">
                <div className={"SectionHdr"}>
                    <HOne title={dictionary.property_information} align={"left"} addclass={"xsmall"} />
                </div>
                <div>
                    <table className="PropertyInfoTable">
                        <thead>
                        </thead>
                        <tbody >
                            {/*<tr>*/}
                            {/*    <th>{dictionary.general_information}</th>*/}
                            {/* <th></th>
                            <th>General inforamtion</th> */}
                            {/*</tr>*/}
                            <tr>
                                <td>
                                    <ul>
                                        {data.summary.map((item, index) => {
                                            if(selection){
                                                if(index < data.summary.length-2){
                                                    return (<li key={index}>{item}</li>)
                                                }
                                            }
                                            else{
                                                return (<li key={index}>{item}</li>)
                                            }
                                        })
                                        }
                                    </ul>
                                </td>
                                <td>

                                    {/* <GoogleMapStatic/> */}
                                </td>
                                {/* <td>
                                <ul>
                                {data.features.map((item, index) => {
                                        return (<li key={index}>{item}</li>)
                                    })
                                    }
                                   
                                </ul>
                            </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {renderInformation(props)}
            {/* <GoogleMap
                addClass="ListingMap"
                width={googleWidth}
                markCoords={{
                    lat: this.state.data.Latitude,
                    lng: this.state.data.Longitude
                }}
                coord={{
                    lat: this.state.data.Latitude,
                    lng: this.state.data.Longitude
                }}
            //    lat ={this.state.Latitude}
            //    lng = {this.state.Longitude}
            /> */}

            {/* <GoogleMapStatic
                markCoords={{
                    lat: this.state.data.Latitude,
                    lng: this.state.data.Longitude
                }}
                coord={{
                    lat: this.state.data.Latitude,
                    lng: this.state.data.Longitude
                }}
            /> */}

            {/* {featuresItems} */}

        </div>
    )
}