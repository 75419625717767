import React,{useState,useEffect} from 'react';
import HOne from '../h-one';
import ConfigurationService from '../../services/configuration-service';

import './agent-card-style.css'
import { BreakfastDiningOutlined } from '@mui/icons-material';
export default function AgentCard(props) {
    // const contactWA = require('../../elements/images/contactWA.png');
    const contactWAlogo = require('../../elements/images/whatsapp_logo.png');
    const configurationService = new ConfigurationService();
    const lang = configurationService.getCurrentLanguagePrefix();

    const [WAMessageText,setWAMessageText] = useState('');
    const [emailText,setEmailText] = useState('');

    useEffect(()=>{
        let {listing,agent,selection = false} = props;
        let messageText = '',emailText='';
        console.log(props)
        let agent_first_name = agent.name.split(' ')[0];
        if(!selection){
            let address = listing.UnparsedAddress || listing.UnparseAddress;
            console.log(listing);
    
            switch(lang){
                case 'es':{
                    messageText = `¡Hola ${agent_first_name}! Agradecería me envíe más información sobre Icon ${listing.ListingId}`;
                    emailText=`?subject=${listing.ListingId} - ${address}&body=¡Hola ${agent_first_name}!%0D%0A Agradecería me envíe más información sobre Icon ${listing.ListingId}`;
    
                    break;
                }
                case 'pt':{
                    messageText = `Olá ${agent_first_name}! Favor enviar mais informações sobre o empreendimento ${listing.ListingId}`;
                    emailText=`?subject=${listing.ListingId} - ${address}&body=Olá ${agent_first_name}!%0D%0A Favor enviar mais informações sobre o empreendimento ${listing.ListingIdLS}`;
    
                    break;
                }
                default:{
                    messageText = `Hello ${agent_first_name}! Please send me more information about ${listing.ListingId}`;
                    emailText=`?subject=${listing.ListingId} - ${address}&body=Hello ${agent_first_name}!%0D%0A Please send me more information about ${listing.ListingId}`;
                }
            }
            messageText = messageText.replaceAll(' ','%20');
            emailText = emailText.replaceAll(' ','%20');
        }
        
        setWAMessageText(messageText);
        setEmailText( emailText);
    },[])

    let formatPhoneNumber = (str) => {
        let cleaned = ('' + str).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `+ ${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
        };
        return null
      };


    let { agent, notes, title = '', date, disableNote = false ,width,hugeProfile=false,listing,signature=false } = props;
    if (agent == null) {
        return null;
    }
    let lineWidth = '270'
    if(width<500){
        lineWidth = '140'
    }
    const iconInstagramWithoutElliplse = require('../../elements/icons/instagram_without_ellipse_icon.svg').default;
    const iconLogo = require('../../elements/images/logo_positive_center.svg').default;
    const iconWebsite = require('../../elements/icons/website_ico.png');
    const iconWA = require('../../elements/icons/whatsapp_ico.png');
    const iconInstagram = require('../../elements/icons/instagram_ico.png');


    return (
        <div className={disableNote?(`ListingAgentCard NotesAndAgent`):(`NotesAndAgent`) } >

            {!disableNote && 
            <div className="Notes">
                <HOne addclass={'Title'} title={title} align="left" />
                {notes}
                <div className="Date">
                    Selection prepared: {date}
                </div>
            </div>}

            <div className={"Agent"}>
                <div className='AgentTop' >
                    <div className={hugeProfile ?'BigProfile':'Photo'}>
                        <img className={"AgentImg"} alt='Agent' src={hugeProfile ? `https://ngirealty.com/img/profiles/${agent.profile_picture}`: `https://ngirealty.com/img/signature/${agent.profile_picture}`} />
                    </div>
                    <div className='Info'>
                        <div className='Name'>
                            {agent.name}<br />
                        </div>

                        {agent.title}<br />
                        <span className='HeadquarterLocation'>
                            Miami - Ft. Lauderdale
                        </span>
                        <HOne title='' line={'large'} lineWidth={lineWidth}></HOne>
                        <div className='TableBlock'>
                            <table>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td className='InfoIcon'>M</td>
                                        <td>{formatPhoneNumber(agent.phone)}</td>
                                    </tr>
                                    <tr>
                                        <td className='InfoIcon'>E</td>
                                        <td><a href={`mailto:${agent.email}${emailText}`}>{agent.email}</a></td>
                                    </tr>
                                    {agent.instagram && <tr>
                                       
                                        <td className='InfoIcon'><img className='InstagramIcon' src={iconInstagramWithoutElliplse} alt=""></img></td>
                                        <td><a href={`https://www.instagram.com/${agent.instagram}/`}>{agent.instagram}</a></td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>


                {signature &&  <div className='SMLXSignature'>
                    <img className='Logo' src={iconLogo} alt="Logo"/>
                    <a href={`https://api.whatsapp.com/send?phone=${agent.phone}&text=${WAMessageText}`} target='_blank'><img className='SocialIcon' src={iconWA} alt=""/></a>
                    <a href='/' target='_blank'><img className='SocialIcon' src={iconWebsite} alt=""/></a>
                    <a href={`https://www.instagram.com/${agent.instagram}/`} target='_blank'><img className='SocialIcon' src={iconInstagram} alt=""/></a>
                </div>
                }
              
              {!signature &&  <div className='AgentBottom'>
                    {/* <a target="_blank" className="ContactWABtn" rel="noreferrer"  href={`https://wa.me/${agent.phone}`}><div>Contact Via</div> <img alt='Contact WA' className="ContactWA" src={contactWAlogo} /></a> */}
                    <a target="_blank" className="ContactWABtn" rel="noreferrer"  href={`https://api.whatsapp.com/send?phone=${agent.phone}&text=${WAMessageText}`}><div>Contact Via</div> <img alt='Contact WA' className="ContactWA" src={contactWAlogo} /></a>
                </div>}

               

            </div>
        </div>

    )
}