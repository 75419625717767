import React, { useState, useEffect} from 'react'
import {
    BrowserRouter as Router,
    withRouter
} from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import CustomMarker from '../custom-marker';
import { DrawingManager } from '@react-google-maps/api';

var mapRef = React.createRef();
let markers = [];
let flag = false;
let firstRender = false;

const libraries = ['drawing']; //("drawing" | "geometry" | "localContext" | "places" | "visualization")

var drawingOptions = {

}

function ChangeZoom(event) {

    const googleScript = document.getElementById('google-map-script')
    if (window.google) {
        if (event.detail.zoom && event.detail.zoom < 16) {
            mapRef.setZoom(event.detail.zoom);
        }
    }

}

function GetSecondaryMarkers(props) {
    let { mapSecondaryMarkers, currentSelectionListing, hoveredMarkerId, selectionId, agentId, currentPage } = props;

    if (mapSecondaryMarkers != null) {
        return (
            mapSecondaryMarkers.map((item, index) => {
                if (item.latitude !== undefined && item.longitude !== undefined) {
                    let pos = {
                        lat: item.latitude,
                        lng: item.longitude
                    }
                    return (
                        <CustomMarker
                            currentSelectionListing={currentSelectionListing}
                            secondaryMarker={true}
                            hoveredMarkerId={hoveredMarkerId}
                            handleOnMarkerHover={props.handleOnMarkerHover}
                            data={item}
                            key={index}
                            position={pos}
                            agentId={agentId}
                            selectionId={selectionId}
                            currentPage={currentPage}
                        // similarOffers={similarOffers}
                        />)
                } else return null;
            })
        )

    }
    return null;
}

function GetSoldOutMarkers(props) {
    let { hoveredMarkerId, currentSelectionListing, selectionId, agentId, mapSoldOutMarkers, currentPage } = props;

    if (mapSoldOutMarkers != null) {
        return (
            mapSoldOutMarkers.map((item, index) => {
                if (item.latitude !== undefined && item.longitude !== undefined) {
                    let pos = {
                        lat: item.latitude,
                        lng: item.longitude
                    }
                    return (
                        <CustomMarker
                            currentSelectionListing={currentSelectionListing}
                            soldOutMarker={true}
                            hoveredMarkerId={hoveredMarkerId}
                            handleOnMarkerHover={props.handleOnMarkerHover}
                            data={item}
                            key={index}
                            position={pos}
                            agentId={agentId}
                            selectionId={selectionId}
                            currentPage={currentPage}
                        // similarOffers={similarOffers}
                        />)
                } else return null;
            })
        )

    }
    return null;
}

function GetMarkers(props) {
    let res;
    const googleScript = document.getElementById('google-map-script')
    let bounds;
    if (window.google) {
        // All is good! Carry on 
        bounds = new window.google.maps.LatLngBounds();
    }
    markers = [];
    let { data, selectionId, listingExpiredList, agentId, hoveredMarkerId, currentPage, currentSelectionListing } = props;
    if (data !== undefined && Array.isArray(data) && data.length > 0 && mapRef.current !== null && bounds !== undefined) {
        res = data.map((item, index) => {
            let flagExpired = false;

            if (listingExpiredList) {
                for (let i = 0; i < listingExpiredList.length; i++) {
                    if (item.id === listingExpiredList[i]) {
                        flagExpired = true;
                    }
                }
            }
            if (item.latitude !== undefined && item.longitude !== undefined) {
                let pos = {
                    lat: item.latitude,
                    lng: item.longitude
                }
                if (!flagExpired) {
                    return (
                        <CustomMarker
                            currentSelectionListing={currentSelectionListing}
                            hoveredMarkerId={hoveredMarkerId}
                            handleOnMarkerHover={props.handleOnMarkerHover}
                            data={item}
                            key={index}
                            position={pos}
                            agentId={agentId}
                            selectionId={selectionId}
                            currentPage={currentPage}
                        />)
                } else return null;
            } else return null;
        })
        if (!firstRender) {
            // console.log("bounds: ",bounds);
            mapRef.fitBounds(bounds);
            setTimeout(() => {
                if (mapRef.getZoom() > 13) {
                    mapRef.setZoom(13);
                }
            }, 600)
            firstRender = true;
        }
        // if (currentSelectionListing != null && currentSelectionListing.Longitude !== undefined) {//also for project page

        //     const userPos = new window.google.maps.LatLng(currentSelectionListing.Latitude, currentSelectionListing.Longitude);
        //     mapRef.setCenter(userPos)
        // }
    }

    return res;
}

function GetCenterOfCords(props) {
    const googleScript = document.getElementById('google-map-script')
    let bounds;
    if (window.google) {
        // All is good! Carry on 
        bounds = new window.google.maps.LatLngBounds();
    }
    markers = [];
    let { data, listingExpiredList} = props;
    if (mapRef.current !== null && bounds !== undefined) {
        if (data !== undefined && Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                markers.push({ lat: data[i].latitude, lng: data[i].longitude })
            }
         
            mapRef.fitBounds(bounds);
        }
        // else if (coord !== undefined && coord.lat) {
        //     markers.push({ lat: coord.lat, lng: coord.lng });
        //     bounds.extend(markers[0]);
        //     mapRef.fitBounds(bounds);
        //     setTimeout(() => {
        //         if (mapRef.getZoom() > 13) {
        //             mapRef.setZoom(13);
        //         }
        //     }, 500)
        // }

        // if (currentSelectionListing != null && currentSelectionListing.Longitude !== undefined) {//also for project page
        //     console.log(currentSelectionListing)
        //     const userPos = new window.google.maps.LatLng(currentSelectionListing.Latitude, currentSelectionListing.Longitude);
        //     mapRef.setCenter(userPos)
        // }
       

    }
}

// function GetDrawingManager(props) {

//     if (props.currentPage == 'SearchPage') {
//         return (
//         <DrawingManager
//             options={drawingOptions}

//         //   onOverlayComplete={this.handleOverlayComplete}
//         >

//         </DrawingManager>)
//     }
// }

// function createCenterControl() {
//     const controlButton = document.createElement("button");
  
//     // Set CSS for the control.
//     controlButton.style.backgroundColor = "#fff";
//     controlButton.style.border = "2px solid #fff";
//     controlButton.style.borderRadius = "3px";
//     controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
//     controlButton.style.color = "rgb(25,25,25)";
//     controlButton.style.cursor = "pointer";
//     controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
//     controlButton.style.fontSize = "16px";
//     controlButton.style.lineHeight = "38px";
//     controlButton.style.margin = "8px 0 22px";
//     controlButton.style.padding = "0 5px";
//     controlButton.style.textAlign = "center";
//     controlButton.textContent = "Search this area";
//     controlButton.title = "Search this ara";
//     controlButton.type = "button";
//     // Setup the click event listeners: simply set the map to Chicago.
//     controlButton.addEventListener("click", () => {
//         console.log(mapRef.getBounds())
//     });
//     return controlButton;
//   }


function GoogleMapComponentTest(props) {
    let { data } = props;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC46fA748XZu3TynqHM1lhQLRpGFKzrxdc",
        // libraries: libraries
    })

    const [map, setMap] = useState(null)
    // const [markerCoord, setMarkerCoord] = useState(null)
    // const [userActive, setUserActive] = useState(false)

    const [isVisibleButtonSearchArea,setIsVisibleButtonSearchArea] = useState(false);

    const onLoad = React.useCallback(function callback(map) {

        mapRef = map;

        // drawingOptions = {
        //     drawingControlOptions: {
        //         position: window.google.maps.ControlPosition.TOP_CENTER,
        //         drawingModes: [window.google.maps.drawing.OverlayType.POLYLINE,
        //         window.google.maps.drawing.OverlayType.POLYGON,
        //         window.google.maps.drawing.OverlayType.RECTANGLE,
        //         ]
        //     }
        // }
        let test = new window.google.maps.LatLngBounds();
        setMap(map)
        // map.setOptions({
        //     draggableCursor: "default",
        //     draggingCursor: "pointer"
        // });

        console.log('Map is loaded')
        // document.addEventListener('changeMapZoom', ChangeZoom)
        //GetCenterOfCords(props);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        // document.removeEventListener('changeMapZoom', ChangeZoom)
        setMap(null)
    }, [])

    const createButtonForSearch = ()=>{
        // const centerControlDiv = document.createElement("div");
        // const centerControl = createCenterControl(map);
        // centerControlDiv.appendChild(centerControl);
        // map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
    }

    // const onCenterChanged = ()=>{
    //     if (props.currentPage == 'SearchPage') {
    //     if(!isVisibleButtonSearchArea && userActive && map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].length == 0){
    //             setIsVisibleButtonSearchArea(true);
    //             createButtonForSearch();
    //         }
    //     }
        
       
    // }

    // const onZoomChanged = ()=>{
    //     if (props.currentPage == 'SearchPage') {
    //         if(!isVisibleButtonSearchArea && userActive && map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].length == 0){
    //             setIsVisibleButtonSearchArea(true);
    //             createButtonForSearch();
    //         }
    //     }
       
    // }

    // const onMouseMove = () =>{
    //     if(!userActive) setUserActive(true);
    // }

    let containerStyle = {
        width: '100%',
        height: '400px',
    };

    props.width !== undefined ? containerStyle.width = props.width : containerStyle.width = '100%';
    props.height !== undefined ? containerStyle.height = props.height : containerStyle.height = '400px';

    // let prevMarkers= usePrevious(props.data);    
    // console.log(prevMarkers);
    // console.log(props.data)

    useEffect(() => {
        if (props.data != null) GetCenterOfCords(props)
        setIsVisibleButtonSearchArea(false);
    }, [props.data]);

    useEffect(() => {
        console.log("listingExpiredList")
        GetCenterOfCords(props)
    }, [props.listingExpiredList]);

    useEffect(() => {
        console.log("currentSelectionListing")
        GetCenterOfCords(props)
    }, [props.currentSelectionListing]);

    useEffect(() => {
        GetCenterOfCords(props);
    }, [mapRef])

    useEffect(() => {
        console.log("screenWidth")
        GetCenterOfCords(props)
    }, [props.screenWidth]);

    if (props.data == null) {
        return null;
    } else {

    }

    return (isLoaded) ? (

        <GoogleMap
            // ref={mapRef}
            mapContainerStyle={containerStyle}
            //center={center?(center):(null)}   
            // mapContainerClassName={props.addClass}
            // onMouseMove={onMouseMove}
            onLoad={onLoad}
            // onCenterChanged={onCenterChanged}
            // onZoomChanged={onZoomChanged}
            onUnmount={onUnmount}
            options={{
                gestureHandling: 'greedy',
                styles:
                    [
                        {
                            "featureType": "all",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "all",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "lightness": "70"
                                },
                                {
                                    "saturation": "0"
                                },
                                {
                                    "gamma": "1.34"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ba9450"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#d2ab67"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2e6d2"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#c3d6e4"
                                }
                            ]
                        }
                    ]
            }}
        >
            {/* {GetDrawingManager(props)} */}
            {GetMarkers(props)}
            {GetSecondaryMarkers(props)}
            {GetSoldOutMarkers(props)}
            {/* {props.coord !== undefined && props.coord.lat !== undefined ? (<Marker
                icon={{
                    url: "https://ngirealty.com/resource/data/smlx_marker_a.png"

                }}
                position={props.coord}></Marker>) : (null)} */}
            { /* Child components, such as markers, info windows, etc. */}
        </GoogleMap>
    ) : <></>
}

export default withRouter(GoogleMapComponentTest);