import React, { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';

import ProjectSlider from '../project-slider';
import OffersGrid from '../offers-grid';
import ProjectOffersGrid from '../project-offers-grid';

import ServerApi from '../../services/server-api-service';
import CookiesManager from '../../services/cookies-manager';

import './selection-project-offer-page-style.css';
import HOne from '../h-one';
export default function SelectionProjectOfferPage(props) {
    let { saveState, saveSelectionListings, dictionary,width,saveDataForMap} = props;
    const params = useParams();
    const [agent,setAgent] = useState(null);
    const [offer,setOffer] = useState(null);
    const [selection,setSelection] = useState(null);
    const [listingExpiredList,setListingExpiredList] = useState([]);
    const serverApi = new ServerApi();
    const cookiesManager = new CookiesManager();

    function renderSimilars() {
        let similarOffers = [];
        console.log(selection.listings)
       if(selection){
            if (similarOffers) {
                for (let i = 0; i < selection.offers.length; i++) {
                    
                    if (selection.offers[i].id != params.offerId) {
                        similarOffers.push(selection.offers[i])
                    }
                }
            }
      
      
      
            return (
              <>
              <HOne key="GridTitle" title={`${dictionary.more_deals}`} />
              {selection.listings && selection.listings.length > 0 ? 
                <OffersGrid
                selectionId={params.selectionId}
                data={selection.listings}
                width={width}
                dictionary={dictionary}
                lastCard={false}
                agentId={params.agentId}
                selection={true}
                dealScore={false}
                listingExpiredList={listingExpiredList}
                />
              : null}
               
              {similarOffers&& similarOffers.length > 0 ?
                <ProjectOffersGrid
                  width={width} 
                  dictionary={dictionary}
                  data={similarOffers}
                /> : null
              }
              </>
             
            )
       }

       return null
    
      }


    function getSelection() {
        if (params.selectionId) {
            serverApi.getSelection(params.selectionId, params.agentId)
                .then((data) => {
                    // this.setState((prevState) => {
                    //   return {
                    //     similarOffers: data.listings,
                    //     selectionTitle: data.title,
                    //     agent: data.agent,
                    //     notes: data.notes,
                    //     date: data.date
                    //   }
                    // })

                    let projectsMarkers = data.offers.map((offer)=>{
                        offer.project.marker.offerId = offer.id;
                        return offer.project.marker;
                    })
                    let commonDataForMap = data.listings.concat(projectsMarkers);
                    saveSelectionListings(commonDataForMap, params.selectionId, params.agentId);
                    setAgent(data.agent)
                    setSelection(data)
                    let mapOfferProject = null;
                    data.offers.find((offer)=>{
                        
                        if(offer.id == params.offerId) 
                        {   
                            setOffer(offer);
                            mapOfferProject = offer.project;
                        }
                    })
                    let initialData = {
                        agent: data.agent,
                        currentPage:'ProjectOfferPage',
                        currentSelectionListing:mapOfferProject
                    }
                    saveState(initialData);
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getSelection ... -> Component: Seletion-Page ERROR:${error}`);
                })
        }
    }

    function replacer(matched) {
        let withProtocol = matched
        
        if(!withProtocol.startsWith("https")) {
          withProtocol = "https://" + matched
        }
       
        const newStr = `<a
          class="text-link"
          target="_blank"
          href="${withProtocol}"
        >
          ${matched}
        </a>`
        
        return newStr
      }


    useEffect(() => {
        getSelection();
        let initialData = {
            mapSide: 'left',
            selectionHeader: true,
            showSelectionToolBar: false,
            currentPage: 'SelectionOfferPage',
            isVisibleSliderModal: false,
        }
        if (cookiesManager.get(params.selectionId)) {
            let expiredList = JSON.parse(cookiesManager.get(params.selectionId))
            if (expiredList) {
                setListingExpiredList(expiredList);
            }
        }



        saveState(initialData);
    }, [])

    useEffect(()=>{
        if(props.widthRouterMark == 0 || props.widthRouterMark == 'auto'){
            let page = document.getElementsByClassName('SelectionProjectOfferPage')[0];
            if (page != null) {
              if (props.widthRouterMark !== page.clientWidth) {
                props.saveState({
                  widthRouterMark: page.clientWidth,
                  heightRouterMark: page.clientHeight
                })
              }
            }
        }
        
    })


    const contactWA = require('../../elements/images/contactWA.png');
    const linkRegex = /https?\:\/\/?(www\.)?[^\s]+\.[^\s]+/g
    if(!offer) return null;

    return (
        <div className="SelectionProjectOfferPage">
            <div className={"ListinPageHeaderLine"}>
                {/* <AgentCard
              title={selectionTitle}
              notes={notes}
              agent={agent}
              date={date}
              disableNote={true}
            /> */}

                {agent != null ? (
                    <div className="AgentLine">
                        <div className="AgentContact">
                            {agent.name}  <a href={`mailto:${agent.email}`}>{agent.email}</a>
                        </div>
                        <a target="_blank" href={`https://wa.me/${agent.phone}`}><img className="ContactWA" src={contactWA} /></a>
                    </div>
                ) : (null)}
                <div className="ListinPageStaticHeader">
                    <div className="Title">
                        <div className={"VertLine"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64.484" viewBox="0 0 2 64.484">
                                <defs>
                                    <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                        <stop offset="0" stopColor="#a96903" />
                                        <stop offset="0.258" stopColor="#aa6a02" />
                                        <stop offset="0.352" stopColor="#b07102" />
                                        <stop offset="0.418" stopColor="#b97d02" />
                                        <stop offset="0.472" stopColor="#c78e01" />
                                        <stop offset="0.518" stopColor="#daa401" />
                                        <stop offset="0.557" stopColor="#f0be00" />
                                        <stop offset="0.579" stopColor="#ffd100" />
                                        <stop offset="1" stopColor="#a96903" />
                                    </linearGradient>
                                </defs>
                                <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-272.933 -2822.173) rotate(90)">
                                    <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="64.484" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                </g>
                            </svg>
                        </div>
                        <div className="TitieAndDisc">
                            <h2>
                                {/* 18555 Collins Avenue, Unit 4803 */}
                                {offer.project.name}
                            </h2>
                            
                            <img  className="Logo" src={`https://ngirealty.com/img/projects/${offer.project.id}/${offer.project.logo}`} alt='logo'></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='PageBody '>
                <div className='slider'>
                    <ProjectSlider
                    projectData={offer.project}
                    data={offer.project.photos}
                    width={width}
                    dictionary={dictionary}
                    hideForm={true}
                    /> 
                </div>
                   <div className='OfferBody'>
                        <HOne tag='h1' title={offer.name} align={"left"} color="black" />
                        {HTMLReactParser(
                            (offer.body
                            .replaceAll('\n',"<br/>")
                            .replace(linkRegex, replacer))
                            .replaceAll('- ',"&mdash; ")
                            )}
                   </div>

                   <div>
                    {renderSimilars()}
                   </div>
            </div>  
        </div>
    )
}