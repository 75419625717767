import React from 'react';

import ProjectCard from '../project-card';
import './project-cards-grid-style.css';
class ProjectCardsGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debug: false
        }
    }

    renderCards() {
        if (this.props.cards) {
            let { cards, hoveredMarkerId, dictionary, searchForm, limit = 1000,shortTerm=true } = this.props;
            return (
                cards.map((card, index) => {
                    if (card.longitude !== undefined && card.latitude !== undefined && index < limit) {
                        return (
                            <ProjectCard
                                shortTerm ={shortTerm}
                                searchForm={searchForm}
                                dictionary={dictionary}
                                data={card}
                                hoveredMarkerId={hoveredMarkerId}
                            />
                        )
                    } else return null;
                })
            )
        }
    }

    renderLastCard() {
        let { cards, hoveredMarkerId, dictionary, searchForm, lastCard = false,randomNumberForLastCardImage,shortTerm = true } = this.props;
        return (
            <ProjectCard
                shortTerm={shortTerm}
                searchForm={searchForm}
                dictionary={dictionary}
                hoveredMarkerId={hoveredMarkerId}
                data={{}}
                lastCard={lastCard}
                randomNumberForLastCardImage={randomNumberForLastCardImage}
            />
        )
    }

    render() {
        let { cards, hoveredMarkerId, dictionary, searchForm, lastCard = false } = this.props;
        if (Array.isArray(cards)) {
            if (cards.length > 0) {
                return (
                    <div className={"GridMain"}>
                        <div className={"Grid"}>
                            {this.renderCards()}
                            {lastCard&&this.renderLastCard()}
                        </div>
                    </div>
                )
            } else {
                return <div className='NotFound'>
                    Not found. Please change search criteria
            </div>;
            }
        } else {
            return null;
        }

    };

}

export default ProjectCardsGrid;