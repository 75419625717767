import React, { useState, useEffect,useRef  } from "react";
import { useParams, useHistory } from "react-router-dom";

import ConfigurationService from "../../services/configuration-service";
import StringProcessingService from '../../services/string-processing-service';
import ServerApi from "../../services/server-api-service";


import Parser from 'html-react-parser';
import Loading from "../loading";
import { Helmet } from "react-helmet";
import SignaturePad from 'react-signature-pad-wrapper'
import './sign-agreement-style.css'

const configurationService = new ConfigurationService();
const smartLuxeLogo = require('../../elements/images/logo_negative_center.png')
const serverApi = new ServerApi();


function SignAgreement(props){
    let { saveState, height, width, dictionary } = props;

    const params = useParams();
    const history = useHistory();

    const [agreement, setAgreement] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDraw,setIsDraw] = useState(true);
    const [name,setName] = useState('');

    let signaturePadRef = useRef(null);
    

    useEffect(() => {
        if (saveState) {
            saveState({
                routerMarkStyle:{flex:1,minHeight:'auto'},
                isVisibleHeader: false,
                isVisibleFooter: false,
                isEnablePopUps:false
            })
        }
        if (params.agreementId) {
            serverApi.getAgreement(params.agreementId)
                .then((data) => {
                    if(data.status && data.status =='redirect') window.location.href = data.url;
                    else{
                        setAgreement(data);
                        setLoading(true);
                    }
                   
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getAgreement ... -> Component: SignAgreement ERROR:${error}`);
                    //history.replace({ pathname: `/page-not-found` });
                })
        } else {
            console.log(`ERROR: url params undefined`);
            history.replace({ pathname: `/page-not-found` });
        }

    }, [])


    useEffect(() => () => {
        if (saveState) {
            saveState({
                routerMarkStyle:{flex:'none',minHeight:900},
                isVisibleHeader: false,
                isVisibleFooter: false,
                isEnablePopUps:false,
            })
        }
    }, []);

    function clearSign () {
        signaturePadRef.current.clear();
    }

    function sign (){
            if (params.agreementId) {
                let sign = '';
                if(isDraw){
                    sign =`<img  style="border-bottom: 2px solid black; width:200px" src="${signaturePadRef.current.toDataURL()}"/>`;
                }else{
                    sign = name;
                }

                serverApi.signAgreement(
                    params.agreementId,
                    agreement
                        .replace('{__signature__}',sign)
                        .replaceAll('{__signature_date__}',new Date().toLocaleDateString('en-US',{ month: 'long',day:'numeric',year:'numeric' }))
                    )
                    .then((data) => {
                        window.location.replace(data)
                    })
                    .catch((error) => {
                        console.log(`ERROR: fetch getAgreement ... -> Component: SignAgreement ERROR:${error}`);
                        //history.replace({ pathname: `/page-not-found` });
                    })
            } else {
                // console.log(`ERROR: url params undefined`);
                // history.replace({ pathname: `/page-not-found` });
            }
        
    }

    return (
        <div>
         

            {loading ? (
                
                <div className="sign-agreement-page">
                       <Helmet>
                        <title> Sign Agreement | Smart Luxe</title>
                    </Helmet>
                    <div className="title">
                        <p>Sign Agreement | Smart Luxe</p>
                    </div>

                    <div className="agreement">
                        {Parser(agreement)}
                        <div className="actions">
                            <br/>
                            <br/>

                            <div className="sign_block">
                                <div className="tabs">
                                    <div className="nav_tab" id={!isDraw &&'nav_tab_active'} onClick={()=>{setIsDraw(false)}}>
                                        Type
                                    </div>
                                    <div className="nav_tab" id={isDraw &&'nav_tab_active'} onClick={()=>{setIsDraw(true)}}>
                                        Draw
                                    </div>
                                </div>
                                <div className="sign">
                                    {isDraw&& <SignaturePad ref={signaturePadRef}  width={350} height={150} options={{minWidth: 1, maxWidth: 2, penColor: 'rgb(0,0,0)'}} />}
                                    {!isDraw&& <input className="Input" value = {name} onChange={(e)=>{setName(e.target.value)}} type=''/>}
                                    <div className="buttons">
                                        <button className="Button button_cancel" onClick={clearSign}>
                                            Clear

                                        </button>
                                        <button className="Button button_sign" onClick={sign}>
                                            Sign
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   

                    <div className="presented">
                        Presented by
                        <img className="logo" src={smartLuxeLogo} alt='logo' />
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </div>
    )
}
export default SignAgreement;