import React from "react";
import {
    BrowserRouter as Router,
    Link,
    withRouter,
    useParams
} from "react-router-dom";

import ListingFeedback from '../listing-feedback/listing-feedback';

import './project-offer-style.css';

function MobileLink(props) {
    let { width, children, params, offer } = props;
    if (width < 1000) return (
        <Link to={`/selection/${params.selectionId}/${params.agentId != null ? params.agentId + '/' : ''}offer/${offer.id}`}>
            {children}
        </Link>
    )
    else return <>{children}</>;
}

function ProjectOffer(props) {
    let { offer, dictionary, selectionId, agentId, width, hoveredMarkerId, saveState, vertical = false,selectionData } = props;
    const locationIcon = require('../../elements/icons/s_location_offer.png');
    const params = useParams();
    if (vertical && width > 1000) {
       return (
        <div className={`ProjectOffer`} id={hoveredMarkerId == offer.project.id ? 'SelectedProjectOffer' : ''}
        onMouseEnter={() => { if (saveState) saveState({ hoveredMarkerId: offer.project.id }) }}
        onMouseLeave={() => { if (saveState) saveState({ hoveredMarkerId: null }) }}
    >
        <MobileLink
            params={params}
            width={width}
            offer={offer}
        >
            <div className={`Logo`}>
                <Link to={`/selection/${params.selectionId}/${params.agentId != null ? params.agentId + '/' : ''}offer/${offer.id}`}>
                    <img src={`https://ngirealty.com/storage/projects/${offer.project.picture}`} alt="Company Logo" />
                </Link>
            </div>

            <div className={`RightBlock`}>
                <div className={`TopPart`}>
                    <div className={`Title`}>
                        {offer.name}
                    </div>
                    {selectionData && selectionData.allow_feedback ?  <div className='Feedback'><ListingFeedback type={'offer'} overlay={false} data={offer} selectionId = {params.selectionId}/> </div>: null}

                        {width > 1000 && <Link to={`/selection/${params.selectionId}/${params.agentId != null ? params.agentId + '/' : ''}offer/${offer.id}`}>
                            <button className="Button" >{dictionary.view_details}</button>
                        </Link>}

                </div>

                <div className="ProjectOfferTitle">
                    {offer.project.slogan}
                </div>
            </div>
        </MobileLink>
    </div>
       )
    }

    return (

        <Link to={`/selection/${params.selectionId}/${params.agentId != null ? params.agentId + '/' : ''}offer/${offer.id}`}
            className="OfferParent ProjectCard" id={hoveredMarkerId == offer.project.id ? 'SelectedProjectOffer' : ''}

            onMouseEnter={() => { if (saveState) saveState({ hoveredMarkerId: offer.project.id }) }}
            onMouseLeave={() => { if (saveState) saveState({ hoveredMarkerId: null }) }}
        >
            <div className="OfferMain">
                <div className={"TopBlockOffer"}>

                    <div className="OfferTitle">
                        <div className="OfferLocation"><img src={locationIcon} alt="" />{offer.project.web_location}</div>
                    </div>
                    <img className="OfferImg" src={`https://ngirealty.com/storage/projects/${offer.project.picture}`} alt="" />

                    <div className="OfferImgSubstrate"></div>
                    {selectionData &&   selectionData.allow_feedback && width<1000 ? (
                                    <ListingFeedback type={'offer'} overlay={true} data={offer} selectionId={params.selectionId}/>
                            ) : null}
                </div>
                <div className={"BottomBlockOfferProject"}>
                    <h2 className={"ProjectName"}>{offer.project.name}</h2>
                    <div className={"Separator"}></div>
                    <div>
                        {offer.name}
                    </div>
                </div>
            </div>

        </Link>
    )
}

export default ProjectOffer;