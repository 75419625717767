import React from 'react';
import GoogleMap from '../google-map';
import OffersGrid from '../offers-grid';
import Select from 'react-select';
import Helmet from 'react-helmet';

import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import StringProcessingService from '../../services/string-processing-service';
import MarkerCustomInfoWindow from '../marker-custom-info-window/marker-custom-info-window';
import SeoService from '../../services/seo-service';
import './search-page-style.css';
class SearchPage extends React.Component {
    serverApi = new ServerApi();
    formCheckService = new FormCheckService();
    stringProcessingService = new StringProcessingService()
    seoService = new SeoService();
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchForm: {
                location: 0,
                bedrooms: 0,
                bathrooms: 0,
                price_min: 0,
                price_max: 0
            },
            hoveredMarkerId: 0,
            searchInputForm: {
                location: 0,
                bedrooms: 0,
                bathrooms: 0,
                price_min: '',
                price_max: ''
            },
            searchRooms: [
                { value: 0, label: props.dictionary.any },
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
            ],
            totalItems: 0,
            itemOnPage: 7,
            currentPage: 1,
            totalPages: 0
        }
        this.handleInput = this.handleInput.bind(this);
        this.searchSubmit = this.searchSubmit.bind(this);
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'SearchPage' })
        // this.serverApi.getFeaturedDeals()
        //     .then((data) => {
        //         if (this.state.debug) {
        //             console.log(data);
        //         }
        //         this.setState({
        //             data: data
        //             //total data.total to get count of offers
        //         })

        //     })
        //     .catch((error) => {
        //         console.log(`ERROR: fetch sendForm ... -> Component: Wizard-Page ERROR:${error}`);
        //     })
        this.search(1, null);
        this.getAreas();
    }

    handleChange = (typeName, selectedOption) => {
        console.log(selectedOption, typeName)
        this.setState({
            searchInputForm: {
                ...this.state.searchInputForm,
                [typeName]: selectedOption.value
            }
        });
    }

    handleInput(event) {
        let value = event.target.value.replace(/\,/g, '');
        let parsedValue = this.stringProcessingService.convertToFormat(value);

        this.setState({
            searchForm: {
                ...this.state.searchForm,
                [event.target.name]: parsedValue
            },
            searchInputForm: {
                ...this.state.searchInputForm,
                [event.target.name]: value
            }
        })
    }

    getAreas = () => {
        let { dictionary } = this.props;
        this.serverApi.getAreasForSearch()
            .then((data) => {
                if (this.state.debug) {
                    console.log(data);
                }
                let options = [{ value: 0, label: dictionary.any }];
                data.map((item, index) => {
                    options.push({ value: item.id, label: item.name })
                })

                this.setState({
                    areas: options
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getAreas ... -> Component: search-Page ERROR:${error}`);
            })
    }

    search(page, dataSearch) {
        this.serverApi.search(this.state.itemOnPage, page, dataSearch)
            .then((data) => {
                if (this.state.debug) {
                    console.log(data);
                }
                let totalPages = data.total / this.state.itemOnPage;
                if (data.total % this.state.itemOnPage > 0) {
                    totalPages = data.total / this.state.itemOnPage + 1;
                }

                this.setState({
                    data: data.listings,
                    totalItems: data.total,
                    totalPages: parseInt(totalPages),
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch search ... -> Component: search-Page ERROR:${error}`);
            })
    }

    searchSubmit() {
        if (!this.formCheckService.checkSearchForm(this.state.searchInputForm)) {
            this.search(1, this.state.searchInputForm)
            this.setState({
                currentPage: 1
            })
        }
    }
    switchToPage = (page) => {

        this.setState({
            currentPage: page,
            data: undefined
        })


        let { location, bedrooms, bathrooms, price_max, price_min } = this.state.searchInputForm;
        if (location !== 0 || bedrooms !== 0 || bathrooms !== 0 || price_max !== 0 || price_min !== 0) {
            this.search(page, this.state.searchInputForm)
        } else {
            this.search(page)

        }
    }

    handleOnMarkerHover = (item) => {
        let { dictionary } = this.props;
        return (
            <MarkerCustomInfoWindow
                item={item}
                dictionary={dictionary}
                type={"Listings"}
            />)
    }

    renderPagination() {
        // let { dictionary } = this.props;
        let { totalPages, currentPage } = this.state;
        if (this.state.totalPages !== undefined) {
            const result = [];
            for (let i = 1; i < totalPages; i++) {
                if (totalPages > 10) {
                    // if (currentPage < 4) {
                    //     if (i < 4) {
                    //         result.push(<div className="PaginationItem" id={i == currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                    //     } else if (i > totalPages - 4) {
                    //         result.push(<div className="PaginationItem" id={i == currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                    //     }
                    // }else if(currentPage > totalPages - 4){
                    //     if (i < 4) {
                    //         result.push(<div className="PaginationItem" id={i == currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                    //     } else if (i > totalPages - 4) {
                    //         result.push(<div className="PaginationItem" id={i == currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                    //     }
                    // }else if(currentPage){

                    // }

                    if (currentPage < 3) {
                        if (i < 4) {
                            result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if (i === 3) {
                                result.push(<div id={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else if (currentPage > totalPages - 3) {
                        if (i < 4) {
                            result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if (i === 3) {
                                result.push(<div key={`PaginationItem${i}`} id={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else {
                        if (i > currentPage - 4 && i < currentPage + 4) {
                            result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    }

                } else {
                    result.push(<div key={`PaginationItem${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                }
            }
            return (<div className="PaginationDiv">{result}</div>)
        }
    }

    render() {
        let { dictionary, width, hoveredMarkerId, saveState } = this.props;
        let { data, areas, searchRooms, searchForm } = this.state;

        let parsedDesc = '';
        parsedDesc = dictionary.wizard_desc.replaceAll('[wizard_area_location]', dictionary.wizard_default_location);

        return (
            <div className="Page SearchPage MenuSpace">

                <Helmet>
                    <title> {dictionary.search} | Smart Luxe</title>
                    <meta name="description" content={parsedDesc} />
                    {this.seoService.insertHrefLangTags(
                        {
                            path: `/search`,
                        })
                    }
                </Helmet>

                <div className={"SearchPageHeaderLine"}>
                    <div className="SearchPageStaticHeader">
                        <div className="SearchItemLocationBedroomsBathrooms">
                            <div className="SearchHeaderItem SearchItemLocation">
                                <label className="SearchLabel">{dictionary.location}</label>
                                {/* <select onChange={this.handleInput} className="Input" id="SearchInput" size="1" name="location" onClick={() => { alert(1) }} value={this.state.searchForm.location}>
                                    <option selected>Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select> */}
                                <Select
                                    defaultValue={{ value: 0, label: dictionary.any }}
                                    className="SearchInputRegion"
                                    id="SearchInput"
                                    options={this.state.areas}
                                    name="location"
                                    onChange={this.handleChange.bind(this, 'location')}
                                />
                            </div>
                            <div className="ItemBedroomsBathrooms">
                                <div className="SearchHeaderItem">
                                    <label className="SearchLabel"><div>{dictionary.bedrooms}</div></label>

                                    <Select
                                        defaultValue={{ value: 0, label: dictionary.any }}
                                        id="SearchInput"
                                        options={searchRooms}
                                        name="bedrooms"
                                        onChange={this.handleChange.bind(this, 'bedrooms')}
                                    />

                                    {/* <select onChange={this.handleInput} className="SearchInput" id="SearchInput" size="1" name="bedrooms" value={this.state.searchForm.bedrooms}>
                                        <option defaultValue value='0'>{dictionary.any}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select> */}
                                </div>
                                <div className="SearchHeaderItem">
                                    <label className="SearchLabel">{dictionary.bathrooms}</label>

                                    <Select
                                        defaultValue={{ value: 0, label: dictionary.any }}
                                        id="SearchInput"
                                        options={searchRooms}
                                        name="bathrooms"
                                        onChange={this.handleChange.bind(this, 'bathrooms')}
                                    />

                                    {/* <select onChange={this.handleInput} className="SearchInput" id="SearchInput" size="1" name="bathrooms" value={this.state.searchForm.bathrooms}>
                                        <option selected value='0'>{dictionary.any}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="SearchHeaderItemPrice">
                            <label className="SearchLabel">{dictionary.filter_price_range}</label>
                            <div className="Inputs">
                                <div className="InputsItem">
                                    <label className="SearchLabel SearchLabelFrom">{dictionary.from}</label>
                                    <div className="InputWsDol">
                                        <div className="SearchInputDollar">$</div>
                                        <input className="SearchInput" onChange={this.handleInput} id="SearchInput" placeholder="0" name="price_min" value={this.stringProcessingService.convertToFormat(this.state.searchInputForm.price_min)} />
                                    </div>
                                </div>
                                <div className="InputsItem InputsItemSec">
                                    <label className="SearchLabel SearchLabelTo">{dictionary.to}</label>
                                    <div className="InputWsDol">
                                        <div className="SearchInputDollar">$</div>
                                        <input className="SearchInput" onChange={this.handleInput} id="SearchInput" placeholder="0" name="price_max" value={this.stringProcessingService.convertToFormat(this.state.searchInputForm.price_max)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="SearchHeaderButton">
                            <input type="submit" value="SEARCH" className="Button" id="SearchButton" onClick={this.searchSubmit} />
                        </div>

                    </div>
                </div>
                <div className="PageSearchLine">
                    <div className="SearchBlock">
                        <div className="TopPart">
                            <div className="MainMap">
                                <GoogleMap
                                    width="100%"
                                    height="100%"
                                    data={data}
                                    dataCenter={true}
                                    hoveredMarkerId={hoveredMarkerId}
                                    dataMarkers={true}
                                    currentPage={'SearchPage'}
                                    dictionary={dictionary}
                                    handleOnMarkerHover={this.handleOnMarkerHover}
                                />
                            </div>
                            <div className="Offers">
                                <OffersGrid
                                    title={dictionary.featured_deals}
                                    data={this.state.data}
                                    width={width}
                                    dictionary={dictionary}
                                    hoveredMarkerId={hoveredMarkerId}
                                    verticalList={true}
                                    saveState={saveState}
                                    limit={7}
                                />
                            </div>
                        </div>
                        <div className="BottomPart">
                            {/* pagination */}
                            {this.renderPagination()}
                        </div>
                    </div>
                </div>

            </div>
        )
    };

}

export default SearchPage;