
export default class ApplicationHistoryService{
    history = [];

    push(value){
        if(value !=null&&value.pathname!=null){
           this.history.push(value);
        }
    }

    pop(){
        if(this.history.length>1){this.history.pop()}
        return this.history.pop();
    }

    getPrevPathname(){
        if(this.history.length>0){
            return (this.history[this.history.length-1].pathname);
        }
        return null;
    }

    getLength(){
        return this.history.length;
    }

    removeByKey(key){
        if(this.history.length>0){
            let arr = [];
            for(let i = 0;i<this.history.length;i++){
               
                if(this.history[i].key!==key) arr.push(this.history[i]);
            }
            this.history=arr;
            // this.history = this.history.map((location)=>{
            //     console.log(location)
            //     if(location.key!==key) return location;
            // })
        }
    }
}