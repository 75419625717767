import React from "react";
import StringProcessingService from "./string-processing-service";

const langs = {
    en:{
        lang:'en',
        contries:['GB','US']
    },
    es: {
        lang:'es',
        contries:['AR','BO','CL','CO','CR','CU','DO','EC','ES','GI','GQ','GT','HN','LA','MX','NI','PA','PE','PR','PY','SV','US','UY','VE']
    },
    pt:{
        lang:'pt',
        contries:['BR','PT']
    }
};
const stringProcessingService = new StringProcessingService();



class SeoService {


    getCanonicalLink(originUrl) {

    }

    insertHrefLangTags(props) {
        let {path='/',id = null,parsedNameForUrl = '',currentLang = null,translations = {en:{},es:{},pt:{}},params = null} = props;
        let result =[];
        for(let item in langs){
            let url = path
            if(currentLang&&currentLang == item){
                if(params){
                    for(let param in params){
                        url = url.replace(':'+param,params[param])
                    }
                }
            }else 
            if((translations&&translations.hasOwnProperty(item)) ){
                if(params){
                    
                    for(let param in params){
                        if(param == 'id' && path.includes('/articles/:id/:name')){
                            url = url.replace(':id',translations[item].id) 
                        }else{
                            url = url.replace(':'+param,params[param])

                        }
                    }
                }

                // let idUrl=id
                // let name =parsedNameForUrl
                // if(translations[item]&&currentLang != item){
                //     idUrl = translations[item].id;
                //     name =stringProcessingService.StringFormatUrl(translations[item].title);
                // }
                // url = path.replace(':id',idUrl)
                //               .replace(':name',parsedNameForUrl)
                
            }
            langs[item].contries.map((lang)=>{
                result.push( <link rel="alternate" href={`https://${langs[item].lang!='en'?langs[item].lang+'.':''}smartluxe.com${url}`} hreflang={`${langs[item].lang}-${lang}`} />)
            })
        }
        return (result.map((link) => link))
    }
}

export default SeoService;