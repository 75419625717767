import React from 'react';
import UserCard from '../user-card';

import './user-cards-style.css';
class UserCards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShortEmail: false

        }
    }
    setIsShortEmail = (value) => {
        this.setState((prevState) => {
            if (prevState.isShortEmail !== value)
                return {
                    isShortEmail: value
                }
        })
    }
    renderItems() {
        let { dictionary } = this.props;
        let { isShortEmail } = this.state;
        if (this.props.data !== undefined) {
            let data = this.props.data;
            return (data.map((item, index) => {
                if (data.length === index + 1) {
                    return (
                        <>
                            <UserCard
                                data={item}
                                teamCard={this.props.teamCard}
                                dictionary={dictionary}
                                setIsShortEmail={this.setIsShortEmail}
                                isShortEmail={isShortEmail}
                            />
                            <UserCard isLast={true} teamCard={this.props.teamCard} dictionary={dictionary} handleModal={this.props.handleModal} />
                        </>
                    )
                } else {
                    return (
                        <UserCard
                            data={item}
                            dictionary={dictionary}
                            teamCard={this.props.teamCard}
                            setIsShortEmail={this.setIsShortEmail}
                            isShortEmail={isShortEmail}
                        />
                    )
                }

            }))
        }

    }


    render() {
        let { dictionary } = this.props;
        let { isShortEmail } = this.state;
        return (
            <div className="UserCardsList">
                <div className={"UserCardButton"}><button className="Button" onClick={this.props.handleModal}>{dictionary.join_the_team}</button></div>
                {this.renderItems()}
            </div>
        )
    };
}

export default UserCards;