import './description-style.css';
import HOne from '../h-one';

import './description-style.css';
export default function Description(props){
    let { dictionary ,analyseInfo,data,customDescription} = props;
    if (analyseInfo !== undefined) {
        let { rank } = analyseInfo;

        return (
            <div className="DealBlockInfo InfoBlock">
                <div className={"SectionHdr"}>
                    <HOne title={rank === 3 || rank === 4 ? dictionary.deal_description : dictionary.property_description} align={"left"} addclass={"xsmall"} />
                </div>
                <div className="DealBlockInfoBody">
                    <div className="TextBlock">
                        {customDescription != null ? customDescription : (data.PublicRemarks)}
                    </div>
                    {/* <div className="TextBlock">
                        {this.state.data.PublicRemarks}
                 </div> */}
                </div>
            </div>
        )
    }
    return null;
}