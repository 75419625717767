import React from 'react';
import Slider from 'react-slick';
import ListingPageModal from '../listing-page-modal';
import SoldIcon from '../sold-icon';
import Modal from '../modal';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

import './stand-alone-slider-style.css';

import { SampleNextArrow, SamplePrevArrow } from '../../functions/SliderArrow/SliderArrow.js';

const arrow_play = require('./../../elements/icons/vr_play.png')

class StandAloneSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            currentSlide: 0,
            currentImgId: 0,
            isVisibleCaruselModal: false,
        };
    }

    renderCounter() {
        let { currentImgId } = this.state;
        let { data ,listingGallery} = this.props;

        return (
            <div className="Counter">
                {currentImgId + 1} of {data.Media.length}
            </div>
        )
    }

    openCaruselModal = () => {
        if (this.props.selection) {
            document.dispatchEvent(new CustomEvent('openModal', { detail: { name: 'selectionListingModal', value: true, currentSlide: this.state.currentSlide } }))
        }
        else
            if (this.props.width > 800) {
                this.setState({
                    isVisibleCaruselModal: true
                })
                if (this.props.setSliderModal) this.props.setSliderModal(true)
            }

    }

  
    closeModal = () => {
        this.setState({
            isVisibleCaruselModal: false,
            isVisibleVurtual:false
        })
        if (this.props.setSliderModal) this.props.setSliderModal(false)
    }

    // handleSwitchMedia(newImage) {
    //     this.setState({
    //         currentImgId: newImage.Order,
    //         currentSlide: newImage.Order,
    //     })
    // }

    componentDidMount() {
        console.log('ALERT', this.props)
        let { data } = this.props;
        

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

  

    renderModal() {
        let { data, analyseInfo, dictionary, width, height, dealScore = true, userForm, selection = false, country } = this.props;
        return (

            <ListingPageModal
                isVisible={this.state.isVisibleCaruselModal}
                data={data}
                currentSlide={this.state.currentSlide}
                analyseInfo={analyseInfo}
                dictionary={dictionary}
                closeModal={this.closeModal}
                width={width}
                height={height}
                country={country}
                userForm={userForm}
                dealScore={dealScore}
                selection={selection}
            />
        )
    }

    render() {

        let { data, width,listingGallery = false} = this.props;
        let {isVisibleCaruselModal,currentSlide} = this.state;

        if (this.props.data === undefined) {
            return null;
        }

        


        let SliderItems = this.props.data.Media.map((item, index) => {
            let imgSrc = '';
            if(listingGallery) imgSrc = item.MediaUrl;
            else imgSrc = item.big
            return (
                <div key={index}>
                    <div className="StandAloneTopSliderItem" onClick={this.openCaruselModal}>
                        <img src={imgSrc} className="StandAloneTopSliderImg" alt="" ></img>
                    </div>
                </div>
            )

         
        })

        let SliderItemsBottom = this.props.data.Media.map((item, index) => {
            let imgSrc = '';
            if(listingGallery) imgSrc = item.MediaUrl;
            else imgSrc = item.thumb

            if (this.state.currentImgId === index) {
                return (
                    <div key={index}>
                        {/* <div className="StandAloneMediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}> */}
                        <div className="StandAloneMediaSwitchItem" >
                            <img src={imgSrc} className="StandAloneMediaImgSmall" alt="" ></img>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                         {/* <div className="StandAloneMediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}> */}
                        <div className="StandAloneMediaSwitchItem" >
                            <img src={imgSrc} className="StandAloneMediaImgSmall" alt="" ></img>
                            <div className="Other"></div>
                        </div>
                    </div>
                )
            }
        })


        
        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,

            responsive:[
                {
                    breakpoint: 500,
                    settings: {
                        arrows:true
                    }
                }
               ]
        };
        let minSlidersValue = 12;
        if (SliderItemsBottom.length < minSlidersValue) {
            minSlidersValue = SliderItemsBottom.length
        }
        const settingsNavSlider = {
            slidesToShow: minSlidersValue,//5
            slidesToShow: 11,
            centerPadding: "70px",
            
            responsive: [
                {
                    breakpoint: 2750,
                    settings: {
                        slidesToShow: 11,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 2550,
                    settings: {
                        slidesToShow: 11,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 2350,
                    settings: {
                        slidesToShow: 10,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 2150,
                    settings: {
                        slidesToShow: 9,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1950,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1550,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1350,
                    settings: {
                        slidesToShow:5, //5
                        slidesToScroll: 1
                    }
                }
               
            ],
        };

        const settingsModalSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            slidesToShow: 1,//5
            slidesToScroll: 1,
            // adaptiveHeight: true,
           
        };

        return (
            <>
                <div className='StandAloneSlider'>
                    <div className="StandAloneSliderTop StandAloneSliderTopKo">
                        <Slider {...settingsSlider}
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            adaptiveHeight={false}
                            variableWidth={false}
                            centerMode={false}
                            focusOnSelect={true}
                            afterChange={(index) => {
                                this.setState({
                                    currentSlide: index,
                                    currentImgId: index
                                })
                            }}

                        >
                            {SliderItems}
                        </Slider>
                        {this.renderCounter()}
                    </div>
                    <div className="StandAloneSliderNavi">
                        <Slider  {...settingsNavSlider}
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            swipeToSlide={true}
                            arrows={false}
                            centerMode={true}
                            focusOnSelect={true}
                        >
                            {SliderItemsBottom}
                        </Slider>
                    </div>
                </div>
              
                <Modal
                    ModalStyle={{height:'98%',width:'98%',alignItems:'center',backgroundColor:'rgba(1,1,1,0.3)',flexDirection:'column',display:"block"}}
                 isVisible={isVisibleCaruselModal}
                 closeModal={this.closeModal}
                 closeColor={'#ffff'}
                 >
                    <div className='FullScreenModalSlider'>
                            <Slider  {...settingsModalSlider}
                                arrows={true}
                                centerMode={false}
                                initialSlide = {currentSlide}
                            >
                                {SliderItems}
                            </Slider>
                    </div>
                </Modal>
            </>
        );
    }
}

export default StandAloneSlider;