import React from 'react';
import Slider from 'react-slick';
import ProjectSliderModal from '../project-slider-modal';
import Modal from '../modal';


import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';


import './project-slider-style.css';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const nextIcon = require('../../elements/icons/next_icon.png');
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={nextIcon} alt="Next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const prevIcon = require('../../elements/icons/prev_icon.png');
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src={prevIcon} alt="Prev" />
        </div>
    );
}

class ProjectSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            isVisibleModal: false,
            currentSlide: 1
        }
    }

    handleClick = (id) => {
        this.setState((prevState) => {
            return {
                isVisibleModal: !prevState.isVisible,
                currentSlide: id
            }
        })
    }

    closeModal = () => {
        this.setState({
            isVisibleModal: false
        })
    }



    render() {

        let { data, dictionary, country, width, projectData,hideForm = false } = this.props;
        let { isVisibleModal, currentSlide } = this.state;
        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            arrows: true,
            variableWidth: true,

            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 1, //4
                        arrows: true,
                        variableWidth: false,
                        adaptiveHeight: true,
                        centerMode: false,
                    }
                }
            ],
        };
        return (
            <div className={"ProjectSlider"}>
                <Slider {...settingsSlider}
                    focusOnSelect={false}
                    variableWidth={true}
                    className='ProjectSliderPage'

                >
                    {data ? (
                        data.map((image, index) => {
                          let slideWidth = image.size.width/(image.size.height/400)
                          if(width<1000) slideWidth ='auto'
                            return (
                                <div key={index} onClick={this.handleClick.bind(this, parseInt(image.order))}  >
                                    <div style={{width:slideWidth}} className="ProjectSliderItem">
                                        <img src={`https://ngirealty.com/img/projects/${image.project_id}/thumb/${image.thumb}`} className="ProjectSliderImg" alt="" ></img>
                                    </div>
                                </div>
                            )
                        })
                    ) : (null)}
                </Slider>
                {width > 100 ? (
                    <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        ModalStyle={{ height: "100%", boxShadow: 'none', backgroundColor: 'unset', width: "100%", justifyContent: 'center', flexDirection: 'column' }}
                        closeModal={this.closeModal}
                        closeColor={'#fff'}
                        isVisible={isVisibleModal}
                    >
                        <ProjectSliderModal
                            projectData={projectData}
                            data={data}
                            width={width}
                            dictionary={dictionary}
                            closeModal={this.closeModal}
                            currentSlide={currentSlide}
                            country={country}
                            hideForm={hideForm}
                        />

                    </Modal>
                ) : null}
            </div>
        )
    };

}

export default ProjectSlider;