import React, { useState } from 'react';
import Modal from '../modal';
import parser from 'html-react-parser';

import './info-modal-style.css';

function InfoModal(props) {
    const info = require('../../elements/images/i.svg').default;
    const infoDark = require('../../elements/images/i_dark.svg').default;

    const [isVisible, setIsVisible] = useState(false);
    
    let {dictionary,starIcon = false} = props;
    
    const closeModal =function (){
        setIsVisible(false);
    }

    return (
        <>  
            {starIcon?(
                <sup className='supIcon'
                 onClick={() => {
                    setIsVisible(!isVisible);
                }}

                onMouseEnter={() => {
                    setIsVisible(!isVisible);
                }}
                >*</sup>
            ):(
                <img className={"infoIcon"} src={info}
                onMouseOver={e => {
                    e.target.src = infoDark;
                }}

                onMouseOut={e => {
                    e.target.src = info;
                }}

                onClick={() => {
                    setIsVisible(!isVisible);
                }}

                
                />
                
            )}
            
            <Modal
                ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                modalClass={'MessageModal'}
                closeColor={'#000'}
                speed={0.5}
                ModalStyle={{ justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                isVisible={isVisible}
                closeModal={closeModal}
            >
                <div className='MessageCustomTitle'>{parser(dictionary.prices_change)}</div>
                {props.children}
            </Modal>
        </>
    )
}

export default InfoModal;