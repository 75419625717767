import React from 'react';

import Helmet from 'react-helmet';

import ProjectCardsGrid from '../project-cards-grid';
import Pagination from '../pagination/pagination';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import StringProcessingService from '../../services/string-processing-service';
import { motion } from 'framer-motion';
import SeoService from '../../services/seo-service';

import {
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";



import './construction-lobby-page-style.css';


const ITEM_PER_PAGE = 12;

const pageTransition = {
    in: {
        opacity: 1,
        transition: { duration: 0.5 }
    },
    out: {
        opacity: 0,
        transition: { duration: 0.5 }
    }
}
// const data = require('./testInfo.json');
class ConstructionLobbyPage extends React.Component{
    stringProcessingService = new StringProcessingService();
    serverApi = new ServerApi();
    formCheckService = new FormCheckService();
    seoService = new SeoService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            // searchForm: {
            //     location: 0,
            //     condo: false,
            //     townhouse: false,
            //     single_family_home: false,
            // },
            searchInputForm: {
                location: 0,
            },
            totalItems: 0,
            itemOnPage: 7,
            currentPage: 1,
            totalPages: 0
        }
    }

    getProjectsList = () => {
        let id = '';

        let { form } = this.props;

        
        if (this.props.match.params.id) id = this.props.match.params.id
        this.serverApi.getProjectsList(id, form)
            .then((data) => {
                this.setState({
                    data: data.data,
                })
                if(Array.isArray(data.data)&&data.data.length>0){
                    // let totalPages = data.data.length % ITEM_PER_PAGE == 0 ? (parseInt(data.data.length / ITEM_PER_PAGE)) : (parseInt(data.data.length / ITEM_PER_PAGE) + 1)
                    // let pageData = data.data.slice(0,data.data.length > ITEM_PER_PAGE ? ITEM_PER_PAGE : data.data.length);

                    // this.props.saveDataForMap(pageData);
                    // this.setState({
                    //     totalPages:totalPages,
                    //     pageData: pageData,
                    //     currentPage:1
                    // })

                    this.setState({
                        pageData: data.data,
                    })

                    this.props.saveDataForMap(data.data)
                }
                else {
                    this.setState({
                        pageData:[],
                        totalPages:0
                    })
                    this.props.saveDataForMap([{
                        latitude: 25.761681,
                        longitude: -80.191788,
                        hideMarker: true
                        
                    }])
                }
                    
                
               
            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectsList ... -> Component: ConstructionLobbyPage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }


    componentWillMount() {
        this.props.saveCurrentSelectionListing(null)
        window.addEventListener('getProjectsList', this.getProjectsList);
        // if (this.props.saveCurrentSelectionListing) this.props.saveCurrentSelectionListing(null);
        // setTimeout(() => {
        //     this.props.saveDataForMap(this.state.data);
        // }, 500) // time for API

        // if(location!=null&&location.state!=null&&location.state.searchForm){
        //     if(saveState){
        //         saveState(location.state.searchForm)
        //     }
        //     this.setState({
        //         ...this.state,
        //         searchForm:location.state.searchForm
        //     })
        // }

       


    }

    componentDidMount(){
        this.getProjectsList();

        let initialData = {
            mapSide: "right",
            selectionHeader: false,
            showSelectionToolBar: false,
            selectionMapFloating: true,
            leftBlockWidth: 837,
            currentPage:'ConstructionLobbyPage'
        }
        this.props.saveState(initialData);
    }

    componentWillUpdate(){
        let page  = document.getElementsByClassName('Page')[0];
        if(page!=null){
           if(this.props.widthRouterMark!==page.clientWidth){
            this.props.saveState({
                widthRouterMark:page.clientWidth
            })
           }
        }
    }

    componentWillUnmount(){
        window.removeEventListener('getProjectsList', this.getProjectsList);
        if(this.props.saveState){
            this.props.saveState({
                    Id:null,
                    showSelectionToolBar:false
            })
        }
    }

    switchToPage(newPage){
        let {data,currentPage} = this.state;
    
        if(currentPage != newPage){
            let pageData = data.slice(ITEM_PER_PAGE * (newPage - 1),data.length > ITEM_PER_PAGE * newPage ? ITEM_PER_PAGE * newPage : data.length);
            
            this.setState(()=>{
                return {
                    currentPage:newPage,
                    pageData:pageData,
                }
            })
    
            this.props.saveDataForMap(pageData)
        }
        
    }



    // handleButtonClick = (event) => {
    //     let name = event.target.name
    //     this.setState((prevState) => {
    //         return {
    //             searchForm: {
    //                 ...prevState.searchForm,
    //                 [name]: !prevState.searchForm[name]
    //             }
    //         }
    //     })
    // }

    render() {
        // let locationIcon = require('../../elements/icons/s_location_offer.png');
        // const smartDealImg = require('../../elements/images/sold_out_en.png');
        // const tempMissoni = require('../../elements/images/missoni.png');


        let { dictionary, hoveredMarkerId} = this.props;
        let { data, searchForm,totalPages,currentPage,pageData } = this.state;
        if (pageData) {
            return (
             
                <motion.div 
                   variants={pageTransition}
                exit="out"
                animate="in"
                initial="out"
                className="Page">
                    <div className={"ConstructionLobby"}>
                        <Helmet>
                            <title>SMART LUXE</title>
                            {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/projects`,
                                    })
                                }
                        </Helmet>
                        <ProjectCardsGrid
                            searchForm={searchForm}
                            hoveredMarkerId={hoveredMarkerId}
                            cards={pageData}
                            dictionary={dictionary}
                        />
                        {/* <Pagination 
                            totalPages = {totalPages} 
                            currentPage = {currentPage}
                            switchToPage = {this.switchToPage.bind(this)}
                        /> */}
                    </div>
                </motion.div>
            )
        } else return null;
    };

}

export default withRouter(ConstructionLobbyPage)