import React from "react";
import './agent-form-style.css'

export default function AgentForm(props){
    let {} = props;
    return(
        <div className="InfoModal">
                agent asdsadsadsa
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
        </div>
               

    )
}