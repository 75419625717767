import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import HOne from '../h-one';
import './thanks-modal-style.css';
import Parser from 'html-react-parser';
class ThanksModal extends React.Component {
    render() {
        let { title, message,ModalBlurId } = this.props;
        let parsedTitle = Parser(title);
        let parsedMessage = Parser(message);
        let body = null;
        body = (
            <>
                <div className="Body">

                    <HOne addclass="ThanksTitle" title={parsedTitle} />

                    <div className="ModalMessage">
                        <div className="ModalMessageText">
                            {parsedMessage}
                        </div>
                    </div>

                </div>
            </>
        )
        let page = null;
        if (this.props.isVisible) {
            page = (
                <motion.div
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="ModalBlur"
                    id={ModalBlurId}
                >
                    <div className="Modal">
                        {body}
                        <div onClick={this.props.closeModal}>
                            <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                                <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                                    <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                    <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                                </g>
                            </svg>

                        </div>
                    </div>

                    <div className="ModalBlurDiv" onClick={this.props.closeModal}></div>
                </motion.div>
            )
        } else {
            page = null;
        }

        return (
            <AnimatePresence exitBeforeEnter>
                {page}
            </AnimatePresence>
        )
    }
}

export default ThanksModal;