import React from "react";

import './loading-style.css';

const img_loading = require('../../elements/images/processing.png')
export default function Loading(props){
    return(
        <div className="LoadingBlock" >
            <img className="LoadingComponent" src={img_loading} alt='Loading...'/>
        </div>
    )
}