import React from 'react';
import HOne from "../h-one";
import { withRouter, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import ServerApi from '../../services/server-api-service';
import StringProcessingService from '../../services/string-processing-service';
import ConfigurationService from '../../services/configuration-service';
import SeoService from '../../services/seo-service';
import parser from 'html-react-parser';
import UserForm from '../user-form';
import InnerArticleGrid from '../inner-article-grid';
import ReactPlayer from 'react-player';
import Modal from '../modal';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

import './article-inner-page-style.css'

class ArticleInnerPage extends React.Component {
    serverApi = new ServerApi();
    stringProcessingService = new StringProcessingService();
    configurationService = new ConfigurationService();
    seoService = new SeoService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            isVisibleMessage: false,
            isVisibleMessageScrollFlag: true,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: "",
                project: ''
            },
        }
    }

    // getNeighborhoodArticles(id) {
    //     if (id) {
    //         this.serverApi.getArticlesForNeihgborhood(id)
    //             .then((data) => {
    //                 this.setState({
    //                     articles: data.data
    //                 })


    //             })
    //             .catch((error) => {
    //                 console.log(`ERROR: fetch getNeighborhoodArticles ... -> Component: ArticleInnerPage ERROR:${error}`);
    //             })
    //     }
    // }

    getProjectArticles(id) {
        if (id) {
            this.serverApi.getArticlesForProject(id)
                .then((data) => {
                    let arr = data.data;
                    let articles = [];
                    arr.map((item) => {
                        if (item.id != this.props.match.params.id) {
                            articles.push(item);
                        }
                        return null;
                        // articles.push(item);
                    })
                    this.setState({
                        articles: articles
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getNeighborhoodArticles ... -> Component: ArticleInnerPage ERROR:${error}`);
                })
        }
    }

    componentWillUnmount() {
        if (this.props.saveState) {
            this.props.saveState({
                articlesTranslations: null
            })
        }

    }

    componentDidUpdate(prevProps) {
        let windowRelativeBottom = document.documentElement.getBoundingClientRect().bottom;
        if (windowRelativeBottom < ((document.documentElement.clientHeight + document.getElementsByClassName('Footer')[0].clientHeight) + (this.props.mobileVerison ? 1 : 0)) && prevProps.pageScroll < this.props.pageScroll && this.props.mobileVerison) {
            // добавим больше данных
            if (this.state.isVisibleMessage !== true && this.state.isVisibleMessageScrollFlag) {
                this.setState({
                    isVisibleMessage: true
                })
            }

        }

    }

    componentWillMount() {
        if (this.props.saveState) {
            this.props.saveState({
                currentPage: 'ArticleInnerPage',
                showSelectionToolBar: false,
                selectionMapFloating: false,
            })
        }

        if (this.props.match.params.id) {
            this.serverApi.getArticleInfo(this.props.match.params.id)
                .then((res) => {
                    let area = res.data.areas[0];
                    let project = res.data.projects[0];
                    let requestName = '';
                    let projectDepend = '';
                    if (this.props.saveState) {
                        this.props.saveState({
                            articlesTranslations: res.data.translations
                        })
                    }
                    if (res.data.language !== this.configurationService.getCurrentLanguagePrefix()) {
                        this.props.history.push('/articles')
                    }
                    if (project != null) {
                        requestName = project.name;
                        projectDepend = project.name;
                    } else if (area != null) {
                        requestName = area.name;
                        projectDepend = area.name;
                    }
                    this.setState({
                        data: res.data,
                        contactInfo: {
                            ...this.state.contactInfo,
                            userComment: `${this.props.dictionary.send_me_more_details} ${requestName}`,
                            project: projectDepend
                        }
                    })
                    if (this.props.match.params.projectId) this.getProjectArticles(this.props.match.params.projectId);


                })
                .catch((error) => {
                    console.log(`ERROR: fetch getArticleInfo ... -> Component: ArticleInnerPage ERROR:${error}`);
                })
        }
    }

    renderInfo(data) {
        let parsedData = parser(data);
        // return (parsedData);
        if (Array.isArray(parsedData)) {
            return (
                parsedData.map((item, index) => {
                    if (item.type != null) {
                        switch (item.type) {
                            case 'p': {
                                return (
                                    <p key={item.key} >
                                        {item.props.children}
                                    </p>)
                            }
                            case 'img': {
                                return (
                                    <img key={item.key} alt='Info' src={item.props.src} className={"ArtImg"}>
                                    </img>
                                )
                            }
                            default: {
                                return item;
                            }
                        }
                    }
                    return item;
                })

            );
        } else return (parsedData);

    }

    closeModal = (isVisibleMessageScrollFlag = true) => {
        this.setState({
            isVisibleMessageScrollFlag: isVisibleMessageScrollFlag,
            isVisibleMessage: false
        })
    }

    handleInput = (event) => {
        this.setState({
            contactInfo: {
                ...this.state.contactInfo,
                [event.target.name]: event.target.value
            }
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactInfo: {
                userComment: "",
                userEmail: "",
                userFullName: "",
                userPhone: ""
            }
        })
    }

    renderMainImg() {
        let { data } = this.state;
        // let { dictionary, width, country, saveState } = this.props;
        let res = null;
        if (data.video != null) {
            res = (
                <>
                    <ReactPlayer
                        width={'100%'}
                        className='react-player fixed-bottom Video'
                        url={data.video}
                        controls={true}
                        config={{
                            youtube: {
                                playerVars: {
                                    modestbranding: 1,
                                    color: 'red'
                                }
                            },
                        }}
                    />
                    {data.captions.video && <div className={"ImageText"}>{data.captions.video}</div>}
                </>
            )
        }else 
        if (data.photo != null) {
            res = (
                <>
                    <img className={"ArtImg"} alt='Illustration' src={data.photo}></img>

                    {/* <div className={"ImageText"}>Illustration</div> */}
                    {data.captions.photo && <div className={"ImageText"}>{data.captions.photo}</div>}
                </>
            )
        }

        return res;

    }

    renderUserFormTitle() {
        let { data } = this.state;
        let { dictionary } = this.props;

        let area = data.areas[0];
        let project = data.projects[0];
        let title = '';
        let logo = <></>;
        let line = '';

        if (project != null) {
            if (project.logo != null) {
                line = 'none';
                logo = (
                    <Link to={`/projects/${project.id}/${this.stringProcessingService.StringFormatUrl(project.name)}`}> <img className='FormLogo' src={`https://ngirealty.com/img/projects/${project.id}/${project.logo}`} alt='Logo'></img> </Link>
                )
            } else {
                title = `<br/> ${project.name}`
            }
        } else {
            title = `<br/> ${area.name}`
        }

        return (
            <div className={"SectionHdr"}>
                <HOne addclass={"lagresize"} line={line} title={`${dictionary.send_me_more_details_about_project} ${title}`} />
                {logo}
            </div>
        )
    }

    render() {
        // const tempHeader = require('../../elements/images/misoni_hdr_tmp.png');
        // const tempProImg1 = require('../../elements/images/temp_pro_img1.jpg');
        // const tempProImg2 = require('../../elements/images/temp_pro_img2.jpg');
        // const tempProImg3 = require('../../elements/images/temp_pro_img3.jpg');

        const iconFb = require('../../elements/images/icon_fb.svg').default;
        const iconIn = require('../../elements/images/icon_in.svg').default;
        const iconTwi = require('../../elements/images/icon_twi.svg').default;
        const iconDate = require('../../elements/images/icon_date.svg').default;
        // const iconDateWhite = require('../../elements/images/icon_date_white.svg');

        // let locationIcon = require('../../elements/icons/s_location_offer.png');
        // const smartDealImg = require('../../elements/images/sold_out_en.png');
        // const tempMissoni = require('../../elements/images/missoni.png');

        let { data, contactInfo, articles, isVisibleMessage } = this.state;
        let { dictionary, width, country, neighborhoods } = this.props;
        let { projectId, projectName, id, articleName } = this.props.match.params

        if (data != null) {
            let options = { year: 'numeric', month: 'long', day: 'numeric' };
            let locale = 'en-US';
            switch (this.configurationService.getCurrentLanguagePrefix()) {
                case 'pt': {
                    locale = "pt-PT";
                    break;
                }
                case 'es': {
                    locale = "es-ES";
                    break;
                }
                default: {
                    break;
                }

            }
            let date = new Date(data.date).toLocaleDateString(locale, options);

            let logo = '';
            let logoLink = '';
            let area = data.areas[0];
            let project = data.projects[0];
            let neighborhood;

            let filteredProjects = [];

            if (data.projects != null && Array.isArray(data.projects)) {
                data.projects.forEach(element => {
                    if (element.web_online != null) filteredProjects.push(element)
                });
            }

            let metaTitle = data.title;
            let description = data.body;
            if (data.meta.title != null) {
                metaTitle = data.meta.title;
            }
            if (data.meta.description != null) {
                description = data.meta.description
            }

            if (project != null && area != null && area.image_cover != null) {
                logo = `https://ngirealty.com/img/smart_luxe/areas/cover/${area.image_cover}`;
                logoLink = area.name;
            }

            if (project != null && project.cover_image != null) {

                logo = `https://ngirealty.com/img/projects/${project.id}/${project.cover_image}`;
                logoLink = project.name;
            } else if (area != null && area.image_cover != null) {
                logo = `https://ngirealty.com/img/smart_luxe/areas/cover/${area.image_cover}`;
                logoLink = area.name;
            }

            neighborhoods.forEach((item, index) => {
                if (project != null && item.id == project.area_id) {
                    neighborhood = item;
                } else if (area != null && area.id == item.id) {
                    neighborhood = item;
                }
            })

            // let articleMetaBody = data.body.replaceAll('<p>', '');
            // articleMetaBody = articleMetaBody.replaceAll('</p>', '');
            // articleMetaBody = articleMetaBody.replaceAll('\r\n\r\n', '');

            let parsedNameForUrl = this.stringProcessingService.StringFormatUrl(articleName);
            return (
                <div className={"ArticleInnerPage"}>
                    <Helmet prioritizeSeoTags>
                        <title> {metaTitle} | Smart Luxe</title>
                        <meta name="description" content={description} />
                        {/* {articleName != null && id != null ? <link rel="canonical" href={`https://smartluxe.com/articles/${id}/${parsedNameForUrl}`}></link> : null} */}
                        {this.seoService.insertHrefLangTags(
                            {
                                path: `/articles/:id/:name`,
                                params:{
                                    id:id,
                                    name:parsedNameForUrl
                                },
                                translations: data.translations,
                                currentLang:data.language
                            })
                        }
                        {/* <meta property="og:url" content={`${document.location.href}`} />
                        <meta property="og:title" content={`${metaTitle} | Smart Luxe`} />
                        <meta property="og:description" content={description} />
                        <meta property="og:type" content="article" />
                        <meta property="og:image" content={logo} />
                        <meta property="og:image:alt" content={data.title} />
                        <meta property="og:image:type" content="image/jpeg" />
                        <meta property="og:image:width" content="1280" />
                        <meta property="og:image:height" content="360" />



                        <meta property="twitter:title" content={`${metaTitle} | Smart Luxe`} />
                        <meta property="twitter:description" content={description} />
                        <meta property="twitter:image" content={logo} />
                        <meta property="twitter:image:alt" content={data.title} />
                        <meta name="twitter:card" content="summary_large_image" />

                        <script type="application/ld+json">
                            {`{"@context": "https://schema.org",
                            "@type": "TechArticle",
                            "headline": "${data.title}",
                            "image": "${logo}",
                            "url": "${document.location.href}",
                            "datePublished": "${date}",
                            "description": "${parser(data.body)[0].props.children}",
                            "articleBody": "${articleMetaBody}"
                            }`}
                        </script> */}

                    </Helmet>
                    <div className="PageLogo">
                        <img id="PageLogoImg" alt="Logo" src={logo}></img>
                        <div id="PageLogoTitle">
                            {(project != undefined && project.web_online != null) ? <Link className="ProjectTitle" to={`/projects/${project.id}/${parsedNameForUrl}`}>
                                <HOne line={width > 600 ? "lagre" : "none"} title={logoLink} className={'Title'} />
                            </Link> : <HOne line={width > 600 ? "lagre" : "none"} title={logoLink} className={'Title'} />}

                        </div>
                    </div>
                    <div className={"ArticleBody"}>
                        <h1>{data.title}</h1>
                        <div className={"date"}>
                            <img src={iconDate} alt="Date" />
                            {date}
                        </div>
                        {this.renderMainImg()}
                        {this.renderInfo(data.body)}

                        {/* <img className={"ArtImg"} src={tempProImg1}></img>
                    
                    <p className={"firsP"}>
                        If you love coastal living with walkable access to Atlantic Ocean beaches, and you want to move to a beachfront address while bypassing the hustle and bustle of South Beach, there are two beachside communities to consider when looking to make your move: Sunny Isles Beach and Surfside.
                    </p>
                    <p>
                        While these communities are practically next-door neighbors (with ritzy Bal Harbour nestled between them) and they share many amenities, including direct beach access to the Atlantic. Plus, they both are in prime locations that provide quick and easy access to airports, shopping, arts and culture, and outdoor living, living there could not be more different from one another.
                    </p>
    
                    <p>
                        As insiders who’ve lived in these popular Miami neighborhoods, we’ve broken down the similarities and differences between these two beautiful seaside communities in case you are looking to move to Miami.
                    </p>
    
    
    
    
                    <img className={"ArtImg"} src={tempProImg2}></img>
                    <div className={"ImageText"}>Conceptual rendering - Turnberry Ocean Club</div>
    
    
                    <h3>Business: Sunny Isles Beach vs Surfside</h3>
    
                    <p>
                        While these communities are practically next-door neighbors (with ritzy Bal Harbour nestled between them) and they share many amenities, including direct beach access to the Atlantic. Plus, they both are in prime locations that provide quick and easy access to airports, shopping, arts and culture, and outdoor living, living there could not be more different from one another.
                    </p>
    
                    <p>
                        As insiders who’ve lived in these popular Miami neighborhoods, we’ve broken down the similarities and differences between these two beautiful seaside communities in case you are looking to move to Miami.
                    </p>
    
    
                    <p>
                        While neither of these two neighborhoods will put you in the thick of a corporate business district with commercial office spaces all around you, both are within a close enough distance to areas such as Downtown Miami, Aventura, and Brickell, where office spaces are far more abundant and small private professional service businesses flourish. Many professionals live in these areas precisely to avoid living in the midst of the busy areas where they work. Commuting to and from Downtown Miami and Brickell is definitely doable and you can easily drive to and from work in less than 30-45 minutes from both locations.
                    </p>
    
    
                    <img className={"ArtImg"} src={tempProImg3}></img>
                    <div className={"ImageText"}>Conceptual rendering - Turnberry Ocean Club</div>
    
    
                    <p>If you enjoy walking and biking about town, in Sunny Isles Beach you can walk to the beach and to commercial shopping areas, as well as to local parks. Surfside is meticulously designed to provide a small-town feeling with safe, quiet streets offering plenty of walkability and providing a pleasant place to ride around on your bike.</p>
     */}
                        <div className={"Share"}>
                            {dictionary.share}: &nbsp;
                            <FacebookShareButton
                                url={document.location.href}
                                quote={data.title}
                            >
                                <img alt={"Facebook"} src={iconFb}></img>
                            </FacebookShareButton>
                            <LinkedinShareButton
                                url={document.location.href}
                                title={data.title}
                                summary={data.title}
                                source={data.title}
                            >
                                <img alt={"LinkedIn"} src={iconIn}></img>
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={document.location.href}
                                title={data.title}
                            >
                                <img alt={"Twitter"} src={iconTwi}></img>
                            </TwitterShareButton>
                            {/* <a href={`https://www.facebook.com/sharer/sharer.php?u=${document.location.href}`}><img alt={"Facebook"} src={iconFb}></img></a>
                            <a href={`https://www.linkedin.com/shareArticle?url=https://smartluxe.com/&mini=true`} target="_blank"><img alt={"LinkedIn"} src={iconIn}></img></a>
                            <a href={`https://twitter.com/intent/tweet?text=${data.title}&url=${document.location.href}`}><img alt={"Twitter"} src={iconTwi}></img></a> */}
                        </div>

                        <div className={"Separator"}></div>




                        {/* <div className={"SectionHdr"}>
                            <HOne addclass={"lagresize"} title={`${dictionary.send_me_more_details_about_project} <br/> ${data.projects[0].name}`} />
                        </div> */}
                        {this.renderUserFormTitle()}

                        <UserForm
                            clearForm={this.clearForm}
                            topPartRow={true}
                            contactForm={contactInfo}
                            handleInput={this.handleInput}
                            width={width}
                            message={dictionary.thank_you_deal}
                            country={country}
                            thankModal={true}
                            secureInfo={true}
                            dictionary={dictionary}
                            className={'Form'}
                            CustomInputStyle={{ padding: '0 0 0 5px' }}
                            style={{ textAlign: 'center', display: 'block', justifyContent: 'center' }} />
                    </div>
                    {
                        articles != null && Array.isArray(articles) && articles.length > 0 ?
                            (
                                <div className={"MoreArticles"}>
                                    <HOne title={dictionary.more_articles} />
                                    <InnerArticleGrid
                                        dictionary={dictionary}
                                        data={articles}
                                        projectName={projectName}
                                        projectId={projectId}
                                    />
                                </div>
                            ) : null
                    }

                    {
                        filteredProjects != null && Array.isArray(filteredProjects) && filteredProjects > 0 ?
                            (
                                <div className={"SimilarProjects"}>
                                    <HOne title={`${dictionary.similar_projects_in} ${neighborhood.name}`} />
                                    <InnerArticleGrid
                                        dictionary={dictionary}
                                        data={filteredProjects}
                                        type={'card'}
                                        projectId={projectId}
                                        projectName={projectName}
                                    />
                                </div>
                            ) : null
                    }
                    {/* 
                    <div className={"SimilarProjects"}>
                        <HOne title={"SIMILAR PROJECTS IN AVENTURA"} />
                        <InnerArticleGrid
                            dictionary={dictionary}
                            data={data.projects}
                            type={'card'}
                        />

                        <div className={"GridMain"}>
                            <div className={"Grid"}>
                                <div className={"OfferParent"}>
                                    <div className={"OfferMain"}>
                                        <div className={"TopBlockOffer"}>
                                            <div className="OfferTitle">
                                                <div className="OfferLocation"><img src={iconDateWhite} width="14" height="14" alt="" />June 28, 2021</div>
                                            </div>
                                            <img className="OfferImg" src="https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/358844995/83dcefb7.jpeg" alt="" />
                                        </div>
                                        <div className={"BottomBlockOfferProject"}>
                                            <div className={"ProjectName"}>Missoni Baia</div>
                                            <div className={"Separator"}></div>
                                            <div className={"ProjectFeature ProjectFeatureOne"}>
                                                <div className={"Featuretitle"}>Estimated Delivery:</div>
                                                <div className={"FeatureDescription"}>Q1 2022</div>
                                            </div>
                                            <div className={"ProjectFeature"}>
                                                <div className={"Featuretitle"}>Price Range:</div>
                                                <div className={"FeatureDescription"}>$505,900 - $1,050,900</div>
                                            </div>
                                            <div className={"ReadMore"}><button name="TopForm" className="Button" id="ButtonSubmit">Read More</button></div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"OfferParent"}>
                                    <div className={"OfferMain"}>
                                        <div className={"TopBlockOffer"}>
                                            <div className="OfferTitle">
                                                <div className="OfferLocation"><img src={iconDateWhite} width="14" height="14" alt="" />June 28, 2021</div>
                                            </div>
                                            <img className="OfferImg" src="https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/358844995/83dcefb7.jpeg" alt="" />
                                        </div>
                                        <div className={"BottomBlockOfferProject"}>
                                            <div className={"ProjectName"}>Missoni Baia</div>
                                            <div className={"Separator"}></div>
                                            <div className={"ProjectFeature ProjectFeatureOne"}>
                                                <div className={"Featuretitle"}>Estimated Delivery:</div>
                                                <div className={"FeatureDescription"}>Q1 2022</div>
                                            </div>
                                            <div className={"ProjectFeature"}>
                                                <div className={"Featuretitle"}>Price Range:</div>
                                                <div className={"FeatureDescription"}>$505,900 - $1,050,900</div>
                                            </div>
                                            <div className={"ReadMore"}><button name="TopForm" className="Button" id="ButtonSubmit">Read More</button></div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"OfferParent"}>
                                    <div className={"OfferMain"}>
                                        <div className={"TopBlockOffer"}>
                                            <div className={"ProjectLogo"}><img src={tempMissoni} alt="" className={"ProjectLogoImg"} /></div>
                                            <div className="OfferTitle">
                                                <div className="OfferLocation"><img src={iconDateWhite} width="14" height="14" alt="" />June 28, 2021</div>
                                            </div>
                                            <img className="OfferImg" src="https://dvvjkgh94f2v6.cloudfront.net/523fa3e6/358844995/83dcefb7.jpeg" alt="" />
                                        </div>
                                        <div className={"BottomBlockOfferProject"}>
                                            <div className={"ProjectName"}>Missoni Baia</div>
                                            <div className={"Separator"}></div>
                                            <div className={"ProjectFeature ProjectFeatureOne"}>
                                                <div className={"Featuretitle"}>Estimated Delivery:</div>
                                                <div className={"FeatureDescription"}>Q1 2022</div>
                                            </div>
                                            <div className={"ProjectFeature"}>
                                                <div className={"Featuretitle"}>Price Range:</div>
                                                <div className={"FeatureDescription"}>$505,900 - $1,050,900</div>
                                            </div>
                                            <div className={"ReadMore"}><button name="TopForm" className="Button" id="ButtonSubmit">Read More</button></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> 

                    </div>
                    */}
                    <Modal
                        ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                        closeModal={this.closeModal.bind(this, false)}
                        modalClass={'MessageModal'}
                        closeColor={'#000'}
                        speed={0.5}
                        ModalStyle={{ justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                        isVisible={isVisibleMessage}>
                        <HOne addclass={"xsmall"} align={"center"} title={`${dictionary.request_about}`} line={"none"} />
                        <img src={data.project_logo === null ? null : `https://ngirealty.com${data.project_logo}`} className={"ProjectLogo"} alt=""></img>
                        <br />
                        {data.web_urgency ?
                            <React.Fragment>
                                <svg xmlns="http://www.w3.org/2000/svg" width="138.247" height="2" viewBox="0 0 138.247 2">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stopColor="#a96903" />
                                            <stop offset="0.258" stopColor="#aa6a02" />
                                            <stop offset="0.352" stopColor="#b07102" />
                                            <stop offset="0.418" stopColor="#b97d02" />
                                            <stop offset="0.472" stopColor="#c78e01" />
                                            <stop offset="0.518" stopColor="#daa401" />
                                            <stop offset="0.557" stopColor="#f0be00" />
                                            <stop offset="0.579" stopColor="#ffd100" />
                                            <stop offset="1" stopColor="#a96903" />
                                        </linearGradient>
                                    </defs>
                                    <g id="Сгруппировать_94" data-name="Сгруппировать 94" transform="translate(-2822.173 274.933)">
                                        <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="138.247" height="2" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />
                                    </g>
                                </svg>
                                <br />
                                <div className={"FormText"}>
                                    {data.web_urgency}
                                </div>
                            </React.Fragment>
                            : (null)
                        }
                        <UserForm
                            className="MobileMessageModal"
                            thankModal={true}
                            inputBans={{ comment: true, message: true }}
                            clearForm={this.clearForm}
                            message={dictionary.thank_you_deal}
                            initialMessage={`${dictionary.send_me_more_details_about_project} ${data.name}`}
                            saveCurrentSelectedCountry={this.saveCurrentSelectedCountry}
                            country={country}
                            topPartRow={true}
                            contactForm={contactInfo}
                            handleInput={this.handleInput}
                            width={width}
                            secureInfo={true}
                            dictionary={dictionary}
                            style={{ textAlign: 'center', justifyContent: 'center' }}
                        />
                    </Modal>
                </div>
            )
        }
        return <div></div>;


    };
}

export default withRouter(ArticleInnerPage);