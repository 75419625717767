import React,{useState  } from 'react';

import './router-mark-style.css';

export default function RouterMark(props){
    let {children,width,minHeight,applicationWidth,style={}} = props;
    const [minWidth,setMinWidth] = useState(0);
    if(width!=='auto' && minWidth !== width){
        setMinWidth(width)
    }
    if(applicationWidth){

    }

    let styleProperties = applicationWidth>1000?{width:width,minHeight:'900px',minWidth:minWidth}:null
    styleProperties= {
        ...styleProperties,
        ...style,
    }
        


    return(
        <div className='RouterMark' style={styleProperties}>
            {children}
        </div>
    )
}