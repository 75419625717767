import React from 'react';
import Article from '../article';
import HOne from '../h-one';

import './article-grid-style.css';
//also see article-inner-page-style.css
export default function ArticleGrid(props) {
    let { data, dictionary,project} = props;
    if (data != null && Array.isArray(data) && data.length > 0) {
        return (
            <div className={"ProjectInnerArticles ProjectInnerBox ProjectInnerАnchorArticles"}>
                <HOne addclass={"xsmall"} align={"left"} title={dictionary.articles} />
                {data.map((article, index) => {
                    return (
                        <Article 
                            project={project}
                            key={index + article.title} 
                            data={article} 
                            dictionary={dictionary}
                        />
                    )
                })}
            </div>
        )
    }
    return null;
    
}
