import React from "react";
import StringProcessingService from "../../services/string-processing-service";
import sold_out from "../../elements/images/invest/sold_out.png";

import './marker-custom-info-window-style.css';


const stringProcessingService = new StringProcessingService();

function MarkerCustomInfoWindow(props) {
    let { dictionary, item, type } = props;
    const soldOutIcon = require('../../elements/images/sold_out_en.png');

    switch (type) {
        case "Projects": {

            let QValue = '';

            let month = ''

            if (item.completion && item.completion !== null) {
                month = parseInt(item.completion.slice(5, 7));
                if ((month >= 1 && month <= 2) || month == 12) QValue = dictionary.winter
                else if (month >= 3 && month <= 5) QValue = dictionary.spring
                else if (month >= 6 && month <= 8) QValue = dictionary.summer
                else if (month >= 9 && month <= 11) QValue = dictionary.fall
            }


            let priceRange = '';
            let priceName = '';

            if (item.max_price !== undefined && item.min_price !== null) {
                priceRange = ` $${stringProcessingService.convertToFormat(item.min_price.toString())}`
                priceName = dictionary.starting_price;
            }

            return (
                <div className={'SelecitonMapMarker'}>
                    {item.units_total === item.units_sold ? (<img className="SmartDealIcon SoldOutIcon" id="" src={soldOutIcon} alt="" />) : (null)}
                    {/* <img className='MarkerImage' alt={"logo"} src={`https://ngirealty.com/img/projects/${item.id}/${item.logo}`}/> */}
                    <img className='MarkerImage' alt={"logo"} src={`https://ngirealty.com/img/projects/${item.id}/${item.logo}`} />

                    {item.completion !== undefined && item.completion !== null ? (
                        <div>
                            {item.timeline === 4 ? (dictionary.completed) : (dictionary.estimated_delivery)}: <span>{QValue} {item.completion && parseInt(item.completion.slice(0, 4)) + ((QValue === dictionary.winter && month === 12) && 1)}</span>
                        </div>
                    ) : (null)}
                    {item.sold_out ? (<img className="SoldOutIcon" id="" src={sold_out} alt="" />) : (null)}
                    {priceRange !== '' ?
                        (<div>
                            {priceName}:  <span>{priceRange}</span>
                        </div>) : null}
                </div>
            )
        }
        case "Listings": {

            let price = item.list_price.toString();
            if (item.close_price != null && item.close_price > 0) price = item.close_price.toString();
            let parsedPrice = stringProcessingService.convertToFormat(price);

            return (
                <div className={'ListingMapMarker'}>
                    <div className="Location">
                        {item.StreetNumber} {item.StreetDirPrefix} {item.StreetName} {item.UnitNumber != null ? ('#') : ('')}{item.UnitNumber}
                        <div style={{ display: 'block' }}>

                            {item.city}, {item.state_or_province} {item.postal_code}
                        </div>
                    </div>
                   
                    <img className='MarkerImage' alt={"logo"} src={item.image} />
                    <div className="Price" >
                        Price: <span> ${parsedPrice} </span>
                    </div>
                </div>
            )
        }
        default: {
            return null;
        }
    }

}

export default MarkerCustomInfoWindow;