import React from "react";
import ProjectOffer from "../project-offer/project-offer";

import './project-offers-grid-style.css';

export default function ProjectOffersGrid(props){
    let {width,data,dictionary,hoveredMarkerId,saveState,vertical=false,selectionData = null} = props;
    return(
        <div className={`Grid ProjectOffersGrid`}>
            {data&& data.map((offer)=>{
                return(<ProjectOffer
                    offer={offer}
                    selectionData={selectionData}
                    width={width}
                    hoveredMarkerId={hoveredMarkerId}
                    dictionary={dictionary}
                    saveState={saveState}
                    vertical={vertical}
                />)
            })}
        </div>
    )
}