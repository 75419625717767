import React from 'react';

import './google-map-static-custom-style.css';


function GetMarkers(data){

    let res =''

    if(data){
        data.map((offer,index)=>{
            res+=`${offer.latitude},%20${offer.longitude}`
            if(index!==data.length-1) res+='|'
         
        })
    }
    return res;
}

function GoogleMapStaticCustom(props) {
    let { lng, lat, googleMapSize = { width: 1280, height: 1280 },zoom = 13 } = props;
    //for generate styles https://mapstyle.withgoogle.com/
    let url ='';
    let icon =  'https://ngirealty.com/resource/data/smlx_marker_a.png';
    if(!props.data)  url = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyC46fA748XZu3TynqHM1lhQLRpGFKzrxdc&markers=icon:${icon}%7Csize:small%7Clabel:P%7C${lat},%20${lng}&zoom=${zoom}&format=png&maptype=roadmap&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x333333%7Csaturation:36%7Clightness:40&style=element:labels.text.stroke%7Ccolor:0xffffff%7Clightness:16%7Cvisibility:on&style=feature:administrative%7Celement:geometry.fill%7Ccolor:0xfefefe%7Clightness:20&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xfefefe%7Clightness:17%7Cweight:1.2&style=feature:landscape%7Celement:geometry%7Csaturation:0%7Clightness:70%7Cgamma:1.34%7Cvisibility:on&style=feature:poi%7Celement:geometry%7Ccolor:0xf5f5f5%7Clightness:21&style=feature:poi.park%7Celement:geometry%7Ccolor:0xdedede%7Clightness:21&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xd2ab67%7Clightness:18&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0xba9450%7Clightness:17&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xffffff%7Clightness:29%7Cweight:0.2&style=feature:road.local%7Celement:geometry%7Ccolor:0xf2e6d2%7Clightness:16&style=feature:transit%7Celement:geometry%7Ccolor:0xf2f2f2%7Clightness:19&style=feature:water%7Celement:geometry%7Ccolor:0xe9e9e9%7Clightness:17&style=feature:water%7Celement:geometry.fill%7Ccolor:0xc3d6e4&size=${googleMapSize.width}x${googleMapSize.height}&scale=2`
    else {
        url = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyC46fA748XZu3TynqHM1lhQLRpGFKzrxdc&markers=icon:${icon}%7Csize:small%7Clabel:P%7C${GetMarkers(props.data)}&format=png&maptype=roadmap&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x333333%7Csaturation:36%7Clightness:40&style=element:labels.text.stroke%7Ccolor:0xffffff%7Clightness:16%7Cvisibility:on&style=feature:administrative%7Celement:geometry.fill%7Ccolor:0xfefefe%7Clightness:20&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xfefefe%7Clightness:17%7Cweight:1.2&style=feature:landscape%7Celement:geometry%7Csaturation:0%7Clightness:70%7Cgamma:1.34%7Cvisibility:on&style=feature:poi%7Celement:geometry%7Ccolor:0xf5f5f5%7Clightness:21&style=feature:poi.park%7Celement:geometry%7Ccolor:0xdedede%7Clightness:21&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xd2ab67%7Clightness:18&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0xba9450%7Clightness:17&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xffffff%7Clightness:29%7Cweight:0.2&style=feature:road.local%7Celement:geometry%7Ccolor:0xf2e6d2%7Clightness:16&style=feature:transit%7Celement:geometry%7Ccolor:0xf2f2f2%7Clightness:19&style=feature:water%7Celement:geometry%7Ccolor:0xe9e9e9%7Clightness:17&style=feature:water%7Celement:geometry.fill%7Ccolor:0xc3d6e4&size=${googleMapSize.width}x${googleMapSize.height}&scale=2`
    }

    return (
        <img className="GoogleStaticMapImg" alt='img map static' src={url} />
    )
}

export default GoogleMapStaticCustom;