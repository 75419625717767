import React from 'react';
import {
    BrowserRouter as router,
    withRouter,
    Link,
} from "react-router-dom";

import './menu-style.css';




class Menu extends React.Component {
    randomGen(){
        return Math.floor(Math.random() * Math.floor(2000));
    }

    handleClick() {
        this.props.history.replace(`/find-my-deal`);
    }

    renderBody(){
        let {closeModal,dictionary,saveState} = this.props;  //neighboorhoods,neighborhoodsIsVisible,
        if(!document.location.pathname.includes('/selection')){
            return(
                <div className={"MenuHolder"}>
                <div className="Menu">
                    <Link to="/projects" className="Item" onClick={()=>{
                        closeModal();
                        if(window.location.pathname=='/projects'){
                            window.location.reload();
                        }
                    }}>{dictionary.new_construction}</Link>
                    <span className="Separator"></span>
                    <Link to ="/neighborhoods" className="Item" onClick={closeModal}>{dictionary.neighborhoods}</Link>
                    <span className="Separator"></span>
                    <Link to ="/find-my-deal"   className="Item" onClick={() => {
                    if(window.location.pathname.includes('find-my-deal')){
                        window.location.reload();
                    }
                    // this.handleClick();
                    closeModal();
                    }}>{dictionary.buy}</Link>
                     <span className="Separator"></span>
                     <Link to ="/sell" className="Item" onClick={closeModal}>{dictionary.sell}</Link>
                     <span className="Separator"></span>
                    <Link to="/about" className="Item" onClick={closeModal}>{dictionary.about}</Link>
                    
                   
                    {/* <div onClick={handleNeighborhoods} className={neighborhoodsIsVisible?'CurrentMenuItem Item':'Item'}>{dictionary.neighborhoods}</div>
                    {mobileNeighborhoodsItems()} */}
                    <span className="Separator"></span>
                    <Link to="/team" className="Item" onClick={closeModal}>{dictionary.our_team}</Link>
                    <span className="Separator"></span>
                    <Link to ="/contact" className="Item" onClick={closeModal}>{dictionary.contact}</Link>
                </div>
            </div>
            )
        }
    }
    render() {


        // let mobileNeighborhoodsItems = ()=>{
        //     if(neighborhoodsIsVisible){
        //         return(
        //             <div id="MobileNeighborhoodsItems">
        //                 {neighboorhoods.map((item,index)=>{
        //                     return(<Link to={`/neighborhood/${item.id}`} className="Item" onClick={closeModal}>{item.name}</Link>)
        //                 })}
        //             </div>
        //         )
        //     }else return null;
        // }
        // let {handleNeighborhoods} = this.props;

        return (
            <>
                {this.renderBody()}
            </>
        )

    }
}
export default withRouter(Menu);