import React from "react";
import ServerApi from "../../services/server-api-service";
import { withRouter } from "react-router-dom";
import Loading from "../loading";
import './link-tree-page-style.css';

class LinkTreePage extends React.Component {

    serverApi = new ServerApi();
    constructor(props) {
        super(props)
        this.state = {
            loading: false

        }
    }

    componentDidMount() {
        this.props.saveState({
            currentPage:'LinkTreePage',
            isVisibleFooter: false,
            isVisibleHeader: false,
            footerHideElem: true,
            headerDisableMenu: true
        })
        this.serverApi.getAgentData(this.props.match.params.instagram)
            .then((data) => {
                console.log(data)
                this.setState({
                    agentData: data,
                    loading: true
                })
            })
            .catch((error) => {
                console.log('Error with fetch: getAgentData: ', error)
                window.history.pushState(null, 'SmartLuxe', `/`);
            })
    }

    componentWillUnmount() {
        this.props.saveState({
            footerHideElem: false,
            headerDisableMenu: false,
            isVisibleFooter: true,
            isVisibleHeader: true,
        })
    }

    render() {
        const footerLogo = require('../../elements/images/logo_negative_center.svg').default;

        let { agentData } = this.state;
        let { dictionary} = this.props;

        if (!agentData) return null;

        return (
            <>
            <div className="LinkTreePage Page">
                <div className="SocialBlock">
                    <div className="Logo">
                        <img alt='Agent photo' src={`https://ngirealty.com/img/signature/${agentData.profile_picture}`} />
                    </div>
                    <div className="AgentName"> {agentData.name}</div>
                    <div className="Links">

                        {agentData.phone && agentData.phone != '' ? (
                            <a href={`https://wa.me/${agentData.phone}`} target="_blank">
                                <div className="Link">
                                    WhatsApp
                                </div>
                            </a>
                        ) : null}


                        {agentData.email && agentData.email != '' ? (
                            <a href={`mailto:${agentData.email}`} target="_blank">
                                <div className="Link">
                                    E-Mail
                                </div>
                            </a>
                        ) : null}

                        {agentData.instagram && agentData.instagram != '' ? (
                            <a href={`https://www.instagram.com/${agentData.instagram}/`} target="_blank">
                                <div className="Link">
                                    Instagram
                                </div>
                            </a>
                        ) : null}

                        {agentData.facebook && agentData.facebook != '' ? (
                            <a href={'https://www.facebook.com/${agentData.facebook}/'} target="_blank">
                                <div className="Link">
                                    Facebook
                                </div>
                            </a>
                        ) : null}

                        <a href={'https://smartluxe.com'} target="_blank">
                            <div className="Link">
                                Website
                            </div>
                        </a>



                    </div>
                </div>
               
            </div>
            <div className="LinkTreeFooter">
                       <a href="/"> <img src={footerLogo}></img></a>
                       <div>{dictionary.copyright} {new Date().getFullYear()}</div>
                </div>
            </>
        )
    };
}

export default withRouter(LinkTreePage);