import React, { useState } from 'react';
import Modal from '../modal';

import './key-deal-feature-modal-style.css';
function KeyDealFeatureModal(props) {
    const [isVisible, setIsVisible] = useState(false);
    const info = require('../../elements/images/i.svg').default;
    const infoDark = require('../../elements/images/i_dark.svg').default;
    return (
        <div className='KeyDealFeatureModalBlock'>
       
            <img className={"infoIcon"} src={info} alt=""
                onMouseOver={e => {
                    e.target.src = infoDark;
                }}

                onMouseOut={e => {
                    e.target.src = info;
                }}

                onClick={() => {
                    setIsVisible(true)
                }}
            />

            <Modal
                isVisible={isVisible}
                closeModal={() => { setIsVisible(false) }}
            >
                <div className="InfoModal">
                {props.children}
                </div>
                <div onClick={() => { setIsVisible(false) }}>
                    <svg className="CloseIcon" xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                        <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                            <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                            <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke="#000" strokeWidth="2" />
                        </g>
                    </svg>

                </div>
            </Modal>
        </div>
    )
}

export default KeyDealFeatureModal;