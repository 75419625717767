import Parser from 'html-react-parser';
import { withRouter, Link,useLocation } from 'react-router-dom';

export default class StringProcessingService {

    convertToFormat(money) {
        let output = money;
        let countMove = 0;
        let length = money.length
        for (let i = money.length; i <= money.length && i >= 0; i--) {
            let index = (i);
            if (index % 3 == 0 && i !== length && i !== 0) {
                output = output.slice(0, length + countMove - index) + ',' + output.slice(length + countMove - index, length + countMove);
                countMove++;
            }
        }
        // if(length>3){
        //     output=money.slice(0,money.length-3) + ',' + money.slice(money.length-3,money.length)
        // }
        // if(length>6){
        //     output=output.slice(0,output.length-7) + ',' + output.slice(output.length-7,output.length)
        // }
        return output;
    }

    StringFormatUrl(str) {
        if (str !== undefined && str !== null) {
            var pattern = new RegExp("[^a-zA-Z0-9]+", "g");
            return (str.toLowerCase().replace(pattern, '-'))
        }
        return null;
    }

    GetParamsFromUrl(){
        return new URLSearchParams(document.location.search);
    }

    static parseNodeElem(data) {
        try {
            let ul = Parser(data);
            let children = ul.props.children;
            let arr = [];
            for (let i = 0; i < children.length - 1; i++) {
                if (children[i] !== "\n\t") {
                    arr.push(children[i].props.children)
                }
            }
            return arr;
        }catch(exception){
            return false;
        }
    }
}