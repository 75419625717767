import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom';

import StringProcessingService from '../../services/string-processing-service';
import ConfigurationService from '../../services/configuration-service';
import './project-card-style.css'

import sold_out from "../../elements/images/invest/sold_out.png";

const stringProcessingService = new StringProcessingService();

// let imgSrc;
// let imgLastCardClass=''
// let linkParams='';
class ProjectCard extends React.Component {
  configurationService = new ConfigurationService();
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
      imgIsLoaded:false,
      imgSrc:null
    }
  }

  componentDidMount(){
    //Math. floor(Math. random() * (1 + 1))

    switch(Number(this.props.randomNumberForLastCardImage)){
      case 0:{
        this.setState({
          imgSrc: require('../../elements/images/more-projects-1a.jpg'),
          imgLastCardClass:'LastCardBottom',
          linkParams:'?btn_version=map'
        })
        // imgSrc= require('../../elements/images/more-projects-1a.jpg');
       
        break;
      }
      case 1:{
        this.setState({
          imgSrc: require('../../elements/images/more-projects-3.jpg'),
          imgLastCardClass:'LastCardTop',
          linkParams:'?btn_version=collage'
        })
        // imgSrc= require('../../elements/images/more-projects-3.jpg');
     
        break;
      }
      default:{
        break;
      }
    }
  }

  handleMouseOver = (e) => {
    let { data } = this.props;
    document.dispatchEvent(new CustomEvent('markerHover', { detail: { id: data.id } }))
  }

  handleMouseOut = (e) => {
    // if(e.target.className==='OfferMain'||e.target.className==='OfferImg'){
    //   document.dispatchEvent(new CustomEvent('markerHover', { detail: { id: null } }))
    // }

    document.dispatchEvent(new CustomEvent('markerHover', { detail: { id: null } }))
  }

  renderInfoPart() {
    let { hoveredMarkerId,dictionary, data } = this.props;
    const projectLogo = require('../../elements/images/projectLogo.png')

    let month= '';
    let QValue = '';
    if(data.completion&&data.completion!==null){
      month = parseInt(data.completion.slice(5, 7));
      if ((month >= 1 && month <= 2)||month==12) QValue = dictionary.winter
      else if (month >= 3 && month <= 5) QValue = dictionary.spring
      else if (month >= 6 && month <= 8) QValue = dictionary.summer
      else if (month >= 9 && month <= 11) QValue = dictionary.fall
    }

    let priceRange = '';
    let priceRangeDict = dictionary.price_range;
    // if(data.max_price!==undefined&&data.min_price!==undefined&&data.max_price!==null&&data.min_price!==null){
    //     priceRange=`$${stringProcessingService.convertToFormat(data.min_price.toString())} - $${stringProcessingService.convertToFormat(data.max_price.toString())}`
    // }else
    // if(data.max_price!==undefined&&data.max_price!==null) priceRange=` $${stringProcessingService.convertToFormat(data.max_price.toString())}`

    // else 
    if(data.max_price!=null&&data.min_price!=null){
      priceRange=` $${stringProcessingService.convertToFormat(data.min_price.toString())} - $${stringProcessingService.convertToFormat(data.max_price.toString())}`
    } else if(data.min_price!=null){
      priceRange=` $${stringProcessingService.convertToFormat(data.min_price.toString())}`;
      priceRangeDict = dictionary.starting_price;
    }else if(data.max_price!=null){
      priceRange=` $${stringProcessingService.convertToFormat(data.max_price.toString())}`;
      priceRangeDict = dictionary.starting_price;
    }

    if (hoveredMarkerId === data.id) {
      return (
        <div className={"ProjectFeature "} id={'FeatureLogo'}>
          <img className="Logo" alt="logo" src={`https://ngirealty.com/img/projects/${data.id}/${data.logo}`} />
        </div>
      )
    } else {
      return (
        <>
          {data.completion&&data.completion!==null?(<div className={"ProjectFeature ProjectFeatureOne"}>
            <div className={"Featuretitle"}>{data.timeline===4?(dictionary.completed):(dictionary.estimated_delivery)}:</div>
            <div className={"FeatureDescription"}>{QValue} {data.completion && parseInt(data.completion.slice(0, 4))+((QValue===dictionary.winter&&month===12)&&1)}</div>
          </div>):null}

          {priceRange!==''?(
            <div className={"ProjectFeature"}>
            <div className={"Featuretitle"}>{priceRangeDict}:</div>
            <div className={"FeatureDescription"}>{priceRange}</div>
          </div>
          ):null}
        </>
      )
    }
  }

  renderTESTLOCATION(){
    let locations =['Hallandale, FL','Downtown, Miami','Brickell, Miami','South Beach, Miami']
    let { dictionary, data } = this.props;
    switch(data.id){
      case 149:{
        return(locations[0])
        break;
      }
      case 447:{
        return(locations[1])
        break;
      }
      case 545:{
        return(locations[2])
        break;
      }
      case 547:{
        return(locations[3])
        break;
      }
      default:{
        return(data.name)
      }
    }
  }

  renderShortTerm(){
    let { dictionary, data, hoveredMarkerId,searchForm,lastCard=false,shortTerm = true} = this.props;
    if(data.short_rental && shortTerm){
      const termIcon = require(`../../elements/images/seal_short_term_${this.configurationService.getCurrentLanguagePrefix()}.png`);
      return(
        <div className="ShortTermIcon">
          <img src={termIcon} alt='Short Term Icon'/>
        </div>
      )
    }
    return null;
  }

  render() {
    let locationIcon = require('../../elements/icons/s_location_offer.png');
    const smartDealImg = require('../../elements/images/sold_out_en.png');
    const tempMissoni = require('../../elements/images/missoni.png');
    let { dictionary, data, hoveredMarkerId,searchForm,lastCard=false,imgIsLoaded} = this.props;
    let {imgSrc,imgLastCardClass,linkParams } = this.state; 
    
    if(data){
      if(lastCard){ 
         return(
          <Link to={`/projects${linkParams}`} className={"OfferParent ProjectCard"} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOut}>
            <div className={`LastCard ${imgLastCardClass}`}>
               
                <img src={imgSrc} className="Logo" alt={dictionary.more_projects}/>
                {/* HARD TEXT */}
                <div className='Button'>{dictionary.more_projects}</div>
            </div>
          </Link>
         )
      }
      return (
        <Link to={{
          pathname:data.units_total===data.units_sold?(``):(`/projects/${data.id}/${stringProcessingService.StringFormatUrl(data.name)}`),
          state:{
            searchForm:searchForm
          }
        }} className={"OfferParent ProjectCard"} onMouseEnter={this.handleMouseOver} onMouseLeave={this.handleMouseOut}>
        {this.renderShortTerm()}
          <div className={"OfferMain"} >
            <div className={"TopBlockOffer"}>
            
              {/* sold icon */}
              {data.sold_out ? (<img className="SmartDealIcon SoldOutIcon" id="" src={sold_out} alt="" />) : (null)}
              <div className="OfferTitle">
                <div className="OfferLocation"><img src={locationIcon} alt="" />{data.web_location}</div>
              </div>
              <img className="OfferImg" src={`https://ngirealty.com/storage/projects/${data.picture}`} alt="" />
              
              <div className="OfferImgSubstrate"></div>
            </div>
            <div className={"BottomBlockOfferProject"}>
              <h2 className={"ProjectName"}>{data.name}</h2>
              <div className={"Separator"}></div>
              {this.renderInfoPart()}
            </div>
          </div>
        </Link>
      )
    }else return null
  };

}

export default ProjectCard;