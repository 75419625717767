import React from "react";

function Pagination(props){

        
        let { totalPages, currentPage,switchToPage } = props;

        if (totalPages !== undefined) {
            const result = [];
            if(totalPages > 1){
                for (let i = 1; i <= totalPages; i++) {
                    if (totalPages > 10) {
                        if (currentPage < 3) {
                            if (i < 4) {
                                result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                                if(i===3){
                                    result.push(<div key ={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                                }
                            } else if (i > totalPages - 4) {
                                result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                            }
                        } else if (currentPage > totalPages - 3) {
                            if (i < 4) {
                                result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                                if(i===3){
                                    result.push(<div key ={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                                }
                            } else if (i > totalPages - 4) {
                                result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                            }
                        } else {
                            if (i > currentPage - 4 && i < currentPage + 4) {
                                result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                            }
                        }
    
                    } else {
                        result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { switchToPage(i) }}>{i}</div>)
                    }
                }
            }
            return (<div className="PaginationDiv">{result}</div>)
        }
        return null;
}

export default Pagination;