import React from 'react';

import './project-company-mobile-page-style.css'
import HOne from "../h-one";
import {
    BrowserRouter as Router,
    withRouter,
} from "react-router-dom";
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import Slider from 'react-slick';
import Modal from '../modal';
import UserForm from '../user-form';
import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

class ProjectCompanyMobilePage extends React.Component {
    configurationService = new ConfigurationService();
    serverApi = new ServerApi();
    seoService = new SeoService();
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: ''
            },
            currentJumbo: null,
            selectedCountry: null,
            topSliderImages: null,
            isVisibleModal:false,
        }
    }
    componentDidMount() {
        if(this.props.width>1000){
            this.props.history.push(document.location.pathname.replace('/mobile-campaign','/campaign'));
        }

        this.serverApi.getProjectInfo(this.props.match.params.id)
            .then((data) => {
                if (this.state.debug) console.log(`getProjectInfo data:`, data);
                this.setState({
                    data: data
                })
                let initialData = {
                    currentProjectLogo: `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`,
                    currentProjectLogoInvert:`https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`,
                    
                }
                this.props.saveState(initialData);
                // this.props.saveState('currentProjectLogo', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo}`);
                // this.props.saveState('currentProjectLogoInvert', `https://ngirealty.com/img/projects/${this.props.match.params.id}/${data.logo_invert}`);
            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectInfo ... -> Component: ProjectCompanyPage ERROR:${error}`);
                this.props.history.replace({pathname:`/page-not-found`});
            })

            let initialData = {
                selectionHeader: true,
                showSelectionToolBar: false,
                footerHideElem: true,
                isVisibleFooter:false,
                selectionDisplay:"block",
                currentPage:'ProjectCompanyMobilePage'
            }
            this.props.saveState(initialData);
        // this.props.saveState('selectionHeader', true);
        // this.props.saveState('showSelectionToolBar', false);
        // this.props.saveState('footerHideElem', true);
        // this.props.saveState('isVisibleFooter', false);
    }

    componentWillUnmount() {
        let initialData = {
            currentProjectLogo: null,
            footerHideElem: false,
            isVisibleFooter:true
        }
        this.props.saveState(initialData);
        // this.props.saveState('currentProjectLogo', null);
        // this.props.saveState('footerHideElem', false);
        // this.props.saveState('isVisibleFooter', true);
    }

    closeModal =()=>{
        this.setState({
            isVisibleModal:false
        })
    }

    handleInput = (event) => {
        let { name, value } = event.target;
        this.setState((prevState) => {
            return {
                contactForm: {
                    ...prevState.contactForm,
                    [name]: value
                }
            }
        })
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: '',
                userMessage: '',
                userComment: ''
            },
        })
    }

    saveCurrentSelectedCountry = (value) => {
        this.setState({
            selectedCountry: value
        })
    }

    renderSliderTop() {
        let { sliderData, data } = this.state;
        let {height,width} = this.props;
        let images = [];
        if (data) {
            for (let i = 0; i < data.gallery.length; i++) {
                if (data.gallery[i].jumbo == 1) images.push(data.gallery[i]);
            }
        }
        const settingsSlider = {
            slidesToShow: 1,//5
            dots: false,
            adaptiveHeight: false,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        dots: false
                    }
                },
               
            ],
        };
        return (
            <Slider {...settingsSlider}
                className={'ProjectCompanyMobileSlider'}
                onInit={() => {
                    this.setState({
                        topSliderImages: images,
                        currentJumbo: images[0].jumbo_dark
                    })
                }}

                afterChange={(index) => {
                    this.setState({
                        currentJumbo: this.state.topSliderImages[index].jumbo_dark
                    })
                }}
            >
                {images.map((img, index) => {

                    if (width > 550) {
                        //desctop
                        if (img.jumbo === 1) {
                            return (
                                <div className={'ItemBox'}>
                                    <img className={'Item'} style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`} alt={img.jumbo_image}></img>
                                </div>
                            )
                        }
                        else if (img.jumbo_mobile===1) {
                            return (
                                <div className={'ItemBox'}>
                                    <img className={'Item'}  style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`} alt={img.mobile_image}></img>
                                </div>
                            )
                        }
                    } else {
                        //mobile
                        if (img.jumbo_mobile===1) {
                            return (
                                <div className={'ItemBox'}>
                                    <img className={'Item'}  style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/mobile/${img.mobile_image}`} alt={img.mobile_image}></img>
                                </div>
                            )
                        }else
                        if (img.jumbo === 1) {
                            return (
                                <div className={'ItemBox'}>
                                    <img className={'Item'}  style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/jumbotron/${img.jumbo_image}`} alt={img.jumbo_image}></img>
                                </div>
                            )
                        }
                    }
                    return (
                        <div className={'ItemBox'}>
                            <img className={'Item'}  style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/big/${img.photo}`} alt={img.photo}></img>
                        </div>
                    )
                    {/* return (
                        <div className={'ItemBox'} >
                            <img className={'Item'} style={{height:`${height-106}px`}} src={`https://ngirealty.com/img/projects/${data.id}/big/${img.photo}`} alt={img.photo}></img>
                        </div>
                    ) */}
                })}
            </Slider>
        )
    }

    renderButtons(){
        const phone = require('../../elements/icons/phone-solid-white.png');
        const message = require('../../elements/icons/comment-dots-solid.png');
        const contactWA = require('../../elements/images/contactWA.png');

        let { contactForm, sliderData, data, selectedCountry,currentJumbo,isVisibleModal} = this.state;
        let { dictionary, width, country } = this.props;
        let res;
        if(this.configurationService.getCurrentLanguagePrefix()==='en'){
            res = (
                <>
                <a target="_blank" href={`tel:${data.main_agent.phone}`} className='Button'>Call <img className="Icon" style={{ marginLeft: '10px' }} src={phone} alt={'phone'} /></a>
               <button onClick={()=>{this.setState({isVisibleModal:true})}} className='Button'>{dictionary.message} <img className="Icon" style={width>500?({ marginLeft: '50px' }):({ marginLeft: '20px' })} src={message} alt={'message'} /></button>
               </>
            )

        }else{
            res = (
                <>
                    {country==='US'?<a target="_blank" href={`https://wa.me/${data.main_agent.phone}`} className='Button'>WhatsApp <img className="Icon" style={{ marginLeft: '10px' }} src={phone} alt={'phone'} /></a>:null}
                    {/* <a target="_blank" href={`https://wa.me/${data.main_agent.phone}`} className='ContactWAButton'> <img className="ContactWA" src={contactWA} alt={'message'} /></a> */}
                    <button onClick={()=>{this.setState({isVisibleModal:true})}} className='Button'>{dictionary.message} <img className="Icon" style={width>500?({ marginLeft: '50px' }):({ marginLeft: '20px' })} src={message} alt={'message'} /></button>
                </>
            )
        }
        return (
            <div className={'Buttons'}>
                {res}
            </div>
        )
    }

    render() {

        let { contactForm, sliderData, data, selectedCountry,currentJumbo,isVisibleModal} = this.state;
        let { dictionary, width, country } = this.props;
        console.log(dictionary)
        let googleMapSize = {
            width: '500',
            height: '400',
            scale: 2
        }
        if (width < 1000) {
            googleMapSize.width = "1000";
            googleMapSize.height = '300';
        }
        if (data) {
            return (
                <div className={"Page CompanyProjectMobile"}>
                <Helmet>
                        <title> {data.name} | Smart Luxe</title>
                        {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/project/:id/mobile-campaign/:name`,
                                        params:{
                                            id:this.props.match.params.id,
                                            name:this.props.match.params.name,
                                        }
                                       
                                    })
                                }
                    </Helmet>
                    <div className={"TopPart"}>
                        <div className={"TopSlider"} style={currentJumbo===1?({color:'white'}):({color:'black'})}>

                            {width > 1000 ? (
                                <div className={"TopHeader"}>
                                    <HOne align={"left"} title={data.name} />
                                    <div className={"Slogan"}>{data.web_slogan}</div>
                                </div>
                            ) : (
                                <div className={"TopHeaderMobile"}>
                                    <div className={'Slogan'}>
                                        <HOne line="large" align={"center"} title={data.web_slogan} />
                                    </div>
                                    
                                    {this.renderButtons()}
                                        
                                </div>
                            )}



                            {this.renderSliderTop()}
                        </div>

                        
                    </div>
                    <Modal
                    closeModal={this.closeModal}
                    ModalStyle={{minWidth:'250px',padding:'40px',width:'auto',display:'flex',flexDirection:'column',alignItems:'center'}}
                    isVisible={isVisibleModal}>
                        <HOne
                            align={'center'}
                            title={dictionary.contact}
                        ></HOne>
                        <div className="Description">
                            {dictionary.wizard_contact_desc}
                        </div>
                        <UserForm
                            clearForm={this.clearForm}
                            topPartRow={true}
                            contactForm={contactForm}
                            handleInput={this.handleInput}
                            width={width}
                            country={country}
                            thankModal={true}
                            secureInfo={true}
                            dictionary={dictionary}
                            className={'Form'}
                            CustomInputStyle={{ padding:'0 0 0 5px'}}
                            style={{ textAlign: 'center', display:'block', justifyContent: 'center'}}
                        >

                        </UserForm>
                    </Modal>
                </div>

            )
        }
        else return null;
    }
}

export default withRouter(ProjectCompanyMobilePage)