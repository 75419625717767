import React,{useEffect} from "react";
import {
    BrowserRouter as Router,
    withRouter,
    Redirect,
} from "react-router-dom";

import './document-redirect-page-style.css';

function DocumentRedirectPage(props){
    
    useEffect(()=>{
        window.location.replace(`https://ngirealty.com/doc/${props.match.params.id}`);
    })
    return (
        <div className="RedirectPage">
            <div>
                REDIRECT
            </div>
        </div>
    )
}

export default withRouter(DocumentRedirectPage);