import React from 'react';
import InnerArticle from '../inner-article/inner-article';

export default function InnerArticleGrid(props) {
    let { data, type, dictionary, projectId, projectName,limit=1000000 } = props;
    if (!Array.isArray(data) || data.length === 0) {
        return null;
    }
    return (
        <div className={"GridMain"}>
            <div className={"Grid"}>
                {data.map((item, index) => {
                    if (index<limit&&(projectId!=item.id&&type!="card")) return (
                        <InnerArticle
                            key={index + item.title}
                            data={item}
                            type={type}
                            dictionary={dictionary}
                            projectId={projectId}
                            projectName={projectName}
                        />
                    )
                    else return null
                })}
            </div>
        </div>
    )
}