import React from 'react';
import {Link} from "react-router-dom";
import './neighborhoods-modal-style.css';
class NeighborhoodsModal extends React.Component {
    state = {
    }
    render() {

        let body = null;
        let renderNeighborhoodsList = null;
        
        if (this.props.data !== undefined ) {
            renderNeighborhoodsList = this.props.data.map((item, index) => {
                return (
                    <Link to={`/neighborhood/${item.id}`} key={item.id} className="Item" onClick={()=>{this.props.closeModal()}}>
                        {item.name}
                    </Link>
                );
            })
        }
        if (this.props.isVisible) {
            body = (
                <div className={"NeighborhoodsModalMainCont"}>
                    <div className="NeighborhoodsModalMain" onClick={(event)=>{event.stopPropagation()}}>
                            {renderNeighborhoodsList}
                    </div>
                    <div className="CloseModal" onClick={()=>{this.props.closeModal()}}></div>
                </div>
            )
        } else {
            body = null;
        }

        return (
            <>
                {body}
            </>
        )
    }
}

export default NeighborhoodsModal;