import React from 'react';
import {
    BrowserRouter as router,
    withRouter,
    Link,
} from "react-router-dom";
import Parser from "html-react-parser";
import LanguageSwitch from '../language-switch';

import './header-selection-style.css'
function HeaderSelection(props) {

    let handlePushFindMyDeal = () => {
        props.history.push('/find-my-deal');
    }

    const logo = require('../../elements/images/logo_positive_center.svg').default;
    let { dictionary, selectionId, currentSelectionListing, agentId, currentProjectLogo,width } = props;

    let pathName = '';
    if (document.location.pathname.includes('/selection')) {
        if (agentId !== undefined && agentId !== null) pathName = `/selection/${selectionId}/${agentId}`;
        else pathName = `/selection/${selectionId}`
    } else {
        pathName = `/`
    }

    return (
        <>
            <div className={"HeaderSelection"}>
                <div className={"HeaderSelectionLogo"}>
                    <Link to={pathName}>
                        <img src={currentProjectLogo === null ? logo : currentProjectLogo} alt=""></img>
                    </Link>
                </div>
                {/* <div className={"HeaderSelectionBack"}>
                    {currentSelectionListing !== undefined && currentSelectionListing !== null ? (<Link to={pathName}>&lt; Back to list</Link>) : null}
                </div> */}

                {currentProjectLogo === null ? (
                    <div className={"HeaderSelectionPoweredBy"}>
                        <div className={"PoweredBy"} onClick={handlePushFindMyDeal}>
                            {Parser(dictionary.slogan_powered_by)}
                        </div>
                    </div>
                ) : (
                    <div className={"HeaderSelectionPresented"}>
                        {width<1000?(null):(<LanguageSwitch allowedLanguages={props.allowedLanguages} />)}
                        {/* <div className={"PoweredBy"} onClick={()=>{props.history.push('/')}}>
                            Presented by SmartLuxe
                        </div> */}
                    </div>
                )}



            </div>
            <div className={"HeaderSelectionBackMobile"}>
                {currentSelectionListing !== undefined && currentSelectionListing !== null && currentSelectionListing.length > 0 ? (<Link to={pathName}>&lt; Back to list</Link>) : null}
            </div>
        </>

    )
}

export default withRouter(HeaderSelection);