import * as configurations from '../configuration/servers';
import currentConfig from '../configuration/config.json';
import * as languages from '../configuration/languages';
import StringProcessingService from './string-processing-service';

const languageList= require('../configuration/languages/list.json')

const stringProcessingService = new StringProcessingService();

let debug = false;
let currentServer = configurations[currentConfig.currentServer];
let siteUrl= '';

if(typeof window !== "undefined"){
        siteUrl = window.location.protocol + "//" + window.location.hostname + "/"; 
} 

let languagePrefix = window.location.hostname.split('.')[0];
let currentLanguage = null;

if(window.location.hostname.split('.')[0] === 'smartluxe'){
   languagePrefix = 'en';
   currentLanguage = languageList[0];
}else{
    for(let i = 0 ; i < languageList.length; i++){
        if(siteUrl.includes(`${languageList[i].name}.`)){
            languagePrefix = languageList[i].name
            currentLanguage = languageList[i];
        }
    }
}


if(!currentLanguage) document.location.href = "https://ngirealty.com/";

if(typeof localStorage !== "undefined"){
    localStorage.setItem('lang', languagePrefix);
}

if (siteUrl.includes('axis')) {
    currentServer = configurations["axis"];

} else if (siteUrl.includes('onserv')) {
    currentServer = configurations["onserv"];
   // siteUrl = configurations["onserv"].serverApi;


} else if (siteUrl.includes('track21')) {
    currentServer = configurations["track"];

}
else {
    currentServer = configurations["prod"];
}

export default class ConfigurationService {

    getCurrentServer() {
        if (debug) {
            console.log(currentServer);
        }
        return currentServer;
    }

    getCurrentSite() {
        return siteUrl;
    }

    getCurrentLanguage() {
        return languages[localStorage.getItem('lang')];
    }

    getDomain(){
        if (stringProcessingService.GetParamsFromUrl().get('debug')) return "http://ngi.test/";
        else  return 'https://ngirealty.com/';
    }

    checkLanguage(currentPage){
       if( currentLanguage && !currentLanguage.global){
            let isWhiteListPage = currentLanguage.whiteList.find((item)=>{
                return item === currentPage;
            })
            if(!isWhiteListPage) document.location.href = "https://ngirealty.com/";
        
       }
    }

    switchLanguage(lang) {
        switch (lang) {
            case 'en': {
                localStorage.setItem('lang', 'en');
                break;
            }
            case 'pt': {
                localStorage.setItem('lang', 'pt');
                break;
            }
            case 'es': {
                localStorage.setItem('lang', 'es');
                break;
            }
            default: {
                localStorage.setItem('lang', 'en');
                break;
            }
        }
    }

    getCurrentLanguagePrefix() {
        return languagePrefix;
    }

    checkDomainName(){
        // let domain = document.location.hostname;
        // let domainZone = 'com'
        // let protocol = document.location.protocol;
        // let port = '';
        
        // if(domain.includes('.test')||domain.includes('.qa')){
        //     domainZone="test:3000"
        //     port=":3000"
        // }
        // if(domain+port===`smartluxe.${domainZone}`){

        // }else{
        //     let flagLocale= false;
        //     languageList.map((locale)=>{
        //         if(domain+port===`${locale.name}.smartluxe.${domainZone}`){
        //             flagLocale=true;
        //         }else {
        //             if(domain.includes(`${locale.name}.`)){
        //                 flagLocale=true;
        //                 window.location.replace(`${protocol}//${locale.name}.smartluxe.${domainZone}${document.location.pathname}`);
        //             }
        //         }
        //     })

        //     if(!flagLocale){
        //         window.location.replace(`${protocol}//smartluxe.${domainZone}${document.location.pathname}`);
        //     }
        // }
        
        if(document.location.hostname.includes('www.')){
            document.location.replace(document.location.href.replace('www.',''))
        }

        return null;
    }
}

