import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import './modal-style.css';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderCloseIcon() {
        let { opportunityToClose = true, closeModal,closeColor ="#000",onStartPopUp = false  } = this.props;
        let iconClassAdditional =''
        if(onStartPopUp){
            iconClassAdditional ='CloseIconUnderBlock'
        }
        if (opportunityToClose) {
            return (
                <div onClick={opportunityToClose?closeModal:null}>
                    <svg className={`CloseIcon ${iconClassAdditional}`} xmlns="http://www.w3.org/2000/svg" width="21.683" height="21.683" viewBox="0 0 21.683 21.683">
                        <g id="Сгруппировать_348" data-name="Сгруппировать 348" transform="translate(-1189.793 -281.793)">
                            <line id="Линия_85" data-name="Линия 85" x1="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke={closeColor} strokeWidth="5" />
                            <line id="Линия_86" data-name="Линия 86" x2="20.269" y2="20.269" transform="translate(1190.5 282.5)" fill="none" stroke={closeColor} strokeWidth="5" />
                        </g>
                    </svg>

                </div>
            )
        }
        return null;
    }

    render() {
        let { isVisible, children, closeModal,opportunityToClose=true,ModalBlurStyle,ModalStyle,modalClass,speed = 1,onStartPopUp = false } = this.props;
        const pageTransition = {
            initial: {
                opacity: 0,
                transition: { duration: speed }
            },
            exit: {
                opacity: 0,
                transition: { duration: speed }
            },
            animate:{
                opacity: 1,
                transition: { duration: speed }
            }
        }
            return (
                <AnimatePresence exitBeforeEnter>
                    {isVisible && (
                        <motion.div
                        variants={pageTransition}

                            initial="initial"
                            exit="exit"
                            animate="animate"
                            className="ModalBlur"
                            style={ModalBlurStyle}
                        >
                            <div className={`Modal ${modalClass}`} style={ModalStyle}>
                                {children}
                                {!onStartPopUp?this.renderCloseIcon():(null)}
                            </div>
                            <div className="ModalBlurDiv" onClick={(opportunityToClose && !onStartPopUp )?closeModal:null}></div>
                            {onStartPopUp?this.renderCloseIcon():(null)}
                        </motion.div>
                    )}
                </AnimatePresence>
            )
    }

  

}

export default Modal;