import React from 'react';
import ConfigurationService from '../../services/configuration-service';
import './status-icon-style.css';

function StatusIcon(props){

    let {data} = props;
    // let imgSrc = require('../../elements/icons/sold-medium.png');
    // // if(width<=1000) imgSrc = require('../../elements/icons/sold-large.png');
    // return(<img src={imgSrc} alt='Sold Icon'/>);

    const currentLanguage = (new ConfigurationService()).getCurrentLanguagePrefix();
    let rentedIcon = require(`../../elements/icons/rented_${currentLanguage}.png`);
    let soldIcon = require(`../../elements/icons/sold_${currentLanguage}.png`);


    if(data.standard_status != 'Active' && data.StandardStatus != 'Active') return(
        <div className='StatusBlock'>
            <img src={data.type && data.type.includes('Lease') ||  data.Type && data.Type.includes('Lease') || data.PropertyType && data.PropertyType.includes('Lease') ? rentedIcon : soldIcon}/>
        </div>
    )
        else return null;
}

export default StatusIcon;