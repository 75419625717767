import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import ConfigurationService from "../../services/configuration-service";
import ServerApi from "../../services/server-api-service";

import ListingSlider from "../listing-slider";
import StandAloneSlider from "../stand-alone-slider";
import Loading from "../loading";
import { Helmet } from "react-helmet";
import './stand-alone-gallery-page-style.css'

const configurationService = new ConfigurationService();
const serverApi = new ServerApi();
const smartLuxeLogo = require('../../elements/images/logo_negative_center.png')

export default function StandAloneGalleryPage(props) {

    let { saveState, height, width, dictionary } = props;

    const params = useParams();
    const history = useHistory();
    
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [galleryData, setGalleryData] = useState(null);


    useEffect(() => {
        if (saveState) {
            saveState({
                routerMarkStyle:{flex:1,minHeight:'auto'},
                isVisibleHeader: false,
                isVisibleFooter: false
            })
        }
        if (params.projectId) {
            serverApi.getProjectInfo(params.projectId)
                .then((data) => {

                    //tranform to component data props obj
                    let galleryItems ={Media:[]}
                    data.gallery.map((item,index)=>{
                        galleryItems.Media.push({
                            big:`https://ngirealty.com/img/projects/${item.project_id}/big/${item.photo}`,
                            Order:item.order,
                            thumb:`https://ngirealty.com/img/projects/${item.project_id}/thumb/${item.thumb}`
                        })
                    })
                    setGalleryData(galleryItems);
                    setCompanyData(data);
                    setLoading(true);
                    console.log(data)
                })
                .catch((error) => {
                    console.log(`ERROR: fetch getProjectInfo ... -> Component: StandAloneGalleryPage ERROR:${error}`);
                    this.props.history.replace({ pathname: `/page-not-found` });
                })
        } else {
            console.log(`ERROR: url params undefined`);
            history.replace({ pathname: `/page-not-found` });
        }

    }, [])


    useEffect(() => () => {
        if (saveState) {
            saveState({
                routerMarkStyle:{flex:'none',minHeight:900},
                isVisibleHeader: false,
                isVisibleFooter: false
            })
        }
    }, []);


    return (
        <div>
         

            {loading ? (
                
                <div className="StandAlonePage">
                       <Helmet>
                        <title> {companyData.name} | Smart Luxe</title>
                    </Helmet>
                    <div className="title">
                        <img className="logo" src={`https://ngirealty.com/img/projects/${companyData.id}/${companyData.logo_invert}`} alt='Company Logo'/>
                        <p>{companyData.name}</p>
                    </div>

                    <div className="gallery">

                        <StandAloneSlider
                            data={galleryData}
                            dictionary={dictionary}
                            width={width}
                            height={height}
                        ></StandAloneSlider>
                    </div>

                    <div className="presented">
                        Presented by
                        <img className="logo" src={smartLuxeLogo} alt='logo' />
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </div>
    )
}