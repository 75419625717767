
export default class FormCheckService {

    showAlert = (title = '', text = '') => {
        alert(text);
    }

    checkEmptyField = (field = '', fieldName='All fields') => {
        if (field === '') {
            this.showAlert ('Alert','Please enter '+fieldName);
            return true;
        }
        else {
            return false;
        }
    }

    // checkSwith = (field = '', alert = '') => {
    //     if (!field) {
    //         this.showAlert ('Alert','Please accept Privace Policy');
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }


    checkEqualFields = (field1 = '', field2 = '', alert = '') => {
        if (field1 !== field2) {
            this.showAlert ('alert',alert);
            return true;
        }
        else {
            return false;
        }
    }

    checkEmail = (field = '', alert = '') => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(field)) {
            this.showAlert ('Alert','Please enter correct '+alert);
            return true;
        }
        else {
            return false;
        }
    }
    checkNumber=()=>{


    }

    checkContactForm=(ContactForm)=>{
        if(ContactForm.hasOwnProperty('userFullName')){
            if(this.checkEmptyField(ContactForm.userFullName,'Full name')){
                return true;
            }
        }

        if(ContactForm.hasOwnProperty('userEmail')){
            if(this.checkEmptyField(ContactForm.userEmail,'Email')|| this.checkEmail(ContactForm.userEmail,'Email')){
                return true;
            }
        }
        
        if(ContactForm.hasOwnProperty('userPhone')){
            if(this.checkEmptyField(ContactForm.userPhone,'Phone')){
                return true;
            }
        }

        // if(ContactForm.hasOwnProperty('userComment')){
        //     if(this.checkEmptyField(ContactForm.userMessage,'Message')){
        //         return true;
        //     }
        // }

        // if(ContactForm.hasOwnProperty('userComment')){
        //     if(this.checkEmptyField(ContactForm.userComment,'Comments')){
        //         return true;
        //     }
        // }
        return false;
    }

    checkSearchForm =(SearchForm)=>{
        if(SearchForm.hasOwnProperty('location')){
            if(this.checkEmptyField(SearchForm.location,'location')){
                return true;
            }
        }
        if(SearchForm.hasOwnProperty('bedrooms')){
            if(this.checkEmptyField(SearchForm.bedrooms,'bedrooms')){
                return true;
            }
        }
        if(SearchForm.hasOwnProperty('bathrooms')){
            if(this.checkEmptyField(SearchForm.bathrooms,'bathrooms')){
                return true;
            }
        }
        // if(SearchForm.hasOwnProperty('price_min')){
        //     if(this.checkEmptyField(SearchForm.price_min,'price from')){
        //         return true;
        //     }
        // }
        // if(SearchForm.hasOwnProperty('price_max')){
        //     if(this.checkEmptyField(SearchForm.price_max,'price to')){
        //         return true;
        //     }
        // }
    }
    
    // checkPassword = (field = '', alert = '') => {
    //     //let re = /^([a-z0-9]+$/i){};
    //     let re = /^([a-zA-Z0-9_-]){6,50}$/;
    //     if (!re.test(field)) {
    //         this.showAlert (alert);
    //         return true;
    //     }
    //     else {
    //         return false;
    //     }
    // }

}